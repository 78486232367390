import React, { useEffect, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import Table from "../Table/Table";
import TableButton from "../TableButton/TableButton";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../API/Api";

export default function DownlineActivationHistory() {
  const [pendingWithdrawApi, setPendingWithdrawApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [plandata, setplandata] = useState([]);
  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const Fundrecive_API = async () => {
    try {
      let res = await API.post(
        `/downlineActivationHistory`,
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      res = res.data.data[0];
      setPendingWithdrawApi([]);

      let arr = [];
      res.forEach((item, index) => {
        arr.push({
          RowNumber: item.RowNumber,
          userid: `${item.f_name} - (${item.Username}) `,
          Amount: `₹ ${item.Amount}`,
          remark: item.remark,
          referby: item.referby,
          activationDate: item.activationDate,
          bv: item.bv,
        });
      });

      setPendingWithdrawApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Fundrecive_API();
  }, []);

  // eslint-disable-next-line no-unused-vars
  var [pendingWithdraw, setpendingWithdraw] = new useState({
    cols: [
      { Header: "S. No.", accessor: "RowNumber" },
      { Header: "Name - (User Id)", accessor: "userid" },
      { Header: "Package Name", accessor: "Amount" },
      { Header: "Activation Type", accessor: "remark" },
      //{ Header: "Activated By", accessor: "referby" },
      { Header: "BV", accessor: "bv" },
      { Header: "Date", accessor: "activationDate" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = pendingWithdrawApi.slice(
    indexOfFirstPage,
    indexOfLastPost
  );

  return (
    <>
      <div>
        <DashBoardNav />
        <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
          <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
            Downline Investment History
          </span>
          <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
            Dashboard &gt; Activation / Upgrade
          </span>
        </div>
        <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
          {/* <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
            <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
              <span className="float-left text-sm filterbtn color-black bdr-rad-xs">
                Click For Fund Received
              </span>
              <div className="card col-lg-12">
                <div className="card-header d-flex justify-content-between py_10 mb21">
                  
                </div>
              </div>
            </div>
          </div> */}
          <div className="bg pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
            <div className="bg text-center pad-tp-20 pad-lt-5 pad-rt-10">
              <span className="text-md font-bold color-grad1 float-left">
                Downline Investment History
              </span>
            </div>
            <br />
            <br />
            {/* table */}
            <Table data={[...currentPost]} columns={pendingWithdraw.cols} />
            <div className="container">
              <TableButton
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={pendingWithdrawApi.length}
                listPerpage={listPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
