import React from "react";
import { Link } from "react-router-dom";
import CanvasDash from "../CanvasDash/CanvasDash";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import logo from "../../Assets/Logo/logo.png";
import profile from "../../Assets/Img/SidebarImg/profile.svg";
import deposit from "../../Assets/Img/SidebarImg/deposit.svg";
import share from "../../Assets/Img/SidebarImg/share.svg";
import withdraw from "../../Assets/Img/SidebarImg/withdrawal.svg";
import help from "../../Assets/Img/SidebarImg/customer-service.svg";
import liink from "../../Assets/Img/FooterImg/link.svg";
import whatsapp from "../../Assets/Img/FooterImg/whatsapp.svg";
import telegram from "../../Assets/Img/FooterImg/telegram.svg";
import belll from "../../Assets/Img/FooterImg/bell.svg";
import event from "../../Assets/Img/SidebarImg/event.svg";
import { useSelector } from "react-redux";

function MyVerticallyCenteredModal(props) {
  let URL = window.location.origin;
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bg notify pad-tp-20 pad-bt-10 pad-lt-10 pad-rt-10">
          <div className="flexbox pad-bt-20 text-center">
            <div className="flex-md-100">
              <span className="text-big font-bold">Invite Your Friends</span>
            </div>
          </div>
          <div
            className="flexbox pad-bt-20"
            data-copy-text={`${URL}/registration?userid=${userId}`}
          >
            <div className="flex-md-20 text-left">
              <img src={liink} alt="" className="icon-sm" />
            </div>
            <div className="flex-md-80 pad-lt-20">
              <span className="disp-block color-grad1 text-big">
                Copy Referral Link
              </span>
              <span className="disp-block color-gray mt-1">
                Share Manually with Friends
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-20">
            <div className="flex-md-20 text-left">
              <img src={whatsapp} alt="" className="icon-sm" />
            </div>
            <div className="flex-md-80 pad-lt-20">
              <span className="disp-block color-grad1 text-big">
                Share WhatsApp
              </span>
              <span className="disp-block color-gray mt-1">
                Directly share on whatsapp
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-20">
            <div className="flex-md-20 text-left">
              <img src={telegram} alt="" className="icon-sm" />
            </div>
            <div className="flex-md-80 pad-lt-20">
              <span className="disp-block color-grad1 text-big">
                Share on Telegram
              </span>
              <span className="disp-block color-gray mt-1">
                Quick &amp; Easy Share
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-10 text-center">
            <div className="flex-md-100">
              <span className="text-st disp-inline-block color-gray">
                I will do it later
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
const DashBoardNav = () => {
  const [modalShow2, setModalShow2] = React.useState(false);
  return (
    <div>
      <header className="bg pad-tp-15 pad-bt-10 pad-lt-20 pad-rt-20">
        <div className="flexbox pad-tp-5 pad-bt-5">
          <div className="flex-md-20 pad-tp-5">
            <CanvasDash />
          </div>
          <div className="flex-md-60 text-center">
            <Link to="/DashBoard">
              {" "}
              <img src={logo} alt="" className="icon-st" />
            </Link>
          </div>
          {/* <div className="flex-md-20 pad-tp-5">
            <Link to="/Promotion">
              {" "}
              <img
                src={belll} alt=""
                // onclick="return goPage('promotion');"
                className="icon-xs float-right icon-white"
              />
            </Link>
          </div> */}
        </div>
      </header>

      <nav className="bg nativenav">
        <div className="flexbox text-center shad-box-xs pad-tp-20 pad-bt-10">
          {" "}
          <div className="flex-md-20">
            <Link to="/Profile">
              <img src={profile} alt="" className="icon-xs" />{" "}
              <span className="disp-block mt-1 color-gray">Profile</span>
            </Link>
          </div>
          {/* <div className="flex-md-20" onclick="return show('.sharetool');">
            <button className="bg-grad1">
              <img
                src={share} alt=""
                className="icon-xs icon-white"
                onClick={() => setModalShow2(true)}
              />
            </button>
            <MyVerticallyCenteredModal
              show={modalShow2}
              onHide={() => setModalShow2(false)}
            />
          </div> */}
          <div className="flex-md-20">
            <Link to="/ActivateUpgrade">
              {" "}
              <img src={help} alt="" className="icon-xs" />{" "}
              <span className="disp-block mt-1 color-gray">
                Activate <span className="textnone">/Upgrade</span>
              </span>
            </Link>
          </div>
          <div className="flex-md-20">
            <Link to="/withdrawLog">
              {" "}
              <img src={withdraw} alt="" className="icon-xs" />{" "}
              <span className="disp-block mt-1 color-gray">Payout</span>
            </Link>
          </div>
          {/* <div className="flex-md-20 cha">
            <Link to="/Change_Password">
              <img
                src={profile} alt=""
                className="icon-xs"
              />{" "}
              <span className="disp-block mt-1 color-gray">Change Password</span>
            </Link>
          </div> */}
          <div className="flex-md-20">
            <Link to="/Support">
              <img src={event} alt="" className="icon-xs" />{" "}
              <span className="disp-block mt-1 color-gray">Support</span>
            </Link>
          </div>
          <div className="flex-md-20">
            <Link to="/">
              {" "}
              <img src={deposit} alt="" className="icon-xs" />{" "}
              <span className="disp-block mt-1 color-gray">Logout</span>
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default DashBoardNav;
