import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import logo from "../../Assets/Logo/logo.png";
import ShopCanvas from "../ShopCanvas/ShopCanvas";

const DashShopNav = () => {
  const [modalShow2, setModalShow2] = React.useState(false);
  return (
    <div>
      <header className="bg bgApna pad-tp-15 pad-bt-10 pad-lt-20 pad-rt-20">
        <div className="flexbox pad-tp-5 pad-bt-5">
          <div className="flex-md-20 pad-tp-5">
            <ShopCanvas />
          </div>
          <div className="flex-md-60 text-center">
            <Link to="/Shop_Dasboard">
              {" "}
              <img src={logo} className="icon-st" />
            </Link>
          </div>
         
        </div>
      </header>
    </div>
  );
};

export default DashShopNav;
