import React, { useEffect, useState } from "react";
import jivanlogo from "../../Assets/Logo/logo.png";
import "./Agrmnt.css";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../API/Api";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { useNavigate } from "react-router-dom";

export default function JivanWelcome() {
  const handlePrint = () => {
    window.print();
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const [data, setData] = useState({});

  const Dashboard_API = async () => {
    try {
      let res = await API.get(`/userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("API Response", res.data.data[0][0]);
      setData(res.data.data[0][0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Dashboard_API();
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="main1">
          <div className="mainwelc">
            <div style={{ textAlign: "center" }}>
              <img src={jivanlogo} alt="Jivandhara Logo" className="" />
            </div>
            <br />
            <p style={{ color: "red" }}>Dear {data.f_name},</p>
            <p className="text-white">
              Welcome to our family! We are delighted to officially welcome
              Jivandhara Farming OPC Private Limited as our new associate
              partner.
            </p>
            <p className="text-white">
              Your decision to join forces with us marks the beginning of an
              exciting collaboration. Together, we have the opportunity to
              leverage our respective strengths, expertise, and resources to
              achieve remarkable results.
            </p>
            <p className="text-white">
              At Jivandhara Farming OPC Private Limited, we value partnerships
              built on trust, respect, and mutual benefit. We value our clients
              and deliver exceptional service to them.
            </p>
            <p className="text-white">
              Once again, welcome to the Jivandhara family. We are excited about
              the possibilities that lie ahead and grateful for the opportunity
              to collaborate.
            </p>
            <p className="text-white">Warm regards,</p>
            <div>
              <img className="doclogo" src={jivanlogo} alt="Jivandhara Logo " />
            </div>
            <div
              style={{ textAlign: "center", marginTop: "20px" }}
              className="no-print"
            >
              <button
                className="btn btn-success"
                onClick={handlePrint}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
