import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

export default function PrivateRoutes() {
    const isuser = useSelector((state) => state.Auth.isAuth);
    console.log("PrivateRoutes", isuser);
  
    return (
      <div className="bg_Dashboar">
        {!isuser ? <Navigate to="/login" /> : <Outlet />}
      </div>
    );
  };