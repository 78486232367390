import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Header.css";
import { Link } from "react-router-dom";
import logo from "../../Assets/Logo/logo.png";

function Header() {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      defaultActiveKey="/home"
      className="bg-body-white navMainn"
    >
      <Container>
        <Navbar.Brand href="/">
          <img className="logo_light" src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto resFlx align-items-center">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
            {/* <Nav.Link to="/Contact">Contact</Nav.Link> */}
            <Link to="/Contact">Contact</Link>
          </Nav>
          <Nav className="resFlx">
            <Link
              to="/Login"
              className="animation btttnn logMargn"
              data-animation="fadeInDown"
              data-animation-delay="2s"
            >
              Login
            </Link>
            <Link
              to="/Contact"
              className="animation btttnn logMargn"
              data-animation="fadeInDown"
              data-animation-delay="2s"
            >
              Inquiry
            </Link>
            {/* <Link
              to="/SignUp"
              className="animation btttnn logMargn"
              data-animation="fadeInDown"
              data-animation-delay="2s"
            >
              Register
            </Link> */}
            <Link
              to="/Shop_Login"
              className="animation btttnn"
              data-animation="fadeInDown"
              data-animation-delay="2s"
            >
              Shop Login
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
