import React, { useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import { Button, Form, Input, InputNumber } from "antd";
import { useSelector } from "react-redux";
import { API } from "../../API/Api";
import { toast } from "react-toastify";

const Changepassword = () => {
  const { userId, jwt_token } = useSelector((state) => state.Auth);

  // const [getUID, setgetUID] = useState("");
  const [otp_flag, setotp_flag] = useState(false);

  const sendOTP = async () => {
    try {
      let res = await API.post(
        "/updatePasswordOtp",
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("res", res);
      if (res.data.data === "Successfull") {
        setotp_flag(true);
        toast.success("OTP sent successfully!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const updatedAddress = async () => {
  //   try {
  //     console.log("getOTP", getOTP);
  //     let res = await API.post("/updateEcurrencyAddress", {
  //       uid: getUID,
  //       ecurrencyAddress: getUID,
  //       otp: getOTP,
  //     });
  //     console.log("ResUpdated", res.data);
  //     if (res.data.data == "successfully Update !!") {
  //       toast.success(res.data.data);
  //       setotp_flag(false);
  //     } else {
  //       toast.error(res.data.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const onFinish = async (values) => {
    // console.log("Success:", values);
    let res = await API.post(
      `changeUserPassword`,
      {
        uid: userId,
        oldPassword: values.oldpassword,
        newPassword: values.password,
        otp: values.otp,
      },
      {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("ResUpdated", res.data);
    if (res.data.data === "Password Changed Successfully") {
      toast.success(res.data.data);
      // setotp_flag(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);

      setotp_flag(false);
    } else {
      toast.error(res.data.data);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <DashBoardNav />
      <div className="bg dashb pad-tp-30 pad-bt-150 text-center">
        <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
          Change Password
        </span>
        <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
          Dashboard &gt; Profile
        </span>
      </div>

      <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea">
        <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-20 pad-rt-20 bg-white bdr-rad-md">
          <div className="row mx-0">
            <div className="col-lg-6 mx-auto col-md-8 col-sm-6 col-12">
              <div className="input-group">
                <Form
                  name="basic"
                  layout={"vertical"}
                  initialValues={{
                    remember: true,
                  }}
                  style={{ width: "100%" }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    name="oldpassword"
                    label="Old Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Old password!",
                      },
                    ]}
                  >
                    <Input.Password maxLength={20} />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="New Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your New password!",
                      },
                      {
                        min: 6,
                        message: "Password must be at least 6 characters long!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password maxLength={20} />
                  </Form.Item>
                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The new password that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password maxLength={20} />
                  </Form.Item>
                  {!otp_flag && (
                    <Form.Item>
                      <Button type="primary" danger onClick={sendOTP}>
                        Send OTP
                      </Button>
                    </Form.Item>
                  )}
                  {otp_flag && (
                    <Form.Item
                      name="otp"
                      label="OTP"
                      rules={[
                        {
                          required: true,
                          message: "Please input your OTP!",
                        },
                        {
                          min: 4,
                          message: "OTP must be 4 characters long!",
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }} maxLength={8} />
                    </Form.Item>
                  )}
                  {otp_flag && (
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Update
                      </Button>
                    </Form.Item>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Changepassword;
