import React from 'react'
import Header from '../Header/Header'
import logo from "../../Assets/Logo/logo.png"
import pdf from "../../Assets/WebImages/PDF.png"

import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoTwitter } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import phonecolor from "../../Assets/WebImages/phone-altcolor.png";
import emailcolor from "../../Assets/WebImages/envelopecolor.png";
import mapcolor from "../../Assets/WebImages/map-marker-altcolor.png";
import footerimage1 from "../../Assets/WebImages/rv-9-footer-article-1.jpg";
import footerimage2 from "../../Assets/WebImages/rv-9-footer-article-2.jpg";
import arrow from "../../Assets/WebImages/arrow-right.png";
function Document() {
    return (
        <div>
            <div className="v_light_purple" data-spy="scroll" data-offset={110}>

                <Header />
                <section id="service" className="bg_gray2">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12">
                <div className="title_purple_dark text-center">
                  <h4
                    className="animation animated fadeInUp"
                    data-animation="fadeInUp"
                    data-animation-delay="0.2s"
                    style={{ animationDelay: "0.2s", opacity: 1 }}
                  >
                    Documents
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className='btns'>
                <a href='PAN.pdf' target='_blank'>
                  <div className="icon_box">
                    <img src={pdf}/>
                  </div>
                  <div className="bgde">
                    <h5 className='fonr'>
                      <i className="fa fa-circle cir" aria-hidden="true" />{" "}
                      PAN Card{" "}
                    </h5>
                  </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className='btns'>
                <a href='TAN Card.pdf' target='_blank'>
                  <div className="icon_box">
                    <img src={pdf}/>
                  </div>
                  <div className="bgde">
                    <h5 className='fonr'>
                      <i className="fa fa-circle cir" aria-hidden="true" />{" "}
                      TAN Card{" "}
                    </h5>
                  </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className='btns'>
                <a href='INC 33 MOA.pdf' target='_blank'>
                  <div className="icon_box">
                    <img src={pdf}/>
                  </div>
                  <div className="bgde">
                    <h5 className='fonr'>
                      <i className="fa fa-circle cir" aria-hidden="true" />{" "}
                      INC 33 MOA{" "}
                    </h5>
                  </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className='btns'>
                <a href='INC 34 AOA.pdf' target='_blank'>
                  <div className="icon_box">
                    <img src={pdf}/>
                  </div>
                  <div className="bgde">
                    <h5 className='fonr'>
                      <i className="fa fa-circle cir" aria-hidden="true" />{" "}
                      INC 34 AOA{" "}
                    </h5>
                  </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="purple_dark_bg footer_pattern footermain">
          <div className="top_footer">
            <div className="container">
              <div className="row align-items-center g-3 border-bottom pb-3">
                <div className="col-md-3 col-sm-6 col-12">
                  <a href="#" className="logofooter">
                    <img className="w-100 h-100" src={logo} alt="logo" />
                  </a>
                </div>
                <div className="col-md-3 col-sm-6 col-12">
                  <div className="footericon_group">
                      <a href="tel:+919068282716" className="icons"><img className="w-100 h-100" src={phonecolor} alt="icon" /></a>
                      <div className="footertopsection">
                      <div className="footerheadingtop text-white fw-bold">Our Phone</div>
                      <a className="numbertext text-secondary" href="tel:+919068282716">+91 9068282716</a>
                      </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-12">
                  <div className="footericon_group">
                      <a href="mailto:info@jivandhara.org" className="icons"><img className="w-100 h-100" src={emailcolor} alt="icon" /></a>
                      <div className="footertopsection">
                      <div className="footerheadingtop text-white fw-bold">Our Email</div>
                      <a className="numbertext text-secondary" href="mailto:info@jivandhara.org">info@jivandhara.org</a>
                      </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-12 pb-3 pb-sm-0">
                  <div className="footericon_group">
                      <div  className="icons"><img className="w-100 h-100" src={mapcolor} alt="icon" /></div>
                      <div className="footertopsection">
                      <div className="footerheadingtop text-white fw-bold">Our Address</div>
                      <div className="numbertext text-secondary">3C/104, Avas Vikas, <br /> Buddhi Bihar, Moradabad,<br /> 244001</div>
                      </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 py-4">
                <div className="col-md-3">
                  <div className="footerheading">About us</div>
                  <p className="subtext">We transforming our “waste” stream through active landfill diversion and local disposal of food residuals and other compostables.</p>
                  <ul className="iconlistsocial">
                    <li><a href=""><FaFacebookF className=" fa fa-facebook" /></a></li>
                    <li><a href=""><IoLogoTwitter className=" fa fa-twitter" /></a></li>
                    <li><a href=""><FaInstagram className=" fa fa-instagram" /></a></li>
                    <li><a href=""><FaTelegramPlane className=" fa fa-telegram" /></a></li>
                  </ul>
                </div>
                <div className="col-md-2">
                  <div className="footerheading">Our Services</div>
                  <ul>
                    <li><a href="/">House Cleaning</a></li>
                    <li><a href="/">Office Cleaning</a></li>
                    <li><a href="/">Covid Cleaning</a></li>
                    <li><a href="/">Carpet Cleaning</a></li>
                    <li><a href="/">ContCar Cleaningact</a></li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <div className="footerheading">Recent Posts</div>
                  <div className="imagetextgroup mb-3">
                    <div className="imagefooter">
                      <img className="w-100 h-100" src={footerimage1} alt="imagefooter" />
                    </div>
                  <div className="d-flex gap-1 flex-column">
                    <div className="text-white datetext">June 16, 2023</div>
                    <div className="fw-bold text-white querytext">Ways to Freshen Up Curtains & Drapes Home.</div>
                  </div>
                  </div>
                  <div className="imagetextgroup mb-3">
                    <div className="imagefooter">
                      <img className="w-100 h-100" src={footerimage2} alt="imagefooter" />
                    </div>
                  <div className="d-flex gap-1 flex-column">
                    <div className="text-white datetext">July 21, 2023</div>
                    <div className="fw-bold text-white querytext">Streamlining Your Laundry Routine Through Automation.</div>
                  </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="footerheading">Newsletter Signup</div>
                  <div class="nwsltr-top mb-3 position-absolute">
                    <input type="email" name="email" id="rv-8-subs-form" placeholder="Enter your Email..." />
                    <button><span><img className="w-100 h-100" src={arrow} alt="imagearrow" /></span></button>
                  </div>
                  <div class="rv-6-footer-nwsltr__checkbox d-flex align-items-center gap-3">
                    <input type="checkbox" className="w-auto" id="nwsltr-checkbox" name="checkbox" value="1" />
                    <label className="mb-0" for="nwsltr-checkbox"> I agree to the <a href="#">Privacy Policy</a>.</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-footer">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer_bottom">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <p className="copyright">
                          Copyright © 2024 ekopowerev.com | All Rights Reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

            </div>
        </div>
    )
}

export default Document