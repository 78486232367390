import React, { useEffect, useState } from "react";
import TableButton from "../TableButton/TableButton";
import Table from "../Table/Table";
import DashShopNav from "../DashShopNav/DashShopNav";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { Api_Fren } from "../../API/Api_Fren";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Activation_Product_Order_History() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [levelIncomeApi, setLevelIncomeApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [totalIncome, setTotalIncome] = useState();
  const [selectedLevel, setSelectedLevel] = useState(0);
  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const Pwithdraw_API = async () => {
    try {
      let res = await Api_Fren.post(
        `/ActivationProductOrderHistory`,
        {
          brid: userId,
          uid: "",
          fromDt: "",
          toDt: "",
          type: "5",
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // if (res.data.data && res.data.data.totalIncome) {
      //   setTotalIncome(res.data.data.totalIncome);
      // }

      console.log("ActivationProductOrderRequest", res.data.data[0]);
      let arr = [];
      res.data.data[0].forEach((item, index) => {
        arr.push({
          sno: item.row,
          Uid: item.Uid,
          name: item.name,
          Emailid: item.Emailid1,
          MobileNo: item.telephone,
          telephone: item.telephone,
          state: item.state,
          pincode: item.pincode,
          city: item.city,
          ShippingAddres: item.ShippingAddres,
          dd: item.dd,
          qty: item.qty,
          Shippingcharge: item.Shippingcharge,
          TotalMRP: item.TotalMRP,
          TOtalPaid: item.TOtalPaid,
          TotalBV: item.TotalBV,
          bruid: item.bruid,
          Status: item.Status,
          mpoid: item.mpoid,
          orderno: (
            <>
              <span style={{"color":"blue", "textUnderlinePosition":"under"}}
                onClick={() =>
                  navigate(`/CheckActivation?OrderNo=${item?.mpoid}`)
                }
              >
                {`${item.orderno}` + `/` + `${item.mpoid}`}
              </span>
            </>
          ),
          Action: (
            <>
              {parseInt(item.Status) === 1 ? (
                <>
                  {" "}
                  <button
                    className="btn-success"
                    onClick={() => OrderConfirm_Api(item.mpoid, 3)}
                  >
                    Dispatch Order
                  </button>
                </>
              ) : parseInt(item.Status) === 3 ? (
                <>
                  Order Delivered
                  {/* {" "}
                  <button
                    className="btn-danger"
                    onClick={() => OrderConfirm_Api(item.mpoid, 4)}
                  >
                    Update Remark
                  </button>{" "} */}
                </>
              ) : (
                <>Order Cancelled</>
              )}
            </>
          ),
        });
      });

      setLevelIncomeApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const OrderConfirm_Api = async (mpoid, status1) => {
    try {
      let res = await Api_Fren.post(
        `/ActivationProductOrderCancleConfirm`,
        {
          loginid: userId,
          orderid: mpoid,
          status: status1,
          remark: "confirm",
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("res-->", res);
      window.location.reload();
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Pwithdraw_API();
  }, []);
  var [myTeam, setMyTeam] = new useState({
    cols: [
      { Header: "S.No", accessor: "sno" },
      { Header: "User Id", accessor: "Uid" },
      { Header: "User Name", accessor: "name" },
      { Header: "User Email", accessor: "Emailid" },
      { Header: "Mobile No.", accessor: "MobileNo" },
      // { Header: "Alternate Mobile No.", accessor: "telephone" },
      // { Header: "State", accessor: "state" },
      // { Header: "Pincode", accessor: "pincode" },
      // { Header: "City", accessor: "city" },
      // { Header: "Address", accessor: "ShippingAddres" },
      { Header: "Order ID", accessor: "orderno" },
      { Header: "Order Date", accessor: "dd" },
      { Header: "Qty", accessor: "qty" },
      // { Header: "Courier Charge", accessor: "Shippingcharge" },
      { Header: "Total MRP", accessor: "TotalMRP" },
      { Header: "Total Pay", accessor: "TOtalPaid" },
      { Header: "Total BV", accessor: "TotalBV" },
      { Header: "Franchise Id", accessor: "bruid" },
      { Header: "Status", accessor: "Status" },
      { Header: "Action", accessor: "Action" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = levelIncomeApi.slice(indexOfFirstPage, indexOfLastPost);

  return (
    <>
      <div>
        <DashShopNav />
        <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
          <span className="pad-tp-20 bg marg-tp-50 text-nowrap reText text-xl color-white">
          Product Order History
          </span>
          <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
            Dashboard &gt; Product Order History
          </span>
        </div>
        <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
          <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
            <div className="bg text-center pad-tp-20 pad-lt-5 pad-rt-10">
              <span className="text-md font-bold color-grad1 float-left">
                Product Order History
              </span>
            </div>
            <br />
            <br />
            <div className="card-body p-2">
              <div className="table-responsive">
                <div
                  // style={{ maxHeight: 400, overflow: "scroll" }}
                  id="example_wrapper"
                  className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  {/* <div className="dt-buttons btn-group">
                          <button type="button" className="btn">
                            CSV
                          </button>
                          <button type="button" className="btn">
                            Excel
                          </button>
                          <button type="button" className="btn">
                            PDF
                          </button>
                        </div> */}
                  {/* <div
                          id="example_filter"
                          className="dataTables_filter fldset"
                        >
                          <label>
                            <b>From Date : </b>{" "}
                            <input
                              className="form-control form-control-sm"
                              id="fdate"
                              name="fdate"
                              type="date"
                              defaultValue=""
                            />
                          </label>
                          <label>
                            <b>To Date : </b>{" "}
                            <input
                              className="form-control form-control-sm"
                              id="tdate"
                              name="tdate"
                              type="date"
                              defaultValue=""
                            />
                          </label>

                          <label>
                            <button
                              // type="submit"
                              className="btn btn-success btn-sm"
                              style={{ height: "auto" }}
                              onClick={handleSearch}
                            >
                              Search
                            </button>
                          </label>
                        </div> */}
                </div>
                {/* table */}
                <Table data={[...currentPost]} columns={myTeam.cols} />
                <div className="container">
                  <TableButton
                    indexOfFirstPage={indexOfFirstPage}
                    indexOfLastPost={indexOfLastPost}
                    setcurrentPage={setcurrentPage}
                    currentPage={currentPage}
                    totalData={levelIncomeApi.length}
                    listPerpage={listPerpage}
                  />
                </div>
              </div>
            </div>

            {/* <div className="flexbox tr bg-purple marg-bt-10">
            <div className="flex-md-40 color-white">User</div>
            <div className="flex-md-60 color-white">Business</div>
          </div>
          <div className="bg pad-tp-10 pad-bt-20 marg-bt-20">
            <span className="text-big disp-block marg-bt-5">
              No Record Saved
            </span>
            <span className="text-st color-gray disp-block">
              Invite your friends to join our network.
            </span>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
