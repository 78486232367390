import React from 'react'
import { Link } from 'react-router-dom'
import { UpdateAuth } from '../../Redux/AuthSlice';
import { useDispatch } from 'react-redux';

export default function PageNotFound() {

    const dispatch = useDispatch();
  const LogoutHandler = (e) => {
    dispatch(UpdateAuth({ isAuth: false, userId: "" }));
  };

  return (
    <>
          <div className="container">
              <div className='text-center '>
                  <h1> Page not Found ! Error 404 </h1>
              </div>
              <div className="container text-center" style={{marginTop: "10rem"}}>
                  <Link className="btn btn-lg btn-danger" to="/" onClick={LogoutHandler}>
                      Back to Home
                  </Link>
              </div>
          </div>
      </>
  )
}
