import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import menu from "../../Assets/Img/SidebarImg/menu.svg";
import Default from "../../Assets/Img/SidebarImg/default.jpg";
import dash from "../../Assets/Img/SidebarImg/home.svg";
import profile from "../../Assets/Img/SidebarImg/profile.svg";
import Invest from "../../Assets/Img/SidebarImg/investment.svg";
import team from "../../Assets/Img/SidebarImg/team-lines.svg";
import report from "../../Assets/Img/SidebarImg/income-lines.svg";
import event from "../../Assets/Img/SidebarImg/event.svg";
import support from "../../Assets/Img/SidebarImg/customer-service.svg";
import signout from "../../Assets/Img/SidebarImg/signout.svg";
import { useDispatch } from "react-redux";
import { UpdateAuth } from "../../Redux/AuthSlice";

function ShopCanvas() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const LogoutHandler = (e) => {
    dispatch(UpdateAuth({ isAuth: false, userId: "" }));
  };

  return (
    <>
      <Button className="offcanvasBtn" onClick={handleShow}>
        <img src={menu} alt="menu" className="icon-xs icon-white" />
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Body>
          <ul>
            <Link to="/Shop_Dasboard">
              {" "}
              <li className="Dashboardaksmcl padtttt">
                <img src={dash} alt="dashboard" />
                Dashboard
              </li>
            </Link>
            <li>
              <Accordion>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <img src={profile} alt="" />
                    Profile
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                    <Link to="/ShopProfile">
                        <li>Edit Profile</li>
                      </Link>                    
                      <Link to="/ShopChangepassword">
                        <li>Change Password</li>
                      </Link>                     
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </li>
            <li>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <img src={team} alt="" />
                    Product
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <Link to="/Receive_Stock">
                        <li>Receive Stock</li>
                      </Link>
                      <Link to="/Stock_Statement">
                        <li>Stock Statement</li>
                      </Link>
                      <Link to="/Activation_Product_Order_Request">
                        <li>Product Order Request</li>
                      </Link>
                      <Link to="/Activation_Product_Order_History">
                        <li>Product Order History</li>
                      </Link>
                      {/* <Link to="/Reward_Team">
                        <li>Reward Team</li>
                      </Link> */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <img src={report} alt="" />
                    Income Report
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <Link to="/StockMothlyIncome">
                        <li>Monthly Commission</li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>
            </li>
            {/* <li>
              <Accordion>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <img src={event} alt="" />
                    Withdraw
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <Link to="/FrenWithdraw">
                        <li>Withdraw Request</li>
                      </Link>
                      <Link to="/FrenWithdrawLog">
                        <li>Withdraw Log</li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </li> */}
            {/* <li>
              <Accordion>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <img src={event} alt="" />
                    User Product Request
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <Link to="/ProductRequestList">
                        <li>Product Request List From User</li>
                      </Link>
                      <Link to="/ProductRequestListHistory">
                        <li>Product Request List History</li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </li> */}
            {/* <li>
              <Accordion>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <img src={event} alt="" />
                    Withdraw
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <Link to="/FrenWithdraw">
                        <li>Withdraw Request</li>
                      </Link>
                      <Link to="/FrenWithdrawLog">
                        <li>Withdraw Log</li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </li> */}
            <Link to="/Shop_Login">
              <li onClick={LogoutHandler}>
                <img src={signout} alt="" />
                Log-out
              </li>
            </Link>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default ShopCanvas;
