import React, { useEffect, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import { toast } from "react-toastify";
import { API } from "../../API/Api";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Table from "../Table/Table";
import TableButton from "../TableButton/TableButton";

export default function FundRequestUpi() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [pendingWithdrawApi, setPendingWithdrawApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [getFunType, setgetFunType] = useState("WALLET_FUND");
  const [getUserId, setgetUserId] = useState(0);
  const [UserIdNameU, setUserIdNameU] = useState("");
  const [Amount, setAmount] = useState("");
  const [WalletAmount, setWalletAmount] = useState(0);
  const [AdminAmount, setAdminAmount] = useState(0);
  const user = useSelector((state) => state.Auth.userId);
  const dash = useSelector((state) => state.Auth.UserInfo);
  const [getUserIdU, setgetUserIdU] = useState("");
  const [BankId, setBankId] = useState(0);
  const [UTRNo, setUTRNo] = useState("");
  const [Tpass, setTpass] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [bankdata, setbankdata] = useState([]);
  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const FundRequest_API = async () => {
    try {
      let res = await API.get(`/upiFundRequestHistory?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });

      res = res.data.data[0];
      console.log("FundRequest--->", res);
      setPendingWithdrawApi([]);

      let arr = [];
      res.forEach((item, index) => {
        arr.push({
          uid: item.uid,
          amount: item.amount,
          edate: item.rd,
          utrno: item.utrno,
          bankname: item.bankname,
          accountnumber: item.accountnumber,
          ifsc: item.ifsc,
          st: item.st,
          approve_date: item.ad,
          username: item.username,
        });
      });

      setPendingWithdrawApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const BankList_API = async () => {
    try {
      let res = await API.get(`adminUpiDetails`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      //console.log("upidetails", res.data.data[0]);
      setbankdata(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const [bankDetails, setbankDetails] = useState({});
  const BankDetails_API = async (Bank_id) => {
    try {
      setBankId(Bank_id);
      let res = await API.get(`GetBanklist?bankid=${Bank_id}&action=0&uid=0`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("BankResponse", res.data?.data[0]);
      setbankDetails(res.data?.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    FundRequest_API();
    BankList_API();
  }, []);

  var [pendingWithdraw, setpendingWithdraw] = new useState({
    cols: [
      { Header: "UserId", accessor: "username" },
      { Header: "Amount", accessor: "amount" },
      { Header: "Date", accessor: "edate" },
      { Header: "UTR No.", accessor: "utrno" },
      { Header: "Status", accessor: "st" },
      { Header: "Approve Date", accessor: "approve_date" },
      { Header: "UPI Name", accessor: "bankname" },
      { Header: "UPI No.", accessor: "accountnumber" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = pendingWithdrawApi.slice(
    indexOfFirstPage,
    indexOfLastPost
  );

  const FundRequest = async (event) => {
    try {
      event.preventDefault();
      let res = await API.post(
        `/upiFundRequest`,
        {
          uid: userId,
          amount: Amount,
          utrno: UTRNo,
          slip: "",
          bankid: BankId,
          //transpswd: Tpass,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.data === "success") {
        toast.success(res.data.data);
        handleClose();
        FundRequest_API();
        BankList_API();
      } else {
        toast.error(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <DashBoardNav />
        <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
          <span className="pad-tp-20 bg marg-tp-50 text-nowrap reText text-xl color-white">
            Fund Request (UPI)
          </span>
          <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
            Dashboard &gt; Fund
          </span>
        </div>
        <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
          <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
            <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
              <span className="float-left text-sm filterbtn color-black bdr-rad-xs">
                {/* Click For Fund Request */}
              </span>
              <div className="card col-lg-12">
                <div className="card-header d-flex justify-content-between py_10 mb21">
                  {/* <div className="header-title">
                    <h4 className="card-title">Fund Request</h4>
                  </div> */}
                  <div
                    className="card-header-form col-lg-12 text-center"
                    style={{ backgroundColor: "rgb(194, 16, 16)" }}
                  >
                    <Button onClick={handleOpen} className="Addbtn">
                      <i className="fa fa-plus" />
                      <span style={{ color: "white" }}>
                        Click Here For Fund Request
                      </span>
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Fund Request
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          <div className="">
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <label className="my14">UPI Name:</label>
                                  <select
                                    className="form-control select2"
                                    id="id"
                                    name="id"
                                    required="required"
                                    onChange={(e) =>
                                      BankDetails_API(e.target.value)
                                    }
                                  >
                                    <option value="">Select UPI</option>
                                    {bankdata?.map((ele, index) => (
                                      <option value={ele.id}>
                                        {ele.btcname}
                                      </option>
                                    ))}
                                  </select>
                                  <span
                                    id="uname"
                                    style={{ fontSize: "small" }}
                                  />
                                </div>

                                <div className="col-md-12">
                                  <label className="my14">UPI No.:</label>
                                  <input
                                    readOnly={true}
                                    className="form-control"
                                    placeholder="Account No."
                                    required="True"
                                    type="text"
                                    value={bankDetails[0]?.btcaddress}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <label className="my14">QRCODE:</label>
                                  <img
                                    src={bankDetails[0]?.imag}
                                    style={{ width: "150px", height: "150px" }}
                                  />
                                </div>

                                <div className="col-md-12">
                                  <label className="my14">Amount:</label>
                                  <input
                                    className="form-control"
                                    data-val="true"
                                    data-val-number="The field transferAmount must be a number."
                                    data-val-required="The transferAmount field is required."
                                    id="transferAmount"
                                    name="transferAmount"
                                    placeholder="Enter Amount"
                                    required="True"
                                    type="text"
                                    defaultValue={Amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <label className="my14">UTR No.:</label>
                                  <input
                                    className="form-control"
                                    id="toUser"
                                    name="toUser"
                                    placeholder="Enter UTR Number"
                                    required="True"
                                    type="text"
                                    value={UTRNo}
                                    onChange={(e) => setUTRNo(e.target.value)}
                                  />
                                  <span
                                    id="uname"
                                    style={{ fontSize: "small" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="modal-footer">
                              <button
                                className="btn btn-danger light"
                                style={{
                                  textDecoration: "none",
                                  height: "auto",
                                }}
                                onClick={handleClose}
                              >
                                Close
                              </button>
                              <button
                                className="btn btn-dark light"
                                style={{
                                  textDecoration: "none",
                                  height: "auto",
                                }}
                                onClick={FundRequest}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </Typography>
                      </Box>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
            <div className="bg text-center pad-tp-20 pad-lt-5 pad-rt-10">
              <span className="text-md font-bold color-grad1 float-left">
                Fund Request Log
              </span>
            </div>
            <br />
            <br />
            {/* table */}
            <Table data={[...currentPost]} columns={pendingWithdraw.cols} />
            <div className="container">
              <TableButton
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={pendingWithdrawApi.length}
                listPerpage={listPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
