import React, { useEffect, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import EditProfile from "./EditShopProfile";
import Defult from "./default.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../API/Api";
import { UpdateAuth } from "../../Redux/AuthSlice";
import EditShopProfile from "./EditShopProfile";
import { Api_Fren } from "../../API/Api_Fren";
import DashShopNav from "../DashShopNav/DashShopNav";

const ShopProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const Dashboard_API = async () => {
    try {
      let res = await Api_Fren.post(
        `getUserDetail_byuserid_Fre`,
        {
          uid: userId
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("res--->", res);
      setData(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/Shop_Login");
      }
    }
  };

  useEffect(() => {
    Dashboard_API();
  }, []);

  return (
    <div>
      <DashShopNav />
      <div className="bg dashb pad-tp-30 pad-bt-150 text-center">
        <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
          My Profile
        </span>
        <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
          Dashboard &gt; Profile
        </span>
      </div>
      <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea">
        <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
          <>
            <div className="content-page">
              <div className="content">                
                <div className="container">
                  <div className="row">                   
                    <div className="col-12 col-md-12 col-lg-12">
                      <div className="card">
                        <div className="padding-20">
                          <EditShopProfile                           
                            user={data}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default ShopProfile;