import React, { useEffect, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { API } from "../../API/Api";
import { UpdateAuth } from "../../Redux/AuthSlice";

export default function UpgradeId() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [plandata, setplandata] = useState([]);
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const [getFunType, setgetFunType] = useState("FUNDS");
  const [getUserId, setgetUserId] = useState("");
  const [UserIdName, setUserIdName] = useState("");
  const [pakg_Amount, setpakg_Amount] = useState("");
  const [PackId, setPackId] = useState(0);
  const [valid, setValid] = useState("");

  const Dashboard_API = async () => {
    try {
      let res = await API.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("API Response", res.data.data[0]);
      setData(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const planList_API = async () => {
    try {
      let res = await API.get(`GetPlanlist`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("PlanResponse", res.data.data[0]);
      setplandata(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const CheckuserIdValid = async (value) => {
    try {
      setgetUserId(value);
      let res = await API.get(`CheckUser_id?uid=${value}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });

      if (res.data.data[0][0].f_name === "") {
        setValid("Invalid User Id");
        setPackId(0);
      } else {
        setValid("(" + res.data.data[0][0].f_name + ")");
        setPackId(res.data.data[0][0].top_up);
      }
    } catch (e) {
      console.log("Error While calling CheckuserIdValid API", e);
    }
  };

  useEffect(() => {
    planList_API();
    Dashboard_API();
  }, []);

  const Amount_Data = (event) => {
    const newValue = event.target.value.replace(/[^0-9]/gi, "");
    setpakg_Amount(newValue);
  };

  const ActivationID = async (event) => {
    try {
      event.preventDefault();
      // if (getFunType === "") {
      //   toast.error("Select Fund Type to Activate");
      //   return;
      // }
      if (pakg_Amount === "") {
        toast.error("Select Package Amount");
        return;
      }

      let res = await API.post(
        `/activation`,
        {
          user_id: getUserId,
          amount: pakg_Amount,
          referby: userId,
          orderid: 0,
          type: "FUNDS",
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.data.RESULT === "Successfully Activated...!!!") {
        swal({
          title: getUserId + UserIdName,
          text: "Successfully Activated...!!",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result) {
            setgetUserId("");
            setUserIdName("");
            Dashboard_API();
            planList_API();
            setpakg_Amount("");
          }
        });
      } else {
        swal({
          title: res.data.data.RESULT,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const UpgradeID = async (event) => {
    try {
      event.preventDefault();
      // if (getFunType === "") {
      //   toast.error("Select Fund Type to Upgrade");
      //   return;
      // }
      if (pakg_Amount === "") {
        toast.error("Select Package Amount");
        return;
      }

      let res = await API.post(
        `/upgradeID`,
        {
          user_id: getUserId,
          amount: pakg_Amount,
          referby: userId,
          orderid: 0,
          type: "FUNDS",
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.data.RESULT === "Successfully Upgrade...!!!") {
        swal({
          title: getUserId + UserIdName,
          text: "Successfully Upgraded..!!",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result) {
            setgetUserId("");
            setUserIdName("");
            Dashboard_API();
            planList_API();
            setpakg_Amount("");
          }
        });
      } else {
        swal({
          title: res.data.data.RESULT,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <DashBoardNav />
      <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
        <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
          Id Upgrade
        </span>
        <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
          Dashboard &gt; Activate / Upgrade
        </span>
      </div>
      <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
        <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-20 pad-rt-20 bg-white bdr-rad-md">
          <input type="hidden" name="type" defaultValue="Product" />
          <input type="hidden" name="userid" defaultValue={1} />
          <div className="bg pad-tp-20">
            <label>Available Wallet</label>
            <label className="input_block">
              <span>
                {`Admin Fund (${
                  "₹ " + parseFloat(data[0]?.Net_AdminFund).toFixed(2) ||
                  "$ 0.00"
                })`}
              </span>
            </label>
          </div>
          <div className="bg pad-tp-15">
            <label>Customer ID</label>
            <input
              type="text"
              name="toid"
              className="form-elm"
              data-txn-userid="true"
              placeholder="User Id"
              required=""
              value={getUserId + UserIdName}
              title="UserId is required"
              onChange={(e) => CheckuserIdValid(e.target.value)}
            />
          </div>
          <div className="bg pad-tp-1">
            <label>
              {valid === "Invalid User Id" ? (
                <span style={{ color: "red" }}>{valid}</span>
              ) : (
                <span style={{ color: "green" }}>{valid}</span>
              )}
            </label>
          </div>
          {/* <div className="bg pad-tp-15">
            <label>Choose Product</label>
            <select
              className="form-elm stakamt"
              id="id"
              name="id"
              required="required"
              onClick={(e) => setpakg_Amount(e.target.value)}
            >
              <option value="" selected="">
                Select Package
              </option>
              {plandata?.map((ele, index) => (
                <option value={ele.id}>{ele.plan_name}</option>
              ))}
            </select>
          </div> */}

          <div className="flex-md-60 pad-lt-5">
            <label>Amount</label>
            <input
              type="text"
              className="form-elm"
              placeholder="Amount"
              value={pakg_Amount}
              onChange={Amount_Data}
              maxLength={12}
              // onChange={(e) => setpakg_Amount(e.target.value)}
            />
          </div>

          <div className="bg pad-tp-15 pad-bt-10">
            <button
              type="submit"
              className="bg-red w100 text-md color-white bdr-rad-xs pad-tp-15 pad-bt-15 pad-lt-30 pad-rt-30"
              onClick={(e) => UpgradeID(e)}
            >
              Upgrade Id
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
