import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { API } from "../../API/Api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Api_Fren } from "../../API/Api_Fren";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditShopProfile({ user }) {
  const [value, setValue] = React.useState(0);
  const [getData, setgetData] = useState([]);
  
  const [fileValue, setfileValue] = useState("");
  const [countries, setcountries] = useState([]);
  const [spinner, setspinner] = useState(false);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //////////////////////My Referral Link//////////////

  let URL = window.location.origin;
  // console.log("URL",URL);

  console.log("file", fileValue);

  /////////////// Profile Update//////////////////////////

 

  const handleGet = (event) => {
    const { name, value } = event.target;
    setgetData({ ...getData, [name]: value });
  };

  

  const updateProfile = async () => {
    try {
      setspinner(true);

      let updateRes = await Api_Fren.post(
        `updateProfile_Fre`,
        {
          user_id: userId,
          name: getData?.fname || user[0]?.fname,
          email: getData?.email || user[0]?.email,
          mobile: getData?.contact || user[0]?.contact,
          address: getData?.address || user[0]?.address,        
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("updateRes-->", updateRes.data);
      if (updateRes.data.data == "Profile Updated Successfully !!") {
        toast.success(updateRes.data.data);
        setspinner(false);
        window.location.href = "/ShopProfile";
      } else {
        toast.error(updateRes.data.data);
      }
    } catch (error) {
      toast.error("Something Wrong");

      console.log(error);
      setspinner(false);
    }
  };


  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example">
            <Tab label="My Profile" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <>           
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-6 col-12">
                  <label>Login Id</label>
                  <input
                    type="text"
                    title="Login Id"
                    required=""
                    maxLength={6}
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="Login Id"
                    defaultValue={user[0]?.user_id}
                    onChange={handleGet}
                    readOnly
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                  <label>Store Name</label>
                  <input
                    type="text"
                    title="Store Name"
                    required=""
                    maxLength={50}
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="Store Name"
                    defaultValue={user[0]?.fname}
                    onChange={handleGet}
                    name="fname"
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                  <label>Contact Person</label>
                  <input
                    type="text"
                    title="Contact Person"
                    required=""
                    maxLength={40}
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="Contact Person"
                    defaultValue={user[0]?.fname}
                    onChange={handleGet}
                    name="fname"
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                  <label>Email Id</label>
                  <input
                    type="text"
                    title="Email Id"
                    required=""
                    maxLength={40}
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="Email Id"
                    defaultValue={user[0]?.email}
                    onChange={handleGet}
                    name="email"
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                  <label>Mobile No.</label>
                  <input
                    type="text"
                    title="Mobile No."
                    required=""
                    maxLength={15}
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="Mobile No."
                    defaultValue={user[0]?.contact}
                    onChange={handleGet}
                    name="contact"
                  />
                </div>
                <div className="form-group col-md-12 col-12">
                  <label>Address</label>
                  <textarea                    
                    placeholder="Address"
                    maxLength={100}
                    className="form-control summernote-simple ng-pristine ng-untouched ng-valid ng-empty"
                    defaultValue={user[0]?.address}
                    onChange={handleGet}
                    name="address"
                  />
                  <div className="invalid-feedback">
                    Please fill in the address
                  </div>
                </div>    
              </div>             
            </div>
            <div className="card-footer text-end">
              <button
                type="button"
                ng-click=""
                className="btn btn-success"
                style={{ height: "auto" }}
                onClick={updateProfile}
              >
                {spinner ? "Loading..." : "Save Changes"}
              </button>
            </div>
          </>
        </CustomTabPanel>      
      </Box>
    </>
  );
}
