import React, { useEffect, useState } from "react";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { API } from "../../API/Api";
import { Api_Fren } from "../../API/Api_Fren";
import { useSelector } from "react-redux";
import TableButton from "../TableButton/TableButton";
import Table from "../Table/Table";
import DashShopNav from "../DashShopNav/DashShopNav";

export default function FrenWithdrawLog() {
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: alpha(theme.palette.common.white, 0.15),
    // "&:hover": {
    //   backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
    marginLeft: 0,
    marginBottom: "5px",
    width: "100%",
    backgroundColor: "#0000002e",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));

  const [pendingWithdrawApi, setPendingWithdrawApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  
  // eslint-disable-next-line no-unused-vars
  var [pendingWithdraw, setpendingWithdrawApi] = new useState({
    cols: [
      { Header: "Withdrawal ID", accessor: "wid" },
      { Header: "Amount", accessor: "amount" },
      { Header: "Charge", accessor: "charge" },
      { Header: "Withdrawal Amount", accessor: "wamount" },
      { Header: "Status", accessor: "status" },
      // { Header: "Tnx", accessor: "tnx" },
      { Header: "Request Date", accessor: "rtdate" },
      { Header: "Response Date", accessor: "rsdate" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = pendingWithdrawApi.slice(
    indexOfFirstPage,
    indexOfLastPost
  );

  return (
    <>
      <DashShopNav />
      <div className="content-page_See">
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between py_10 mb21">
                    <div className="header-title">
                      <h4 className="card-title">Withdraw History</h4>
                    </div>
                  </div>
                  <div className="card-body p-2">
                    <div className="table-responsive">
                      <div
                        id="example_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="dt-buttons btn-group">
                          <button type="button" className="btn">
                            CSV
                          </button>
                          <button type="button" className="btn">
                            Excel
                          </button>
                          <button type="button" className="btn">
                            PDF
                          </button>
                        </div>
                        <div
                          id="example_filter"
                          className="dataTables_filter fldset"
                        >
                          <Search>
                            <SearchIconWrapper>
                              <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                              placeholder="Search…"
                              inputProps={{ "aria-label": "search" }}
                            />
                          </Search>
                        </div>
                      </div>
                    </div>
                    {/* table */}
                    <Table
                      data={[...currentPost]}
                      columns={pendingWithdraw.cols}
                    />
                    <div className="container">
                      <TableButton
                        indexOfFirstPage={indexOfFirstPage}
                        indexOfLastPost={indexOfLastPost}
                        setcurrentPage={setcurrentPage}
                        currentPage={currentPage}
                        totalData={pendingWithdrawApi.length}
                        listPerpage={listPerpage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
