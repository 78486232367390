import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./CanvasDash.css";
import Accordion from "react-bootstrap/Accordion";
import { Link, useNavigate } from "react-router-dom";
import menu from "../../Assets/Img/SidebarImg/menu.svg";
import Default from "../../Assets/Img/SidebarImg/default.jpg";
import dash from "../../Assets/Img/SidebarImg/home.svg";
import profile from "../../Assets/Img/SidebarImg/profile.svg";
import Invest from "../../Assets/Img/SidebarImg/investment.svg";
import team from "../../Assets/Img/SidebarImg/team-lines.svg";
import report from "../../Assets/Img/SidebarImg/income-lines.svg";
import event from "../../Assets/Img/SidebarImg/event.svg";
import support from "../../Assets/Img/SidebarImg/customer-service.svg";
import signout from "../../Assets/Img/SidebarImg/signout.svg";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { API } from "../../API/Api";
import withdrw from "../../Assets/Img/SidebarImg/withdrawal.svg";
import logo from "../../Assets/Logo/logo.png";

function CanvasDash() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const LogoutHandler = (e) => {
    dispatch(UpdateAuth({ isAuth: false, userId: "" }));
  };

  const navigate = useNavigate();
  const [data, setData] = useState({});
  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const Dashboard_API = async () => {
    try {
      let res = await API.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      // console.log("SidebarD", res.data.data[0][0]);
      setData(res.data.data[0][0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Dashboard_API();
  }, []);

  return (
    <>
      <Button className="offcanvasBtn" onClick={handleShow}>
        <img src={menu} alt="menu" className="icon-xs icon-white" />
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Body>
          <div className="bg marg-bt-30 pad-tp-20 pad-bt-20 text-center bg-gray-light">
            <div className="bg">
              <img
                className="img-center icon-eq-st bdr-rad-full"
                src={logo}
                alt="prof"
              />
            </div>
            <div className="bg text-md pad-tp-10">{data.f_name}</div>
            <div className="bg text-sm pad-tp-5">
              <strong>UID:</strong> {data.Username}
            </div>
            {/* <div className="bg pad-tp-5 pad-bt-10">
              <span className="text-sm pad-tp-5 pad-bt-5 pad-lt-10 pad-rt-10 text-white bg-red bdr-rad-sm">
                {data.f_name}
              </span>
            </div> */}
            {/* <div className="bg text-sm">
              <a href="./changedp">Change Profile Pic</a>
            </div> */}
          </div>

          <ul>
            <Link to="/Dashboard">
              {" "}
              <li className="Dashboardaksmcl">
                <img src={dash} alt="dashboard" />
                Dashboard
              </li>
            </Link>
            <li>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <img src={profile} alt="" />
                    Profile
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <Link to="/Profile">
                        <li>Update Profile</li>
                      </Link>
                      {/* <Link to="/Mykyc">
                        <li>My KYC</li>
                      </Link> */}
                      <Link to="/Change_Password">
                        <li>Change Password</li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <img src={Invest} alt="" />
                    Fund
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <Link to="/fundRequest">
                        <li>Fund Request (Bank)</li>
                      </Link>
                      <Link to="/FundRequestUpi">
                        <li>Fund Request (UPI)</li>
                      </Link>
                      {/* <Link to="/fundTransfer">
                        <li>Fund Transfer</li>
                      </Link> */}
                      <Link to="/fundReceive">
                        <li>Fund Received</li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <img src={withdrw} alt="" />
                    Buy Franchise Product
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <Link to="/BuyProduct">
                        <li>Buy Product</li>
                      </Link>
                      <Link to="/ActivatedproductHistory">
                        <li>Product History</li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <img src={Invest} alt="" />
                    Activation / Upgrade
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <Link to="/ActivateUpgrade">
                        <li>Activate Id</li>
                      </Link>
                      <Link to="/UpgradeId">
                        <li>Upgrade Id</li>
                      </Link>
                      <Link to="/Activation_history">
                        <li>Investment History</li>
                      </Link>
                      <Link to="/DownlineActivationHistory">
                        <li>Downline Investment History</li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <img src={team} alt="" />
                    Team
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {/* <Link to="/TreeView">
                        <li>Tree View</li>
                      </Link> */}
                      <Link to="/TreenewView">
                        <li>Binary Tree</li>
                      </Link>
                      <Link to="/Network">
                        <li>My Referrals</li>
                      </Link>
                      <Link to="/TeamBusiness">
                        <li>My Team</li>
                      </Link>
                      <Link to="/PoolTeam">
                        <li>Level Details</li>
                      </Link>
                      <Link to="/Hipayteam">
                        <li>Direct Leg BV</li>
                      </Link>
                      {/* <Link to="/Reward_Team">
                        <li>Reward Team</li>
                      </Link> */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <img src={report} alt="" />
                    Report
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <Link to="/RoiIncome">
                        <li>Roi Income</li>
                      </Link>
                      <Link to="/BinaryIncome">
                        <li>Binary Income</li>
                      </Link>
                      <Link to="/Deffirencialincome">
                        <li>Differential Income</li>
                      </Link>
                      <Link to="/RewardIncome">
                        <li>Reward Report</li>
                      </Link>
                      <Link to="/BonanzaReport">
                        <li>Bonanza Report</li>
                      </Link>
                      {/* <Link to="/DirectIncome">
                        <li>Direct Income</li>
                      </Link> */}
                      <Link to="/RoyalityIncome">
                        <li>Royality Income</li>
                      </Link>
                      {/* <Link to="/levelIncome">
                        <li>Level Income</li>
                      </Link>
                    
                      <Link to="/SalaryIncome">
                        <li>Salary Income</li>
                      </Link>
                      <Link to="/FranchiseCommission">
                        <li>Frenchisee Activation Income</li>
                      </Link>
                      <Link to="/SalesCommission">
                        <li>Product Sales Commission</li>
                      </Link> */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <img src={withdrw} alt="" />
                    Payout
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {/* <Link to="/Withdraw">
                        <li>Payout Request</li>
                      </Link> */}
                      <Link to="/withdrawLog">
                        <li>Working Payout Log</li>
                      </Link>
                      <Link to="/ROIPayout">
                        <li>ROI Payout Log</li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </li>

            {/* <li>
              <Accordion>
                
              </Accordion>
            </li> */}

            <li>
              <Accordion>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <img src={event} alt="" />
                    Document
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {data?.topup === 1 ? (
                        <Link to="/jivanwelcome">
                          <li>Welcome Letter</li>
                        </Link>
                      ) : (
                        <li
                          onClick={() => {
                            alert("Activate Your ID First !!");
                          }}
                        >
                          Welcome Letter
                        </li>
                      )}

                      {data?.topup === 1 ? (
                        <Link to="/IDCardOne">
                          <li>ID Card</li>
                        </Link>
                      ) : (
                        <li
                          onClick={() => {
                            alert("Activate Your ID First !!");
                          }}
                        >
                          ID Card
                        </li>
                      )}

                      {data?.topup === 1 ? (
                        <Link to="/IDCardtwo">
                          <li>Visiting Card</li>
                        </Link>
                      ) : (
                        <li
                          onClick={() => {
                            alert("Activate Your ID First !!");
                          }}
                        >
                          Visiting Card
                        </li>
                      )}

                      {/* {data?.topup === 1 ? (
                        <Link to="/Agreement">
                          <li>Agreement</li>
                        </Link>
                      ) : (
                        <li
                          onClick={() => {
                            alert("Activate Your ID First !!");
                          }}
                        >
                          Agreement
                        </li>
                      )} */}

                      {/* <Link to="/DashBoard">
                        <li>ID Card 3</li>
                      </Link> */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </li>
            <Link to="/News">
              {" "}
              <li className="Dashboardaksmcl">
                <img src={event} alt="" />
                News
              </li>
            </Link>
            <Link to="/Support">
              {" "}
              <li className="Dashboardaksmcl">
                <img src={support} alt="" />
                Get Support
              </li>
            </Link>
            <Link to="/Login">
              <li onClick={LogoutHandler}>
                <img src={signout} alt="" />
                Signout
              </li>
            </Link>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default CanvasDash;
