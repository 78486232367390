import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { API } from "../../API/Api";
import { useEffect } from "react";
import sl3 from "../../Assets/WebImages/sl3.jpeg";
import sl1 from "../../Assets/WebImages/sl1.jpeg";
import sl2 from "../../Assets/WebImages/sl2.jpeg";
import sl4 from "../../Assets/WebImages/sl4.jpeg";
import { Carousel } from "antd";
import { colors } from "@mui/material";

export default function SignUp() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [checkbox, setcheckbox] = useState(false);
  const [spinnerload, setspinnerload] = useState(false);

  const [sponsorid, setsponsorid] = useState("");
  const [sponsoruername, setsponsoruername] = useState("");
  const [sponsorname, setsponsorname] = useState("");
  const [username, setusername] = useState("");
  const [usernamecheck, setusernamecheck] = useState("");
  const [name, setname] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [Emailid, setEmailid] = useState("");
  const [Password, setPassword] = useState("");
  const [Cpassword, setCpassword] = useState("");
  const [showPassword, setshowPassword] = useState(0);
  const [Postion, setSelectedPostion] = useState(0);
  const [valid, setValid] = useState(true);

  const getresponseId = async (e) => {
    let newValue = e.target.value;
    //const newValue = value.replace(/[^A-Z a-z]/g, "");
    if (newValue === "") {
      setsponsorid("");
      setsponsorname("");
    } else {
      setsponsoruername(newValue);
      setsponsorid(newValue);
      let res = await API.get(`/check_sponsorid_name?Username=${newValue}`);

      if (res.data.data.result === "Sponsor Id doesnot exists !!") {
        setsponsorname("Wrong sponser id");
      } else {
        // let { f_name } = res.data.data[0];
        setsponsorname(res.data.data.result);
      }
    }
  };

  const checkUserName = async (e) => {
    let newValue = e.target.value;
    //const newValue = value.replace(/[^A-Za-z0-9]/g, "");
    if (newValue === "") {
      setusername("");
      setusernamecheck("");
    } else {
      setusername(newValue);
      let res = await API.get(`/check_Username?Username=${newValue}`);

      if (res.data.data.result === "User Allready Exist !!") {
        setusernamecheck("User Allready Exist");
      } else {
        // let { f_name } = res.data.data[0];
        setusernamecheck("Valid");
      }
    }
  };

  const Changepasswordtype = async (e) => {
    if (parseInt(showPassword) === 0) {
      setshowPassword(1);
    }
    if (parseInt(showPassword) === 1) {
      setshowPassword(0);
    }
  };
  // const checkUserName = async (e) => {
  //   let { value } = e.target;

  //   const newValue = value.replace(/[^A-Z a-z]/g, "");

  //   if (newValue === "") {
  //     setusername("");
  //   } else {
  //     setusername(newValue);
  //   }
  // };
  const checkName = async (e) => {
    let { value } = e.target;

    const newValue = value.replace(/[^A-Z a-z]/g, "");

    if (newValue === "") {
      setname("");
    } else {
      setname(newValue);
    }
  };
  console.log("sponsorid", sponsorid);
  const RegistrationAPI = async () => {
    setspinnerload(true);

    try {
      if (!sponsoruername) {
        toast.error("Sponsor ID is required");
        return;
      } else if (sponsoruername.length > 15) {
        toast.error("Sponsor Id should not be greater than 15 characters");
        return;
      } else if (!name) {
        toast.error("First Name is required");
        return;
      } else if (name.length < 4) {
        toast.error("First Name must be at least 4 characters");
        return;
      } else if (name.length > 20) {
        toast.error("First Name should not be greater than 20 characters");
        return;
      } else if (username.length < 4) {
        toast.error("User Name must be at least 4 characters");
        return;
      } else if (username.length > 15) {
        toast.error("User Name should not be greater than 15 characters");
        return;
      } else if (mobileNo.length <= 9) {
        toast.error("Enter Valid Mobile Number !!");
        return;
      } else if (!valid) {
        toast.error("Please enter a valid email address");
        return;
      } else if (!Emailid) {
        toast.error("Please enter email address");
        return;
      } else if (!Password) {
        toast.error("Password is required");
        return;
      } else if (!Postion) {
        toast.error("position is required");
        return;
      } else if (Password.length < 6) {
        toast.error("Password must be at least 6 characters");
        return;
      } else if (Password != Cpassword) {
        toast.error("Confirm Password is not match");
        return;
      } else if (checkbox != true) {
        toast.error("Select Terms and Conditions");
        return;
      }

      let res = await API.post("/registration", {
        uid: 0,
        sponser_name: sponsoruername,
        did: 0,
        position: Postion,
        salution: "",
        f_name: name,
        Username: username,
        email: Emailid,
        panno: "",
        countryid: 1,
        psw: Password,
        encpsw: Password,
        mob: mobileNo,
        secratekey: Password,
      });
      console.log("res.data.data.result", res);

      if (res.data.data.result === "Successfully register...!!!") {
        localStorage.setItem("Name", username);
        localStorage.setItem("Password", Password);
        toast.success(`Successful`);
        let uid = res.data.data.uid_output;
        history(`/WelcomePage?userid=${uid}`);
      } else {
        toast.error(`${res.data.data.result}`);
        setspinnerload(false);
      }
      setspinnerload(false);
    } catch (error) {
      console.log("API ERROR", error);
    }
  };

  const Mobile_Data = (event) => {
    const newValue = event.target.value.replace(/[^0-9]/gi, "");
    setmobileNo(newValue);
  };

  const EmailValidation = (event) => {
    const inputValue = event.target.value;
    setEmailid(inputValue);

    if (inputValue) {
      // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailRegex = /^[^\s@]{3,}@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(inputValue);
      setValid(isValid);
    } else {
      setValid(true);
    }
  };

  const ReferralAddress = async () => {
    try {
      let URL = window.location.href;
      let URL_ID = new URLSearchParams(window.location.search).get("userid");
      let URL_POS = new URLSearchParams(window.location.search).get("position");

      if (URL.includes("userid")) {
        setsponsoruername(URL_ID);
        setsponsorid(URL_ID);
        let res = await API.get(`/check_sponsorid_name?Username=${URL_ID}`);

        if (res.data.data.result === "Sponsor Id doesnot exists !!") {
          setsponsorname("Wrong sponser id");
        } else {
          // let { f_name } = res.data.data[0];
          setsponsorname(res.data.data.result);
        }
      }
      if (URL.includes("position")) {
        setSelectedPostion(URL_POS);
      } else {
      }
    } catch (e) {
      console.log("Erroe Whille Referral Fuction Call", e);
    }
  };

  useEffect(() => {
    ReferralAddress();
  }, []);

  useEffect(() => {
    const images = [sl1, sl2, sl3, sl4];
    images.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
    });
  }, []);

  return (
    <div>
      <div className="flexbox logpage ht-full pattern-background-primary">
        <div className="flex-md-15 flex-xs-100" />
        <div className="flex-md-70 flex-xs-100">
          <div className="flexbox logarea bg-white">
            {/* <div className="flex-md-50 flex-xs-100 ltblock" /> */}
            <section
              id="home_section"
              className="flex-md-50 flex-xs-100 section_banner section_gradiant2 h-100"
              data-z-index={1}
              data-parallax="scroll"
              data-image-src="~/assets_front/images/banner_bg2.png"
            >
              <Carousel autoplay speed={300} className="banners hxnuwyubbnnrs">
                <div className="">
                  <img
                    src={sl1}
                    class="d-block w-100 banners hxnuwyubbnnrs"
                    alt="Slide 1"
                  />
                </div>
                <div className="">
                  <img
                    src={sl2}
                    class="d-block w-100 banners hxnuwyubbnnrs"
                    alt="Slide 2"
                  />
                </div>
                <div className="">
                  <img
                    src={sl3}
                    class="d-block w-100 banners hxnuwyubbnnrs"
                    alt="Slide 3"
                  />
                </div>
                <div className="">
                  <img
                    src={sl4}
                    class="d-block w-100 banners hxnuwyubbnnrs"
                    alt="Slide 4"
                  />
                </div>
              </Carousel>
            </section>
            <div className="flex-md-50 flex-xs-100 pad-tp-20 pad-bt-20">
              <div className="bg mainlog dologin bdr-rad-xs">
                {/* <div class="bg seculock text-center"><img src="./assest/icon/lock.svg" height="80px" alt="Secure Login"></div> */}
                <div className="bg webdialog">
                  <div className="bg myform">
                    <div
                      className="bg my-signup-block hide"
                      style={{ display: "block" }}
                    >
                      <div className="bg">
                        <p className="form-h1 color-grad1">
                          Create New Account
                        </p>
                      </div>

                      <div className="flexbox form-area">
                        <div className="flex-md-40">
                          <label>Sponsor Id</label>
                          <input
                            type="text"
                            className="form-elm"
                            name="sponser_id"
                            placeholder="Enter Sponsor Id"
                            required="required"
                            maxLength={15}
                            value={sponsorid}
                            onChange={(e) => {
                              getresponseId(e);
                            }}

                          />
                        </div>

                        <div className="flex-md-60 pad-lt-5">
                          {<label>Status</label>}
                          <input
                            type="text"
                            className={`form-elm ${sponsorname === "valid"
                                ? "color-green"
                                : "color-red"
                              }`}
                            name="sponsername"
                            placeholder="status"
                            readOnly="true"
                            value={sponsorname}
                            disabled={true}
                          />
                        </div>
                        <div className="flex-md-40">
                          <label> Name</label>
                          <input
                            type="text"
                            className="form-elm"
                            name="name"
                            placeholder="Name"
                            maxLength={20}
                            value={name}
                            onChange={(e) => {
                              checkName(e);
                            }}
                          />
                        </div>
                        <div className="flex-md-60 pad-lt-5">
                          <label>User Name </label>

                          <input
                            type="text"
                            className="form-elm"
                            name="username"
                            placeholder="User Name"
                            value={username}
                            maxLength={15}
                            onChange={(e) => {
                              checkUserName(e);
                            }}
                          />
                          <small className="errmsg">
                            {usernamecheck === "Valid" ? (
                              <span className="color-green">
                                {usernamecheck}
                              </span>
                            ) : (
                              <span className="color-red">{usernamecheck}</span>
                            )}
                          </small>
                        </div>

                        <div className="flex-md-100">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-elm"
                            name="email"
                            value={Emailid}
                            placeholder="example@gmail.com"
                            onChange={EmailValidation}
                            maxLength={50}

                          // onChange={(e) => setEmailid(e.target.value)}
                          />
                        </div>
                        <div className="flex-md-40 ">
                          <label>Select Position</label>
                          <select
                            style={{ height: "50px" }}
                            className="form-elm"
                            id="Postion"
                            name="Postion"
                            value={Postion}
                            onChange={(e) => setSelectedPostion(e.target.value)}
                          >
                            <option value={0}>Select Position</option>
                            <option value={1}>Left</option>
                            <option value={2}>Right</option>
                          </select>
                        </div>
                        <div className="flex-md-60 pad-lt-5">
                          <label>Mobile No.</label>
                          <input
                            type="text"
                            className="form-elm"
                            placeholder="Mobile Number"
                            value={mobileNo}
                            onChange={Mobile_Data}
                            maxLength={12}
                          // onChange={(e) => setmobileNo(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="bg form-area" style={{ paddingTop: 0 }}>
                        <div className="flexbox">
                          <div className="flex-md-100">
                            <small className="color-red errmsg">
                              Create Strong Password
                            </small>
                            <small className="color-green sucmsg" />
                          </div>
                          <div className="flex-md-50">
                            <label>
                              Password{" "}
                              <span
                                className="text-sm color-green"
                                onClick={(e) => {
                                  Changepasswordtype(e);
                                }}
                              >
                                (SHOW/HIDE)
                              </span>
                            </label>
                            <input
                              type={showPassword === 1 ? "text" : "password"}
                              className="form-elm regpassinp"
                              onkeyup="return strong_password(this.value);"
                              name="password"
                              placeholder="xxxxxxxx"
                              maxLength={20}
                              required=""
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                            />
                          </div>
                          <div className="flex-md-50 pad-lt-5">
                            <label>Confirm Password</label>
                            <input
                              type={showPassword === 1 ? "text" : "password"}
                              className="form-elm confregpassinp"
                              name="confpassword"
                              placeholder="xxxxxxxx"
                              required=""
                              maxLength={20}
                              onChange={(e) => {
                                setCpassword(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="bg form-area">
                        <span className="note">
                          <input
                            className="w-auto"
                            type="checkbox"
                            name="terms"
                            checked={checkbox}
                            onChange={(e) => setcheckbox(e.target.checked)}
                            required=""
                          />{" "}
                          I agree with all terms &amp; conditons &amp; privacy
                          police.
                        </span>
                      </div>
                      <div className="bg form-area d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-primary bounce-top"
                          onClick={() => {
                            RegistrationAPI();
                          }}
                        >
                          Confirm &amp; Register
                        </button>
                      </div>

                      <div className="bg form-area d-flex justify-content-center">
                        <span className="note">
                          I have already registered{" "}
                          <Link to="/Login">Login Here</Link>
                        </span>
                      </div>
                      {/* </form> */}
                    </div>
                    {/* <div
                      className="bg my-wait-block hide pt-50 pb-50"
                      align="center"
                    >
                      <div className="bg">
                        <span>
                          Please wait, While processing your request..
                        </span>
                      </div>
                      <div className="bg pt-20 pb-20">
                        <img src="./loading.svg" style={{ height: 80 }} />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-md-15 flex-xs-100" />
        <div className="flex-md-100 text-center">
          <span className="text-sm disp-block color-gray">
            Created By <a href="https://innowise-group.com/">Innowise Group</a>.
            All Rights Reserved By REEO.
          </span>
        </div>
      </div>
    </div>
  );
}
