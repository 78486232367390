import "./App.css";
import { ToastContainer } from "react-toastify";
import { Toaster } from "react-hot-toast";
import Login from "./Components/Login/Login";
import ForgetPassword from "./Components/ForgetPassword/ForgetPassword";
import { Route, Routes } from "react-router-dom";
import SignUp from "./Components/SignUp/SignUp";
import DashBoard from "./Components/DashBoard/DashBoard";
import Network from "./Components/Network/Network";
import Profile from "./Components/Profile/Profile";
import Withdraw from "./Components/Withdraw/Withdraw";
import Changepassword from "./Components/Changepassword/Changepassword";
import TeamBusiness from "./Components/TeamBusiness/TeamBusiness";
import TreeView from "./Components/TreeView/TreeView";
import PoolTeam from "./Components/PoolTeam/PoolTeam";
import Hipayteam from "./Components/Hipayteam/Hipayteam";
import Hero from "./Components/Hero/Hero";
import IDCardOne from "./Components/IdCard/IDCardOne";
import IDCardtwo from "./Components/IdCard/IDCardtwo";
import Contact from "./Components/Hero/Contact";
import "react-toastify/dist/ReactToastify.css";
import UserAwards from "./Components/Incomes/UserAwards";
import RoiIncome from "./Components/Incomes/RoiIncome";
import BinaryIncome from "./Components/Incomes/BinaryIncome";
import Deffirencialincome from "./Components/Incomes/Deffirencialincome";
import DirectIncome from "./Components/Incomes/DirectIncome";
import RoyalityIncome from "./Components/Incomes/RoyalityIncome";
import RewardIncome from "./Components/Incomes/RewardIncome";
import BonanzaReport from "./Components/Incomes/BonanzaReport";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import PrivateRoutes from "./Utility/Private/PrivateRoutes";
import ShopLogin from "./Components/ShopLogin/ShopLogin";
import DashShopNav from "./Components/DashShopNav/DashShopNav";
import Shop_Dasboard from "./Components/ShopDasboard/ShopDasboard";
import Receive_Stock from "./Components/Receive_Stock/Receive_Stock";
import Stock_Statement from "./Components/Stock_Statement/Stock_Statement";
import Activation_Product_Order_Request from "./Components/Activation_Product_Order_Request/Activation_Product_Order_Request";
import Activation_Product_Order_History from "./Components/Activation_Product_Order_History/Activation_Product_Order_History";
import FranchiseCommission from "./Components/FranchiseCommission/FranchiseCommission";
import FranchiseRoiCommission from "./Components/FranchiseRoiCommission/FranchiseRoiCommission";
import SalesCommission from "./Components/SalesCommission/SalesCommission";
import FrenWithdrawLog from "./Components/FrenWithdraw/FrenWithdrawLog";
import FrenWithdraw from "./Components/FrenWithdraw/FrenWithdraw";
import ProductRequestList from "./Components/ProductRequestList/ProductRequestList";
import ProductRequestListHistory from "./Components/ProductRequestListHistory/ProductRequestListHistory";
import WithdrawLog from "./Components/Withdraw/WithdrawLog";
import FundRequest from "./Components/Fund Request/FundRequest";
import FundRequestUpi from "./Components/Fund Request/FundRequestUpi";
import FundReceive from "./Components/Fund Receive/FundReceive";
import FundTransfer from "./Components/Fund Transfer/FundTransfer";
import ActivateUpgrade from "./Components/ActivateUpgrade/ActivateUpgrade";
import UpgradeId from "./Components/ActivateUpgrade/UpgradeId";
import Activation_history from "./Components/ActivateUpgrade/Activation_history";
import DownlineActivationHistory from "./Components/ActivateUpgrade/DownlineActivationHistory";
import WelcomePage from "./Components/WelcomePage/WelcomePage";
import ShopProfile from "./Components/ShopProfile/ShopProfile";
import ShopChangepassword from "./Components/ShopChangepassword/ShopChangepassword";
import StockMothlyIncome from "./Components/StockMothlyIncome/StockMothlyIncome";
import CheckActivation from "./Components/Activation_Product_Order_Request/CheckActivation";
import BuyProduct from "./Components/BuyProduct/BuyProduct";
import Invoice from "./Components/Product History/Invoice";
import ProductHistory from "./Components/Product History/ProductHistory";
import Document from "./Components/Hero/Document";
import VermiCompost from "./Components/Hero/VermiCompost";
import Plantation from "./Components/Hero/Plantation";
import Kitchengardening from "./Components/Hero/Kitchengardening";

import Support from "./Components/Support/Support";
import News from "./Components/News/News";
import NewTree from "./Components/Binary Tree/NewTree";
import Agrmnt from "./Components/User Agrmnt/Agrmnt";
import JivanWelcome from "./Components/User Agrmnt/JivanWelcome";
import MakeInvoice from "./Components/Product History/MakeInvoice";
import ROIPayout from "./Components/Withdraw/ROIPayout";

function App() {
  return (
    <>
      <ToastContainer />
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route path="/" element={<Hero />}></Route>
        <Route path="/Contact" element={<Contact />}></Route>

        <Route path="/document" element={<Document />}></Route>
        <Route path="/VermiCompost" element={<VermiCompost />}></Route>
        <Route path="/Plantation" element={<Plantation />}></Route>
        <Route path="/Kitchengardening" element={<Kitchengardening />}></Route>


        <Route exact path="/Login" element={<Login />}></Route>
        <Route
          exact
          path="/ForgetPassword"
          element={<ForgetPassword />}
        ></Route>
        <Route exact path="/Shop_Login" element={<ShopLogin />}></Route>
        <Route exact path="/DashShopNav" element={<DashShopNav />}></Route>
        <Route exact path="/Shop_Dasboard" element={<Shop_Dasboard />}></Route>
        <Route exact path="/Receive_Stock" element={<Receive_Stock />}></Route>
        <Route exact path="/ShopProfile" element={<ShopProfile />}></Route>
        <Route
          exact
          path="/ShopChangepassword"
          element={<ShopChangepassword />}
        ></Route>
        <Route
          exact
          path="/Activation_Product_Order_Request"
          element={<Activation_Product_Order_Request />}
        ></Route>
        <Route
          exact
          path="/Activation_Product_Order_History"
          element={<Activation_Product_Order_History />}
        ></Route>
        <Route
          exact
          path="/FranchiseCommission"
          element={<FranchiseCommission />}
        ></Route>
        <Route
          exact
          path="/FranchiseRoiCommission"
          element={<FranchiseRoiCommission />}
        ></Route>
        <Route
          exact
          path="/checkactivation"
          element={<CheckActivation />}
        ></Route>
        <Route
          exact
          path="/SalesCommission"
          element={<SalesCommission />}
        ></Route>
        <Route
          exact
          path="/Stock_Statement"
          element={<Stock_Statement />}
        ></Route>
        <Route exact path="/FrenWithdraw" element={<FrenWithdraw />}></Route>
        <Route
          exact
          path="/FrenWithdrawLog"
          element={<FrenWithdrawLog />}
        ></Route>
        <Route
          exact
          path="/ProductRequestList"
          element={<ProductRequestList />}
        ></Route>
        <Route
          exact
          path="/ProductRequestListHistory"
          element={<ProductRequestListHistory />}
        ></Route>
        <Route exact path="/SignUp" element={<SignUp />}></Route>
        <Route exact path="/WelcomePage" element={<WelcomePage />}></Route>
        <Route element={<PrivateRoutes />}>
          <Route exact path="/DashBoard" element={<DashBoard />}></Route>
          <Route path="/Network" element={<Network />}></Route>
          <Route path="/Profile" element={<Profile />}></Route>
          <Route path="/Withdraw" element={<Withdraw />}></Route>
          <Route path="/withdrawLog" element={<WithdrawLog />}></Route>
          <Route path="/ROIPayout" element={<ROIPayout />}></Route>
          <Route path="/Change_Password" element={<Changepassword />}></Route>
          <Route path="/BuyProduct" element={<BuyProduct />}></Route>
          <Route path="/TeamBusiness" element={<TeamBusiness />}></Route>
          <Route path="/TreeView" element={<TreeView />}></Route>
          <Route path="/TreenewView" element={<NewTree />} />
          <Route path="/PoolTeam" element={<PoolTeam />}></Route>
          <Route path="/Hipayteam" element={<Hipayteam />}></Route>
          <Route path="/UserAwards" element={<UserAwards />}></Route>
          <Route path="/RoiIncome" element={<RoiIncome />}></Route>
          <Route path="/BinaryIncome" element={<BinaryIncome />}></Route>
          <Route
            path="/Deffirencialincome"
            element={<Deffirencialincome />}
          ></Route>
          <Route path="/RoyalityIncome" element={<RoyalityIncome />}></Route>
          <Route path="/RewardIncome" element={<RewardIncome />}></Route>
          <Route path="/BonanzaReport" element={<BonanzaReport />}></Route>
          <Route path="/DirectIncome" element={<DirectIncome />}></Route>
          <Route path="/fundRequest" element={<FundRequest />}></Route>
          <Route path="/fundTransfer" element={<FundTransfer />}></Route>
          <Route path="/fundReceive" element={<FundReceive />}></Route>
          <Route
            exact
            path="/ActivateUpgrade"
            element={<ActivateUpgrade />}
          ></Route>
          <Route exact path="/UpgradeId" element={<UpgradeId />}></Route>
          <Route
            exact
            path="/Activation_history"
            element={<Activation_history />}
          ></Route>
          <Route
            exact
            path="/DownlineActivationHistory"
            element={<DownlineActivationHistory />}
          ></Route>
          <Route
            path="/StockMothlyIncome"
            element={<StockMothlyIncome />}
          ></Route>
          <Route path="/BuyProduct" element={<BuyProduct />}></Route>
          <Route
            path="/ActivatedproductHistory"
            element={<ProductHistory />}
          ></Route>
          <Route path="/IDCardOne" element={<IDCardOne />}></Route>
          <Route path="/IDCardtwo" element={<IDCardtwo />}></Route>
          <Route exact path="/Agreement" element={<Agrmnt />}></Route>
          <Route exact path="/jivanwelcome" element={<JivanWelcome />}></Route>
          <Route exact path="/invoice" element={<Invoice />}></Route>
          <Route exact path="/Support" element={<Support />}></Route>
          <Route exact path="/News" element={<News />}></Route>
          <Route exact path="/MakeInvoice" element={<MakeInvoice />}></Route>
          <Route
            exact
            path="/FundRequestUpi"
            element={<FundRequestUpi />}
          ></Route>
        </Route>
        <Route path="*" element={<PageNotFound />}></Route>
      </Routes>
    </>
  );
}

export default App;
