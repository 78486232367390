import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { API } from "../../API/Api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditProfile({ userData, userBankData, user }) {
  const [value, setValue] = React.useState(0);
  const [getData, setgetData] = useState([]);
  const [getBankData, setgetBankData] = useState([]);
  const [fileValue, setfileValue] = useState("");
  const [countries, setcountries] = useState([]);
  const [spinner, setspinner] = useState(false);
  //console.log("userBankData-->", userBankData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const [Profileotp, setProfileOTP] = useState("");
  const [Profileotpvalid, setProfileOTPValid] = useState("0");

  const [Bankotp, setBankOTP] = useState("");
  const [Bankotpvalid, setBankOTPValid] = useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //////////////////////My Referral Link//////////////

  let URL = window.location.origin;
  // console.log("URL",URL);

  //console.log("file", fileValue);

  /////////////// Profile Update//////////////////////////

  const handleGet = (event) => {
    const { name, value } = event.target;
    if (name === "mobile" || name === "pincode") {
      const newValue = value.replace(/[^0-9]/gi, "");
      event.target.value = newValue;
    }
    setgetData({ ...getData, [name]: value });
  };

  const handleBankGet = (event) => {
    const { name, value } = event.target;
    setgetBankData({ ...getBankData, [name]: value });
  };

  const handlefile = (event) => {
    let file = event.target.files[0];
    setfileValue(file);
    // console.log("File", file);
  };

  const hendleOTPProfile = (event) => {
    const newValue = event.replace(/[^0-9]/gi, "");
    setProfileOTP(newValue);
  };

  const hendleOTPBank = (event) => {
    const newValue = event.replace(/[^0-9]/gi, "");
    setBankOTP(newValue);
  };

  const SendOtpProfile_API = async () => {
    try {
      let res = await API.post(
        "updateProfileOtp",
        {
          email: getData?.email || userData.email,
          name: getData?.firstName || userData.f_name,
          mobile: getData?.mobile || userData.mobile,
          dob: getData?.dob || userData?.dob,
          address: getData?.address || userData.address,
          pan: getData?.panno || userData.panno,
          cityname: getData?.cityname || userData.cityname,
          pincode: getData?.pincode || userData.pincode,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      res = res?.data?.data;
      console.log("SendOtp_API", res);
      if (res === "Successfull") {
        setProfileOTPValid("1");
        toast.success("OTP Send Successfully !!");
      } else {
        setProfileOTPValid("0");
        toast.error(res);
      }
    } catch (e) {
      console.log("Somthing Error in SendOtp_API", e);
    }
  };

  const SendOtpBank_API = async () => {
    try {
      let res = await API.post(
        "updateBankOtp",
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      //console.log("SendOtp_API", res.data.data);
      res = res.data.data;
      if (res === "Successfull") {
        setBankOTPValid("1");
        toast.success("OTP Send Successfully !!");
      } else {
        setBankOTPValid("0");
        toast.error(res);
      }
    } catch (e) {
      console.log("Somthing Error in SendOtp_API", e);
    }
  };

  const updateProfile = async () => {
    try {
      setspinner(true);

      let updateRes = await API.post(
        `updateProfile`,
        {
          user_id: userId,
          name: getData?.firstName || userData.f_name,
          email: getData?.email || userData.email,
          mobile: getData?.mobile || userData.mobile,
          dob: getData?.dob || userData?.dob,
          address: getData?.address || userData.address,
          pan: getData?.panno || userData.panno,
          cityname: getData?.cityname || userData.cityname,
          pincode: getData?.pincode || userData.pincode,
          countryid: 1,
          stateid: 1,
          img: "",
          salution: "",
          adhar: "",
          otp: Profileotp,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      //console.log("updateRes-->", updateRes.data);
      if (updateRes.data.data == "Profile Updated Successfully !!") {
        toast.success(updateRes.data.data);
        setspinner(false);
        window.location.href = "/Profile";
      } else {
        toast.error(updateRes.data.data);
      }
    } catch (error) {
      toast.error("Something Wrong");

      console.log(error);
      setspinner(false);
    }
  };

  const countryList = async () => {
    try {
      let res = await API.get("/countryList");
      // console.log("countryAAA", res.data.data[0]);
      setcountries(res.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    countryList();
  }, []);

  ///////////////////////////////e-Currency Address/////////////////////////

  const [getE_CurrencyAddrress, setgetE_CurrencyAddrress] = useState("");
  const [otp_flag, setotp_flag] = useState(false);
  const [getOTP, setgetOTP] = useState("");

  const updateBankProfile = async () => {
    try {
      setspinner(true);
      let updateRes = await API.post(
        `updateUserBankDetails`,
        {
          userId: userId,
          pan: getBankData?.panno || userBankData.panno,
          accountholder: getBankData?.holder || userBankData.holder,
          branch: getBankData?.branch || userBankData.branch,
          bankName: getBankData?.bank_name || userBankData.bank_name,
          Ifsc: getBankData?.ifsc || userBankData.ifsc,
          accountnumber:
            getBankData?.accountnumber || userBankData.accountnumber,
          otp: Bankotp,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("updatebankRes-->", updateRes.data);
      if (updateRes.data.data === "Bank Updated Successfully !!") {
        toast.success(updateRes.data.data);
        setspinner(false);
        setTimeout(() => {
          window.location.href = "/Profile";
        }, 1000);
      } else {
        toast.error(updateRes.data.data);
      }
    } catch (error) {
      toast.error("Something Wrong");

      console.log(error);
      setspinner(false);
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            className="over"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab label="About" {...a11yProps(0)} />
            <Tab label="Edit Profile" {...a11yProps(1)} />
            <Tab label="Bank Details" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <>
            <div className="row">
              <div className="col-md-4 b-r">
                <label>Full Name</label>
                <p className="ng-binding" style={{ color: "rgb(194, 16, 16)" }}>
                  {userData.f_name}
                </p>
              </div>
              <div className="col-md-4 b-r">
                <label>Mobile</label>
                <p
                  className=" ng-binding"
                  style={{ color: "rgb(194, 16, 16)" }}
                >
                  {userData.mobile}
                </p>
              </div>
              <div className="col-md-4 b-r ">
                <label>Email</label>
                <p className="ng-binding" style={{ color: "rgb(194, 16, 16)" }}>
                  {userData.email}
                </p>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-9 col-md-6 col-12 b-r">
                <Box
                  component="form"
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      fullWidth
                      id="outlined-read-only-input "
                      label="My Left Referral Link"
                      value={`${URL}/SignUp?userid=${userData.Username}&position=1`}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ "margin-top": "10px" }}>
                    <TextField
                      fullWidth
                      id="outlined-read-only-input "
                      label="My Right Referral Link"
                      value={`${URL}/SignUp?userid=${userData.Username}&position=2`}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </Box>
              </div>
              <div></div>
            </div> */}
          </>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <>
            <div className="card-header">
              <h4 style={{ color: "rgb(194, 16, 16)" }}>Edit Profile</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-12 col-12">
                  <label>Full Name</label>
                  <input
                    type="text"
                    ng-model="p.userName"
                    name="firstName"
                    title="first name rquired"
                    required=""
                    maxLength={40}
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="First Name"
                    defaultValue={userData.f_name}
                    onChange={handleGet}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-7 col-12">
                  <label>Email</label>
                  <input
                    type="text"
                    ng-model="p.email"
                    name="email"
                    maxLength={70}
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    required=""
                    title="Email is required"
                    placeholder="Email"
                    defaultValue={userData.email}
                    onChange={handleGet}
                  />
                  <div className="invalid-feedback">
                    Please fill in the email
                  </div>
                </div>
                <div className="form-group col-md-5 col-12">
                  <label>Phone</label>
                  <input
                    type="text"
                    ng-model="p.mobile"
                    name="mobile"
                    maxLength={12}
                    className="form-control ng-pristine ng-untouched ng-valid-maxlength ng-not-empty ng-valid ng-valid-required"
                    required=""
                    title="Phone No. is  required"
                    placeholder="Phone Number"
                    defaultValue={userData.mobile}
                    onChange={handleGet}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6 col-12">
                  <label>DOB</label>
                  <input
                    type="date"
                    name="dob"
                    id="dob"
                    className="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                    required=""
                    title="Dob is  required"
                    // placeholder={userData.dob}
                    defaultValue={userData.dob}
                    onChange={handleGet}
                  />
                </div>
                {/*<div className="form-group col-md-6 col-12">
                  <label>PAN No.</label>
                  <input
                    type="text"
                    ng-model="p.panno"
                    name="panno"
                    id="panno"
                    maxLength={10}
                    className="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                    required=""
                    title="Pan No. is  required"
                    // placeholder={userData.dob}
                    defaultValue={userData.panno}
                    onChange={handleGet}
                  />
                </div>*/}
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-12">
                  <label>Address</label>
                  <textarea
                    name="address"
                    ng-model="p.address"
                    placeholder="Address"
                    maxLength={100}
                    className="form-control summernote-simple ng-pristine ng-untouched ng-valid ng-empty"
                    defaultValue={userData.address}
                    onChange={handleGet}
                  />
                  <div className="invalid-feedback">
                    Please fill in the address
                  </div>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label>City Name</label>
                  <input
                    type="text"
                    ng-model="p.cityname"
                    name="cityname"
                    maxLength={30}
                    className="form-control ng-pristine ng-untouched ng-valid-maxlength ng-not-empty ng-valid ng-valid-required"
                    required=""
                    title="City is  required"
                    placeholder="City"
                    defaultValue={userData.cityname}
                    onChange={handleGet}
                  />
                  <div className="invalid-feedback">
                    Please fill in the City
                  </div>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label>Pincode</label>
                  <input
                    type="text"
                    ng-model="p.pincode"
                    name="pincode"
                    maxLength={7}
                    className="form-control ng-pristine ng-untouched ng-valid-maxlength ng-not-empty ng-valid ng-valid-required"
                    required=""
                    title="PinCode is required"
                    placeholder="Pincode"
                    defaultValue={userData.pincode}
                    onChange={handleGet}
                  />
                  <div className="invalid-feedback">
                    Please fill in the Pincode
                  </div>
                </div>
                {/*{Profileotpvalid === "1" ? (
                  <>
                    <div className="form-group col-md-6 col-12">
                      <label>OTP</label>
                      <input
                        type="text"
                        name="otp"
                        maxLength={10}
                        className="form-control ng-pristine ng-untouched ng-valid-maxlength ng-not-empty ng-valid ng-valid-required"
                        required=""
                        title="OTP is required"
                        placeholder="OTP"
                        onChange={(e) => hendleOTPProfile(e.target.value)}
                        value={Profileotp}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}*/}
              </div>
            </div>
            <div className="card-footer text-end">
              {/* {Profileotpvalid === "0" ? (
                <button
                  className="col-5 mt-3 py-3 wdth_btn btn mb-3"
                  onClick={SendOtpProfile_API}
                  style={{ color: "#000" }}
                >
                  Send OTP
                </button>
              ) : ( */}
              <button
                type="button"
                ng-click=""
                className="btn btn-success"
                style={{ height: "auto" }}
                onClick={updateProfile}
              >
                Update Profile
                {/* {spinner ? "Loading..." : "Save Changes"} */}
              </button>
              {/* )} */}
            </div>
          </>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <>
            <div className="card-header">
              <h4 style={{ color: "rgb(194, 16, 16)" }}>Bank Details</h4>
            </div>
            <div className="card-body">
              <input
                type="hidden"
                name="_token"
                defaultValue=""
                autoComplete="off"
              />
              <div className="row">
                <div className="form-group col-md-4 col-12">
                  <label>Accountholder Name</label>
                  <input
                    type="text"
                    ng-model="p.holder"
                    name="holder"
                    title="Accountholder Name required"
                    required=""
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="Accountholder Name"
                    defaultValue={userBankData.holder}
                    onChange={handleBankGet}
                  />
                </div>
                <div className="form-group col-md-4 col-12">
                  <label>Account No.</label>
                  <input
                    type="text"
                    ng-model="p.accountnumber"
                    name="accountnumber"
                    title="Account No. required"
                    required=""
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="Account No."
                    defaultValue={userBankData.accountnumber}
                    onChange={handleBankGet}
                  />
                </div>
                <div className="form-group col-md-4 col-12">
                  <label>IFSC</label>
                  <input
                    type="text"
                    ng-model="p.ifsc"
                    name="ifsc"
                    title="IFSC required"
                    required=""
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="IFSC"
                    defaultValue={userBankData.ifsc}
                    onChange={handleBankGet}
                  />
                </div>
                <div className="form-group col-md-4 col-12">
                  <label>Bank Name</label>
                  <input
                    type="text"
                    ng-model="p.bank_name"
                    name="bank_name"
                    title="Bank Name required"
                    required=""
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="Bank Name"
                    defaultValue={userBankData.bank_name}
                    onChange={handleBankGet}
                  />
                </div>
                <div className="form-group col-md-4 col-12">
                  <label>Branch Name</label>
                  <input
                    type="text"
                    ng-model="p.branch"
                    name="branch"
                    title="Branch Name required"
                    required=""
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="Branch Name"
                    defaultValue={userBankData.branch}
                    onChange={handleBankGet}
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                  <label>PAN No.</label>
                  <input
                    type="text"
                    ng-model="p.panno"
                    name="panno"
                    id="panno"
                    maxLength={10}
                    className="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
                    required=""
                    title="Pan No. is  required"
                    // placeholder={userData.dob}
                    defaultValue={userBankData.panno}
                    onChange={handleBankGet}
                  />
                </div>
                {/*{Bankotpvalid === "1" ? (
                  <>
                    <div className="form-group col-md-6 col-12">
                      <label>OTP</label>
                      <input
                        type="text"
                        name="otp"
                        maxLength={10}
                        className="form-control ng-pristine ng-untouched ng-valid-maxlength ng-not-empty ng-valid ng-valid-required"
                        required=""
                        title="OTP is required"
                        placeholder="OTP"
                        onChange={(e) => hendleOTPBank(e.target.value)}
                        value={Bankotp}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}*/}
              </div>
              {/* {Bankotpvalid === "0" ? (
                <button
                  className="col-5 mt-3 py-3 wdth_btn btn mb-3 fw-bold"
                  onClick={SendOtpBank_API}
                  style={{ color: "#fff", background: "#3aa43a" }}
                >
                  Save Changes
                </button>
              ) : ( */}
              <button
                type="button"
                ng-click=""
                className="btn btn-success"
                style={{ height: "auto" }}
                onClick={updateBankProfile}
              >
                Update Bank Details
                {/* {spinner ? "Loading..." : "Update Bank Details"} */}
              </button>
              {/* )} */}
            </div>
          </>
        </CustomTabPanel>
      </Box>
    </>
  );
}
