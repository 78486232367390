import React, { useEffect, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { API } from "../../API/Api";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import TableButton from "../TableButton/TableButton";

const Support = () => {
  const [ReportApi, setReportApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [query, setQuery] = useState("");
  const [queryText, setText] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const Submit_API = async (event) => {
    event.preventDefault();
    try {
      let responce = await API.post(
        "HelpDesk",
        {
          uid: userId,
          query: query,
          querTypeText: queryText,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce;
      // console.log("Submit-->", responce);
      Report_API();
      setQuery("");
      setText("");
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const Report_API = async () => {
    try {
      let responce = await API.post(
        "GetHelpdeskuser",
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce.data.data[0];
      console.log("res-->", responce);
      setReportApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          status: item.status,
          edate: item.edate[0],
          subject: item.subject,
          reply_message: item.reply_message,
          ticket_id: item.ticket_id,
          query: item.query,
        });
      });

      setReportApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };
  useEffect(() => {
    Report_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = ReportApi.slice(indexOfFirstPage, indexOfLastPost);

  var [reportData, set_reportData] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "Status", accessor: "status" },
      { Header: "Date", accessor: "edate" },
      { Header: "Subject", accessor: "query" },
      { Header: "Query", accessor: "subject" },
      { Header: "Admin Reply", accessor: "reply_message" },
      { Header: "Ticket Id", accessor: "ticket_id" },
    ],
  });

  function handleQuery(e) {
    const { value } = e.target;
    setQuery(DOMPurify.sanitize(value));
  }

  function handleQueryText(e) {
    const { value } = e.target;
    setText(DOMPurify.sanitize(value));
  }

  return (
    <div>
      <DashBoardNav />
      <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
        <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
          Help &amp; Support
        </span>
        <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
          Dashboard &gt; Support
        </span>
      </div>
      <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
        {/* <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
          <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
            <div className="flex-md-50">
              <span className="disp-block color-grad1 text-big font-thik">
                0
              </span>
              <span className="disp-block color-gray">Open Ticket</span>
            </div>
            <div className="flex-md-50 text-right">
              <span className="disp-block color-grad1 text-big font-thik">
                0
              </span>
              <span className="disp-block color-gray">Closed Ticket</span>
            </div>
          </div>
        </div> */}
        <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-20 pad-rt-20 bg-white bdr-rad-md">
          <form onSubmit={Submit_API} id="queryForm">
            <div className="bg pad-tp-15">
              <label>Subject</label>
              <input
                type="text"
                className="form-elm"
                id="Subject"
                placeholder="Subject"
                onChange={handleQuery}
                value={query}
              />
              {/* <select className="form-elm" name="type" required="">
                <option value="Any Deduction">Any Deduction</option>
                <option value="Deposit Issue">Deposit Issue</option>
                <option value="Withdrawal Issue">Withdrawal Issue</option>
                <option value="P2P Issue">P2P Issue</option>
                <option value="Profile Update">Profile Update</option>
                <option value="Other">Other</option>
              </select> */}
            </div>
            <div className="bg pad-tp-15">
              <label>Your Query</label>
              <textarea
                className="form-elm"
                id="query"
                rows={3}
                value={queryText}
                onChange={handleQueryText}
              />
              {/* <textarea
                name="msg"
                className="form-elm"
                cols={30}
                rows={5}
                placeholder="Write Message"
                required=""
                defaultValue={""}
              /> */}
            </div>
            <div className="bg pad-tp-15">
              <div className="flexbox">
                <div className="flex-md-10">
                  <input
                    className="size-md"
                    type="checkbox"
                    name="terms"
                    required=""
                  />
                </div>
                <div className="flex-md-90">
                  <span>
                    I Accept all the terms of use, privacy policy. I Also
                    acknowledge that, Above information is accurate &amp;
                    authentic as per my current knowledge.
                  </span>
                </div>
              </div>
            </div>
            <div className="bg pad-tp-15 pad-bt-10">
              <button
                type="submit"
                className="bg-red w100 text-md color-white bdr-rad-xs pad-tp-15 pad-bt-15 pad-lt-30 pad-rt-30"
              >
                Submit Query
              </button>
            </div>
          </form>
          <div className="break20" />
          <div className="bg">
            <div className="bg pos-rel pad-bt-20 marg-bt-20">
              <Table data={[...currentPost]} columns={reportData.cols} />
              <TableButton
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={ReportApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
