import React, { useEffect } from "react";
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoTwitter } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import banner from "../../Assets/WebImages/banner.png";
import about from "../../Assets/WebImages/about.jpg";
import about2 from "../../Assets/WebImages/about2.jpg";
import servicecard1 from "../../Assets/WebImages/home-6-service-1.png";
import servicecard2 from "../../Assets/WebImages/home-6-service-2.png";
import servicecard3 from "../../Assets/WebImages/home-6-service-3.png";
import iconservice1 from "../../Assets/WebImages/home-6-service-icon-1.png";
import iconservice2 from "../../Assets/WebImages/home-6-service-icon-2.png";
import iconservice3 from "../../Assets/WebImages/home-6-service-icon-3.png";
import serviveimage1 from "../../Assets/WebImages/home-6-service-4.png";
import serviveimage2 from "../../Assets/WebImages/home-6-service-5.png";
import check from "../../Assets/WebImages/check-circle.png";
import palybtn from "../../Assets/WebImages/play.png";
import arrow from "../../Assets/WebImages/arrow-right.png";
import serviceicon1 from "../../Assets/WebImages/serviceicon1.png";
import serviceicon2 from "../../Assets/WebImages/serviceicon2.png";
import footerimage1 from "../../Assets/WebImages/rv-9-footer-article-1.jpg";
import footerimage2 from "../../Assets/WebImages/rv-9-footer-article-2.jpg";
import scooty from "../../Assets/WebImages/scooty.jpg";
import errrr from "../../Assets/WebImages/errrr.jpg";
import led from "../../Assets/WebImages/led.png";
import mobile_app from "../../Assets/WebImages/mobile_app.png";
import er from "../../Assets/WebImages/er.png";
import logo from "../../Assets/Logo/logo.png";
import bg from "../../Assets/WebImages/bg.jpeg";
import bg1 from "../../Assets/WebImages/bg1.jpeg";
import bg2 from "../../Assets/WebImages/bg2.jpeg";
import phonecolor from "../../Assets/WebImages/phone-altcolor.png";
import emailcolor from "../../Assets/WebImages/envelopecolor.png";
import mapcolor from "../../Assets/WebImages/map-marker-altcolor.png";

import { Carousel } from "antd";
const Hero = () => {
  useEffect(() => {
    const images = [bg1, bg2, bg];
    images.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
    });
  }, []);

  return (
    <div>
      <div className="v_light_purple" data-spy="scroll">
        {/* <div id="loader-wrapper">
          <div id="loading-center-absolute">
            <div className="object" id="object_four" />
            <div className="object" id="object_three" />
            <div className="object" id="object_two" />
            <div className="object" id="object_one" />
          </div>
          <div className="loader-section section-left" />
          <div className="loader-section section-right" />
        </div> */}
        <Header />
        <section
          id="home_section"
          className="section_banner section_gradiant2"
          data-z-index={1}
          data-parallax="scroll"
          data-image-src="~/assets_front/images/banner_bg2.png"
        >
          <Carousel autoplay dots speed={300} className="banners">
            <div className="bannerimage1">
              <div className="container">
                <div className="row  banners">
                  <div className="col-md-6 d-flex flex-column h-100 justify-content-center align-items-center align-items-sm-start">
                    <h3 className="headingbanner">
                      FOREST PLANTATION: RECREATE ENVIRONMENT
                    </h3>
                    <div className="d-flex align-items-center gap-3 flexcolumn d-none">
                      <button className="herobtn">Explore More</button>
                      <div className="d-flex align-items-center gap-3 d-none">
                        <a href="/" className="playbtn">
                          <span>
                            <img
                              className="w-100 h-100"
                              src={palybtn}
                              alt="imagees"
                            />
                          </span>{" "}
                        </a>
                        <p className="mb-0 text-white fw-bold">Play Now</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bannerimage2">
              <div className="container">
                <div className="row  banners">
                  <div className="col-md-6 d-flex flex-column h-100 justify-content-center align-items-center align-items-sm-start">
                    <h3 className="headingbanner">
                      VERMI COMPOST: RECREATE SOIL
                    </h3>
                    <div className="d-flex align-items-center gap-3 flexcolumn d-none">
                      <button className="herobtn">Explore More</button>
                      <div className="d-flex align-items-center gap-3 d-none">
                        <a href="/" className="playbtn">
                          <span>
                            <img
                              className="w-100 h-100"
                              src={palybtn}
                              alt="imagees"
                            />
                          </span>{" "}
                        </a>
                        <p className="mb-0 text-white fw-bold">Play Now</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bannerimage3 ">
              <div className="container">
                <div className="row  banners">
                  <div className="col-md-6 d-flex flex-column h-100 justify-content-center align-items-center align-items-sm-start">
                    <h3 className="headingbanner">
                      KITCHEN GARDENING: RECREATE <br /> HEALTH
                    </h3>
                    <div className="d-flex align-items-center gap-3 flexcolumn d-none">
                      <button className="herobtn">Explore More</button>
                      <div className="d-flex align-items-center gap-3">
                        <a href="/" className="playbtn">
                          <span>
                            <img
                              className="w-100 h-100"
                              src={palybtn}
                              alt="imagees"
                            />
                          </span>{" "}
                        </a>
                        <p className="mb-0 text-white fw-bold">Play Now</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel>
        </section>
        <section id="about">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="text-center res_md_mb_30 res_sm_mb_20">
                  <img
                    className="animation animated zoomIn"
                    data-animation="zoomIn"
                    data-animation-delay="0.2s"
                    src={about}
                    alt="aboutimg11"
                    style={{ animationDelay: "0.2s", opacity: 1 }}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 text_md_center">
                <div className="title_purple_dark position-relative">

                  <h4
                    className="animation animated fadeInUp fw-bold"
                    data-animation="fadeInUp"
                    data-animation-delay="0.4s"
                    style={{ animationDelay: "0.4s", opacity: 1 }}
                  >
                    WHAT WE DO
                  </h4>
                  <p
                    className="animation animated fadeInUp"
                    data-animation="fadeInUp"
                    data-animation-delay="0.6s"
                    style={{ animationDelay: "0.6s", opacity: 1 }}
                  >
                    We empowering farmers on vermicompost in India involves
                    various initiatives aimed at providing them with the
                    knowledge, resources, and support needed to adopt and
                    implement vermicomposting practices effectively. Here
                    are some ways farmers are empowered in this regard:

                  </p>
                  <p
                    className="mb-0 animation animated fadeInUp"
                    data-animation="fadeInUp"
                    data-animation-delay="0.6s"
                    style={{ animationDelay: "0.6s", opacity: 1 }}
                  >
                    1. Vermicompost manufacturing <br />
                    2. Forest plantation <br />

                    3. Kitchen gardening <br />
                    4. Contract farming <br />
                    5. Organic products
                  </p>
                  {/* <div
                    className="text-end position-absolute end-0 bottom-ng5"
                    style={{ bottom: "-165px" }}
                  >
                    <img
                      className="animation animated zoomIn"
                      data-animation="zoomIn"
                      data-animation-delay="0.2s"
                      src={about2}
                      alt="aboutimg2"
                      style={{ animationDelay: "0.2s", opacity: 1 }}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid py-lg-5 py-sm-3 visionmissionsection">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center mb-3">
                <button className="btnheading">
                  <span>vision & mission</span>
                </button>
              </div>
              <div className="col-md-7 col-12 mx-auto mt-3 mb-4 text-center">
                <div className="sectionheading">
                  Let's Ensure The Well-Being of Earth.
                </div>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-md-6">
                <div className="card servicecard border p-3 h-100">
                  <div className="card-body">
                    <div className="card-icon">
                      <img className="" src={serviceicon1} alt="serviceicon1" />
                    </div>
                    <div className="headingcard mt-3">
                      <a className="fw-bold" href="">
                        Our Vission
                      </a>
                    </div>
                    <p>
                      Our mission is to educate the public, individuals,
                      businesses, and institutions about the benefits of compost
                      and to reclaim organic materials from landfills to create
                      soil for community-based agriculture projects.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card servicecard border p-3 h-100">
                  <div className="card-body">
                    <div className="card-icon">
                      <img className="" src={serviceicon2} alt="serviceicon2" />
                    </div>
                    <div className="headingcard mt-3">
                      <a className="fw-bold" href="">
                        Our Mission
                      </a>
                    </div>
                    <p>
                      <ul className="ps-0 mb-0 listst" style={{listStyle:"unset !important"}}>
                        <li style={{listStyle:"disc !important"}}> Transforming Soil</li>
                        <li style={{listStyle:"disc !important"}}> Transforming Lives</li>
                        <li style={{listStyle:"disc !important"}}> Transforming Our Communities</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid py-lg-5 py-sm-3 servicesection">
          <div className="row">
            <div className="col-12">
              <div className="image1">
                <img
                  className="w-100 h-100"
                  src={serviveimage1}
                  alt="imagees"
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 text-center mb-3">
                <button className="btnheading text-uppercase">
                  <span>JIVANDHARA Services</span>
                </button>
              </div>
              <div className="col-lg-4 col-md-6  col-12 mx-auto mt-3 mb-4 text-center">
                <div className="sectionheading">
                  Loving your Garden & Landscape.
                </div>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-md-4">
                <div className="card servicecard">
                  <div className="card-image">
                    <img
                      className="w-100 h-100"
                      src={servicecard1}
                      alt="imagees"
                    />
                  </div>
                  <div className="card-body">
                    <div className="card-icon">
                      <img
                        className="w-100 h-100"
                        src={iconservice1}
                        alt="imagees"
                      />
                    </div>
                    <div className="card-heading">VERMI COMPOST</div>
                    <p>Process of Vermicomposting</p>
                    <div className="hiddenpart">
                      <ul className="list-unstyled ps-0">
                        <li>
                          <span>
                            <img
                              className="w-100 h-100"
                              src={check}
                              alt="imagees"
                            />
                          </span>
                          Collection of Organic Waste
                        </li>
                        <li>
                          <span>
                            <img
                              className="w-100 h-100"
                              src={check}
                              alt="imagees"
                            />
                          </span>
                          Preparation
                        </li>
                        <li>
                          <span>
                            <img
                              className="w-100 h-100"
                              src={check}
                              alt="imagees"
                            />
                          </span>
                          Addition of Earthworms
                        </li>
                        <li>
                          <span>
                            <img
                              className="w-100 h-100"
                              src={check}
                              alt="imagees"
                            />
                          </span>
                          Decomposition
                        </li>
                        <li>
                          <span>
                            <img
                              className="w-100 h-100"
                              src={check}
                              alt="imagees"
                            />
                          </span>
                          Harvesting
                        </li>
                      </ul>
                      <Link className="redmore" to="/VermiCompost">Read More  <span>
                          <img
                            className="w-100 h-100"
                            src={arrow}
                            alt="imagees"
                          />
                        </span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card servicecard">
                  <div className="card-image">
                    <img
                      className="w-100 h-100"
                      src={servicecard2}
                      alt="imagees"
                    />
                  </div>
                  <div className="card-body">
                    <div className="card-icon">
                      <img
                        className="w-100 h-100"
                        src={iconservice2}
                        alt="imagees"
                      />
                    </div>
                    <div className="card-heading">
                    Plantation
                    </div>
                    <div className="hiddenpart">
                      <ul className="list-unstyled ps-0">
                        <li>
                          <span>
                            <img
                              className="w-100 h-100"
                              src={check}
                              alt="imagees"
                            />
                          </span>
                          Commercial Production
                        </li>
                        <li>
                          <span>
                            <img
                              className="w-100 h-100"
                              src={check}
                              alt="imagees"
                            />
                          </span>
                          Alternative Crop Solution
                        </li>
                        <li>
                          <span>
                            <img
                              className="w-100 h-100"
                              src={check}
                              alt="imagees"
                            />
                          </span>
                          Legal Protection and Longevity
                        </li>
                        <li>
                          <span>
                            <img
                              className="w-100 h-100"
                              src={check}
                              alt="imagees"
                            />
                          </span>
                          Environmental Benefits
                        </li>
                      </ul>
                      <Link className="redmore" to="/Plantation">Read More  <span>
                          <img
                            className="w-100 h-100"
                            src={arrow}
                            alt="imagees"
                          />
                        </span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div class="card servicecard">
                  <div class="card-image">
                    <img class="w-100 h-100" src={servicecard3} alt="imagees" />
                  </div>
                  <div class="card-body">
                    <div class="card-icon">
                      <img
                        class="w-100 h-100"
                        src={iconservice3}
                        alt="imagees"
                      />
                    </div>
                    <div class="card-heading">Kitchen gardening </div>
                    <div class="hiddenpart">
                      <ul class="list-unstyled ps-0">
                        <li>
                          <span>
                            <img
                              class="w-100 h-100"
                              src={check}
                              alt="imagees"
                            />
                          </span>
                          Definition
                        </li>
                        <li>
                          <span>
                            <img
                              class="w-100 h-100"
                              src={check}
                              alt="imagees"
                            />
                          </span>
                          Location
                        </li>
                        <li>
                          <span>
                            <img
                              class="w-100 h-100"
                              src={check}
                              alt="imagees"
                            />
                          </span>
                          Purpose
                        </li>
                        <li>
                          <span>
                            <img
                              class="w-100 h-100"
                              src={check}
                              alt="imagees"
                            />
                          </span>
                          Resource
                        </li>
                      </ul>
                      <Link className="redmore" to="/Kitchengardening">Read More  <span>
                          <img
                            className="w-100 h-100"
                            src={arrow}
                            alt="imagees"
                          />
                        </span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="image2">
                <img
                  className="w-100 h-100"
                  src={serviveimage2}
                  alt="imagees"
                />
              </div>
            </div>
          </div>
        </section>
        <footer className="purple_dark_bg footer_pattern footermain">
          <div className="top_footer">
            <div className="container">
              <div className="row align-items-center g-3 border-bottom pb-3">
                <div className="col-md-3 col-sm-6 col-12">
                  <a href="#" className="logofooter">
                    <img className="w-100 h-100" src={logo} alt="logo" />
                  </a>
                </div>
                <div className="col-md-3 col-sm-6 col-12">
                  <div className="footericon_group">
                    <a href="tel:+919068282716" className="icons">
                      <img
                        className="w-100 h-100"
                        src={phonecolor}
                        alt="icon"
                      />
                    </a>
                    <div className="footertopsection">
                      <div className="footerheadingtop text-white fw-bold">
                        Our Phone
                      </div>
                      <a
                        className="numbertext text-secondary"
                        href="tel:+911169652119"
                      >
                        +911169652119
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-12">
                  <div className="footericon_group">
                    <a href="mailto:info@jivandhara.org" className="icons">
                      <img
                        className="w-100 h-100"
                        src={emailcolor}
                        alt="icon"
                      />
                    </a>
                    <div className="footertopsection">
                      <div className="footerheadingtop text-white fw-bold">
                        Our Email
                      </div>
                      <a
                        className="numbertext text-secondary"
                        href="mailto:info@jivandhara.org"
                      >
                        info@jivandhara.live
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-12 pb-3 pb-sm-0">
                  <div className="footericon_group">
                    <div className="icons">
                      <img className="w-100 h-100" src={mapcolor} alt="icon" />
                    </div>
                    <div className="footertopsection">
                      <div className="footerheadingtop text-white fw-bold">
                        Our Address
                      </div>
                      <div className="numbertext text-secondary">
                        3C/104, Avas Vikas, <br /> Buddhi Bihar, Moradabad,
                        <br /> 244001
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 py-4">
                <div className="col-md-3">
                  <div className="footerheading">WHAT WE DO</div>
                  <p className="subtext">
                  We empowering farmers on vermicompost in India involves various initiatives aimed at providing them with the knowledge, resources, and support needed to adopt and implement vermicomposting practices effectively. Here are some ways farmers are empowered in this regard:
                  </p>
                  <ul className="iconlistsocial">
                    <li>
                      <a href="">
                        <FaFacebookF className=" fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <IoLogoTwitter className=" fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <FaInstagram className=" fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <FaTelegramPlane className=" fa fa-telegram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2">
                  <div className="footerheading">Our Services</div>
                  <ul>
                    <li>
                      <Link className="linkf" to="/VermiCompost/#">Vermi Compost</Link>
                    </li>
                    <li>
                    <Link className="linkf" to="/Plantation/#">Plantation</Link>
                    </li>
                    <li>
                    <Link className="linkf" to="/Kitchengardening/#">Kitchen gardening</Link>
                    </li>
                    <li>
                      <a href="/">Contract Farming </a>
                    </li>
                    <li>
                      <a href="/">Organic Product </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <div className="footerheading">Recent Posts</div>
                  <div className="imagetextgroup mb-3">
                    <div className="imagefooter">
                      <img
                        className="w-100 h-100"
                        src={footerimage1}
                        alt="imagefooter"
                      />
                    </div>
                    <div className="d-flex gap-1 flex-column">
                      <div className="text-white datetext">June 16, 2023</div>
                      <div className="fw-bold text-white querytext">
                        Ways to Freshen Up Curtains & Drapes Home.
                      </div>
                    </div>
                  </div>
                  <div className="imagetextgroup mb-3">
                    <div className="imagefooter">
                      <img
                        className="w-100 h-100"
                        src={footerimage2}
                        alt="imagefooter"
                      />
                    </div>
                    <div className="d-flex gap-1 flex-column">
                      <div className="text-white datetext">July 21, 2023</div>
                      <div className="fw-bold text-white querytext">
                        Streamlining Your Laundry Routine Through Automation.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="footerheading">Newsletter Signup</div>
                  <div class="nwsltr-top mb-3 position-absolute">
                    <input
                      type="email"
                      name="email"
                      id="rv-8-subs-form"
                      placeholder="Enter your Email..."
                    />
                    <button>
                      <span>
                        <img
                          className="w-100 h-100"
                          src={arrow}
                          alt="imagearrow"
                        />
                      </span>
                    </button>
                  </div>
                  <div class="rv-6-footer-nwsltr__checkbox d-flex align-items-center gap-3">
                    <input
                      type="checkbox"
                      className="w-auto"
                      id="nwsltr-checkbox"
                      name="checkbox"
                      value="1"
                    />
                    <label className="mb-0" for="nwsltr-checkbox">
                      {" "}
                      I agree to the <a href="#">Privacy Policy</a>.
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-footer">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer_bottom">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <p className="copyright">
                          Copyright © 2024 jivandhara.live | All Rights
                          Reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Hero;
