import React, { useEffect, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../API/Api";
import { toast } from "react-toastify";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { Api_Fren } from "../../API/Api_Fren";
import DashShopNav from "../DashShopNav/DashShopNav";

const ShopChangepassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const Dashboard_API = async () => {
    try {
      let res = await Api_Fren.get(`userDashboard_Fre?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("API Response", res.data.data[0]);
      setData(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/Shop_Login");
      }
    }
  };

  useEffect(() => {
    Dashboard_API();
  }, []);


  const onFinish = async (values) => {
    // console.log("Success:", values);
    let res = await Api_Fren.post(
      `changeUserPassword_Fre`,
      {
        uid: userId,
        oldPassword: values.oldpassword,
        newPassword: values.password,
      },
      {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("ResUpdated", res.data.success);
    if (res.data.success === true) {
      toast.success("Password Successfully Changed!!!");
      // setotp_flag(false);
      values.oldpassword = "";
      values.password = "";
      values.password = "";
    } else {
      toast.error(res.data.data);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <DashShopNav />
      <div className="bg dashb pad-tp-30 pad-bt-150 text-center">
        <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
          Change Password
        </span>
        <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
          Dashboard &gt; Profile
        </span>
      </div>

      <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea">
        <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-20 pad-rt-20 bg-white bdr-rad-md">
          <div className="input-group">
            <Form
              name="basic"
              layout={"vertical"}
              initialValues={{
                remember: true,
              }}
              style={{ width: "100%" }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off">
              <Form.Item
                name="oldpassword"
                label="Old Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Old password!",
                  },
                ]}>
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="password"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your New password!",
                  },
                ]}
                hasFeedback>
                <Input.Password />
              </Form.Item>
              {/* <Input.Password placeholder=" Enter Old Password" /> */}
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}>
                <Input.Password />
              </Form.Item>
              {/* <Form.Item
                            style={{ display: otp_flag ? "" : "none" }}
                            name="otp"
                            label="OTP"
                            rules={[
                              {
                                message: "Please input your OTP!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item> */}

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopChangepassword;