import React, { useEffect, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { API } from "../../API/Api";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import TableButton from "../TableButton/TableButton";

export default function FundTransfer() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [pendingWithdrawApi, setPendingWithdrawApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [getFunType, setgetFunType] = useState("WALLET_FUND");
  const [getUserId, setgetUserId] = useState(0);
  const [UserIdNameU, setUserIdNameU] = useState("");
  const [Amount, setAmount] = useState(0);
  const [WalletAmount, setWalletAmount] = useState(0);
  const [AdminAmount, setAdminAmount] = useState(0);
  const user = useSelector((state) => state.Auth.userId);
  const dash = useSelector((state) => state.Auth.UserInfo);
  const [getUserIdU, setgetUserIdU] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [plandata, setplandata] = useState([]);
  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const Fundtransfer_API = async () => {
    try {
      let res = await API.post(
        `/fundTransferLog`,
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      res = res.data.data[0];
      setPendingWithdrawApi([]);
    //   console.log("fundtransfer", res);
      let arr = [];
      res.forEach((item, index) => {
        arr.push({
          userid: item.touserid,
          amount: item.amount,
          charge: parseFloat(item.charge).toFixed(2),
          wtype: item.wallettype,
          date: item.dd,
        });
      });

      setPendingWithdrawApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const CheckuserIdValidU = async (value) => {
    try {
      let res = await API.get(`CheckUser_id?uid=${value}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      setgetUserIdU(value);
      if (res.data.data[0][0].f_name === "") {
        setUserIdNameU("");
      } else {        
        setUserIdNameU("(" + res.data.data[0][0].f_name + ")");
      }
    } catch (e) {
      console.log("Error While calling Myteam API", e);
    }
  };

  const walletnetbalance = async () => {
    try {
      let res = await API.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      res = res.data.data[0][0];
      setAdminAmount(res.Net_AdminFund);
    } catch (e) {
      console.log("Error While calling Myteam API", e);
    }
  };

  useEffect(() => {
    Fundtransfer_API();
    walletnetbalance();
  }, []);

  // eslint-disable-next-line no-unused-vars
  var [pendingWithdraw, setpendingWithdraw] = new useState({
    cols: [
      { Header: "To UserId", accessor: "userid" },
      { Header: "Amount(₹)", accessor: "amount" },
      { Header: "Charge(₹)", accessor: "charge" },
      { Header: "Wallet Type", accessor: "wtype" },
      { Header: "Date", accessor: "date" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = pendingWithdrawApi.slice(
    indexOfFirstPage,
    indexOfLastPost
  );

  const Fundtransfer = async (event) => {
    try {
      event.preventDefault();
      let res = await API.post(
        `/fundTransfer`,
        {
          uid: userId,
          amount: Amount,
          touserid: getUserIdU,
          WalletType: "FUNDS",
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.data == "Fund Transfered Successfully") {
        toast.success(res.data.data);
        window.location.reload();
      } else {
        toast.error(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <DashBoardNav />
        <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
          <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
            Fund Transfer
          </span>
          <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
            Dashboard &gt; Fund
          </span>
        </div>
        <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
          <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
            <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
              <span className="float-left text-sm filterbtn color-black bdr-rad-xs">
                Click For Fund Transfer
              </span>
              <div className="card col-lg-12">
                <div className="card-header d-flex justify-content-between py_10 mb21">
                  <div
                    className="card-header-form col-lg-12 text-center"
                    style={{ backgroundColor: "rgb(194, 16, 16)" }}>
                    <Button onClick={handleOpen} className="Addbtn">
                      <i className="fas fa-plus" /> Fund Transfer
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description">
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2">
                          Fund Transfer
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          <div className="">
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <label className="my14">
                                    Transfer Wallet
                                  </label>
                                  <div className="">
                                    <FormControl>
                                      <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="FUNDS"
                                        name="radio-buttons-group"
                                        onChange={(e) =>
                                          setgetFunType(e.target.value)
                                        }>
                                        <FormControlLabel
                                          value="FUNDS"
                                          control={<Radio />}
                                          label={`Admin Fund (${
                                            AdminAmount || "$ 0.00"
                                          } $)`}
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <label className="my14">To UserId:</label>
                                  <input
                                    className="form-control"
                                    id="toUser"
                                    name="toUser"
                                    placeholder="To User Id"
                                    required="True"
                                    type="text"
                                    value={getUserIdU.toString() + UserIdNameU}
                                    // onChange={(e) =>
                                    //   setgetUserId(e.target.value)
                                    // }
                                    onChange={(e) =>
                                      CheckuserIdValidU(
                                        e.target.value
                                          .replace(/[^0-9]/g, "")
                                          .substring(0, 6)
                                      )
                                    }
                                  />
                                  <span
                                    id="uname"
                                    style={{ fontSize: "small" }}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <label className="my14">Amount:</label>
                                  <input
                                    className="form-control"
                                    data-val="true"
                                    data-val-number="The field transferAmount must be a number."
                                    data-val-required="The transferAmount field is required."
                                    id="transferAmount"
                                    name="transferAmount"
                                    placeholder="Enter Amount"
                                    required="True"
                                    type="text"
                                    defaultValue={0}
                                    onChange={(e) => setAmount(e.target.value)}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <label className="my14">Final Amount:</label>
                                  <input
                                    type="text"
                                    id="famount"
                                    name="famount"
                                    className="form-control"
                                    readOnly=""
                                    value={Amount}
                                  />
                                  <span
                                    id="ftxt"
                                    style={{ fontSize: "small" }}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <label className="text-danger font-14">
                                    <br />
                                    Note:
                                    <br />
                                    Transfer charge should be 0%
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="modal-footer">
                              <button
                                className="btn btn-danger light"
                                style={{
                                  textDecoration: "none",
                                  height: "auto",
                                }}
                                onClick={handleClose}>
                                Close
                              </button>
                              <button
                                className="btn btn-dark light"
                                style={{
                                  textDecoration: "none",
                                  height: "auto",
                                }}
                                onClick={Fundtransfer}>
                                Submit
                              </button>
                            </div>
                          </div>
                        </Typography>
                      </Box>
                    </Modal>
                  </div>
                </div>
              </div>

              {/* <div className="flex-md-50">
              <span className="disp-block color-grad1 text-big font-thik">
                2.50 $
              </span>
              <span className="disp-block color-gray">Staking Reward</span>
            </div>
            <div className="flex-md-50 text-right">
              <span className="disp-block color-grad1 text-big font-thik">
                50 $
              </span>
              <span className="disp-block color-gray">Staking Amount</span>
            </div> */}
            </div>
          </div>
          <div className="bg pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
            <div className="bg text-center pad-tp-20 pad-lt-5 pad-rt-10">
              <span className="text-md font-bold color-grad1 float-left">
                Fund Transfer Log
              </span>
            </div>
            <br />
            <br />
            {/* table */}
            <Table data={[...currentPost]} columns={pendingWithdraw.cols} />
            <div className="container">
              <TableButton
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={pendingWithdrawApi.length}
                listPerpage={listPerpage}
              />
            </div>

            {/* <div className="bg pos-rel border-bottom-gray pad-bt-20 marg-bt-20">
              <span className="disp-block text-lg marg-bt-5 color-grad1">
                50 $
              </span>
              <span className="disp-block marg-bt-5 text-st">
                <strong>Reward:</strong> 2.50 $
              </span>
              <span className="disp-block marg-bt-5 text-st">
                <strong>Return:</strong> 5%
              </span>
              <span className="disp-block marg-bt-5 text-st">
                <strong>Price:</strong> 22.62 $
              </span>
              <span className="disp-block marg-bt-5 text-st">
                <strong>REEO:</strong> 2
              </span>
              <span className="disp-block marg-bt-5 text-st">
                <strong>Buyer:</strong> WJ64A58E9E4693B
              </span>
              <span className="disp-block marg-bt-5 text-st">
                <strong>Customer ID:</strong> Self
              </span>
              <span className="disp-block marg-bt-5 text-st">
                <strong>Date:</strong> 20/01/24 22:21
              </span>
              <span className="disp-block marg-bt-5 text-st">
                <strong>Maturity:</strong> 28/02/28 23:59
              </span>
              <span className="bg-purple color-white text-sm bdr-rad-xs pad-tp-5 pad-bt-5 pad-lt-10 pad-rt-10 poptxt">
                Maturity Pending
              </span>
              <span
                className="bg-orange color-white text-sm bdr-rad-xs pad-tp-5 pad-bt-5 pad-lt-10 pad-rt-10 poptxt"
                style={{ top: 40 }}
              >
                Transfer REEO
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
