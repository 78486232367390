import React, { useEffect, useState } from "react";
import TableButton from "../TableButton/TableButton";
import Table from "../Table/Table";
import DashShopNav from "../DashShopNav/DashShopNav";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { API } from "../../API/Api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Api_Fren } from "../../API/Api_Fren";

export default function StockMothlyIncome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [levelIncomeApi, setLevelIncomeApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [totalIncome, setTotalIncome] = useState();
  const [selectedLevel, setSelectedLevel] = useState(0);

  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const LevelIncome_API = async (fromDate, toDate) => {
    try {
      let res = await Api_Fren.post(
        `MonthlystockIncomeReport_Fre`,
        {
          uid: userId,
          fdate: fromDate || "",
          tdate: toDate || ""          
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // if (res.data.data && res.data.data.totalIncome) {
      //   setTotalIncome(res.data.data.totalIncome);
      // }

      console.log("API Response", res);
      let arr = [];
      res.data.data[0].forEach((item, index) => {
        arr.push({
          amount: parseFloat(item.income).toFixed(2),
          RowNumber: item.RowNumber,
          fid: item.brid,
          remark: item.remark,
          cdate: item.dd,
          onamount: item.onamount,
        });
      });

      setLevelIncomeApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/Shop_Login");
      }
    }
  };

  const handleSearch = () => {
    const fromDate = document.getElementById("fdate").value;
    const toDate = document.getElementById("tdate").value;
    LevelIncome_API(fromDate, toDate);
  };

  useEffect(() => {
    LevelIncome_API();
  }, []);

  // eslint-disable-next-line no-unused-vars
  var [myTeam, setMyTeam] = new useState({
    cols: [
      { Header: "S.No.", accessor: "RowNumber" },
      { Header: "User Id", accessor: "fid" },
      { Header: "Income", accessor: "amount" },
      //{ Header: "On Amount", accessor: "onamount" },
      // { Header: "Level", accessor: "des" },
      { Header: "Remark", accessor: "remark" },
      { Header: "Created Date", accessor: "cdate" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = levelIncomeApi.slice(indexOfFirstPage, indexOfLastPost);

  return (
    <>
      <div>
        <DashShopNav />
        <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
          <span className="pad-tp-20 bg marg-tp-50 text-nowrap reText text-xl color-white">
            Stock Sale Monthly Income
          </span>
          <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
            Dashboard &gt; Report
          </span>
        </div>
        <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
          <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
            <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
              <span
                // onclick=""
                className="float-left text-sm filterbtn color-black bdr-rad-xs"
              >
                Advance Filter
              </span>
              <div className="card col-lg-12">
                <div className="card-body">
                  <form
                    action=""
                    method="post"
                    className="ng-pristine ng-valid"
                  >
                    <div className="box-body activation_pannel">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              <b>From Date : </b>{" "}
                              <input
                                className="form-control form-control-sm"
                                id="fdate"
                                name="fdate"
                                type="date"
                                defaultValue=""
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label style={{ "margin-left": "5px" }}>
                              <b>To Date : </b>{" "}
                              <input
                                className="form-control form-control-sm"
                                id="tdate"
                                name="tdate"
                                type="date"
                                defaultValue=""
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label style={{ "margin-left": "5px" }}>
                              <b>Level :</b>
                              <select
                                className="form-control form-control-sm"
                                id="level"
                                name="level"
                                value={selectedLevel}
                                onChange={(e) =>
                                  setSelectedLevel(e.target.value)
                                }
                              >
                                <option value={0}>All Level</option>
                                <option value={1}>Level-1</option>
                                <option value={2}>Level-2</option>
                                <option value={3}>Level-3</option>
                                <option value={4}>Level-4</option>
                                <option value={5}>Level-5</option>
                                <option value={6}>Level-6</option>
                                <option value={7}>Level-7</option>
                                <option value={8}>Level-8</option>
                                <option value={9}>Level-9</option>
                                <option value={10}>Level-10</option>
                                <option value={11}>Level-11</option>
                                <option value={12}>Level-12</option>
                                <option value={13}>Level-13</option>
                                <option value={14}>Level-14</option>
                                <option value={15}>Level-15</option>
                              </select>
                            </label>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <button
                            type="button"
                            className="btn btn-success mpy"
                            onClick={handleSearch}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="flex-md-50">
              <span className="disp-block color-grad1 text-big font-thik">
                17
              </span>
              <span className="disp-block color-gray">Team Network</span>
            </div>
            <div className="flex-md-50 text-right">
              <span className="disp-block color-grad1 text-big font-thik">
                0
              </span>
              <span className="disp-block color-gray">Team Activation</span>
            </div> */}
            </div>
          </div>

          {/* <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
          <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
            <div className="flex-md-50">
              <span className="disp-block color-grad1 text-big font-thik">
                17
              </span>
              <span className="disp-block color-gray">Team Network</span>
            </div>
            <div className="flex-md-50 text-right">
              <span className="disp-block color-grad1 text-big font-thik">
                0
              </span>
              <span className="disp-block color-gray">Team Activation</span>
            </div>
          </div>
        </div> */}
          <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
            <div className="bg text-center pad-tp-20 pad-lt-5 pad-rt-10">
              <span className="text-md font-bold color-grad1 float-left">
              Stock Sale Monthly Income
              </span>
            </div>
            <br />
            <br />
            <div className="card-body p-2">
              <div className="table-responsive">
                <div
                  // style={{ maxHeight: 400, overflow: "scroll" }}
                  id="example_wrapper"
                  className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  {/* <div className="dt-buttons btn-group">
                          <button type="button" className="btn">
                            CSV
                          </button>
                          <button type="button" className="btn">
                            Excel
                          </button>
                          <button type="button" className="btn">
                            PDF
                          </button>
                        </div> */}
                  {/* <div
                          id="example_filter"
                          className="dataTables_filter fldset"
                        >
                          <label>
                            <b>From Date : </b>{" "}
                            <input
                              className="form-control form-control-sm"
                              id="fdate"
                              name="fdate"
                              type="date"
                              defaultValue=""
                            />
                          </label>
                          <label>
                            <b>To Date : </b>{" "}
                            <input
                              className="form-control form-control-sm"
                              id="tdate"
                              name="tdate"
                              type="date"
                              defaultValue=""
                            />
                          </label>

                          <label>
                            <button
                              // type="submit"
                              className="btn btn-success btn-sm"
                              style={{ height: "auto" }}
                              onClick={handleSearch}
                            >
                              Search
                            </button>
                          </label>
                        </div> */}
                </div>
                {/* table */}
                <Table data={[...currentPost]} columns={myTeam.cols} />
                <div className="container">
                  <TableButton
                    indexOfFirstPage={indexOfFirstPage}
                    indexOfLastPost={indexOfLastPost}
                    setcurrentPage={setcurrentPage}
                    currentPage={currentPage}
                    totalData={levelIncomeApi.length}
                    listPerpage={listPerpage}
                  />
                </div>
              </div>
            </div>

            {/* <div className="flexbox tr bg-purple marg-bt-10">
            <div className="flex-md-40 color-white">User</div>
            <div className="flex-md-60 color-white">Business</div>
          </div>
          <div className="bg pad-tp-10 pad-bt-20 marg-bt-20">
            <span className="text-big disp-block marg-bt-5">
              No Record Saved
            </span>
            <span className="text-st color-gray disp-block">
              Invite your friends to join our network.
            </span>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
}