import React, { useEffect, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import { API } from "../../API/Api";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dfault from "./default.png";
import "./Referral.css";

const Network = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referrals, setReferrals] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(0);
  const [sponsorChatInfo, setSponsorChatInfo] = useState({});

  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const [data, setData] = useState({});
  const UserInforefferal_API = async () => {
    try {
      let res = await API.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      // console.log("API Response", res.data.data[0]);
      setData(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const MyReferralsAPI = async () => {
    try {
      let res = await API.post(
        `myReferrals`,
        {
          uid: userId,
          fdate: "",
          tdate: "",
          position: selectedPosition,
          status: 2,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("API Response", res.data.data[0]);

      // console.log("myReferrals", res.data.data);
      setReferrals(res.data.data[0] || []);
      setSponsorChatInfo(res.data.data.sponsorChatInfo || {});
    } catch (e) {
      console.error("Error fetching My Referrals:", e);
    }
  };

  useEffect(() => {
    UserInforefferal_API();
    MyReferralsAPI();
  }, []);

  return (
    <div>
      <DashBoardNav />
      <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
        <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
          My Referrals
        </span>
        <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
          Dashboard &gt; Referrals
        </span>
      </div>

      <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
        <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
          <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
            <div className="card col-lg-12 refferal_item">
              <div class="card-header d-flex justify-content-between py_10 mb21">
                <div class="header-title">
                  <h4 class="card-title">My Sponsor's Chat</h4>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-lg-4">
                <div className="card profile-widget col-lg-12">
                  <div className="profile-widget-header">
                    <center>
                      <img
                        src={Dfault}
                        alt="ref"
                        className="rounded-circle profile-widget-picture"
                      />
                    </center>
                    <div className="profile-widget-items">
                      <div className="profile-widget-item">
                        <div
                          className="profile-widget-item-value text-sm"
                          style={{ fontSize: 13, color: "orange" }}
                        >
                          {" "}
                          {data[0]?.f_name}
                        </div>
                        <div
                          className="profile-widget-item-value text-sm"
                          style={{ fontSize: 12 }}
                        >
                          UserId: {data[0]?.Username}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="flex-md-50">
              <span className="disp-block color-grad1 text-big font-thik">
                17
              </span>
              <span className="disp-block color-gray">Team Network</span>
            </div>
            <div className="flex-md-50 text-right">
              <span className="disp-block color-grad1 text-big font-thik">
                0
              </span>
              <span className="disp-block color-gray">Team Activation</span>
            </div> */}
          </div>
        </div>
        <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
          <div className="row" style={{ maxHeight: 500, overflow: "scroll" }}>
            <div className="card">
              <div class="card-header d-flex justify-content-between py_10 mb21">
                <div class="header-title">
                  <h4 class="card-title">My Referrals</h4>
                </div>
              </div>

              {referrals.map((referral) => (
                <div key={referral?.Username} className="col-md-4">
                  <div className="card profile-widget md_mb40">
                    <div className="profile-widget-header">
                      <center>
                        <img
                          src={Dfault}
                          className="rounded-circle profile-widget-picture"
                          alt="referral"
                        />
                      </center>
                      <div className="profile-widget-items text-center">
                        <div className="profile-widget-item text-center">
                          <div
                            className="profile-widget-item-value text-sm"
                            style={{ fontSize: 13 }}
                          >
                            UserId : {referral.Username}
                          </div>
                          <div
                            className="profile-widget-item-value text-sm"
                            style={{ fontSize: 13 }}
                          >
                            Name : {referral.f_name}
                          </div>
                        </div>
                      </div>
                      <div className="myReferralDetil text-center">
                        {/* <h3>{referral.f_name}</h3> */}
                        <ul>
                          <li>Position : {referral.pos}</li>
                          <li>
                            Total Product Value : {referral.packageamount}
                          </li>
                          <li>My Rank : </li>
                          <li>
                            <span>
                              Status :{" "}
                              <span
                                className={`badge badge-sm ${
                                  referral?.idType === "Active"
                                    ? "badge-success"
                                    : "badge-danger"
                                }`}
                              >
                                {referral.idType}
                              </span>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* <form>
            <div className="bg">
              <div className="bg">
                <label>Choose Rank</label>
                <select name="rank" className="form-elm">
                  <option value="All">All Ranks</option>
                  <option value="INC">Customer</option>
                  <option value="EXC">Executive</option>
                  <option value="MNG">Manager</option>
                  <option value="SRM">SR. Manager</option>
                  <option value="ARM">AR. Manager</option>
                  <option value="ZLM">ZL. Manager</option>
                  <option value="RGM">RGNL. Manager</option>
                  <option value="CRM">CTR. Manager</option>
                  <option value="PJH">Project Head</option>
                  <option value="DEV">DEV. Director</option>
                  <option value="CRT">Core Team</option>{" "}
                </select>
              </div>
              <div className="bg pad-tp-10">
                <label>Customer ID</label>
                <input
                  type="text"
                  name="search"
                  className="form-elm"
                  data-type="alpha"
                  defaultValue="WJ476289"
                  placeholder="Search Sponsor ID"
                  required=""
                />
              </div>
              <div className="break10" />
              <div className="bg">
                <button
                  type="submit"
                  className="bg-red w100 text-md color-white bdr-rad-xs pad-tp-10 pad-bt-10 pad-lt-20 pad-rt-20">
                  Search Now
                </button>
              </div>
            </div>
          </form>
          <div className="break20" />
          <div className="flexbox tr bg-purple marg-bt-10">
            <div className="flex-md-40 color-white">User</div>
            <div className="flex-md-60 color-white">Business</div>
          </div>
          <div>
            {userData.map((user, index) => (
              <UserProfile key={index} user={user} />
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Network;
