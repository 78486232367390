import React, { useEffect, useRef, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { API } from "../../API/Api";
import stakingcolor from "../../Assets/Images/stakingcolor.svg";
import team_lines from "../../Assets/Images/team-lines.svg";
import income_lines from "../../Assets/Images/income-lines.svg";
import transfer_lines from "../../Assets/Images/transfer-lines.svg";
import income from "../../Assets/Images/income.svg";
import income_receive from "../../Assets/Images/income-receive.svg";
import team_network from "../../Assets/Images/team-network.svg";
import team_active from "../../Assets/Images/team-active.svg";
import graph from "../../Assets/Images/graph.svg";
import wallet_money from "../../Assets/Images/wallet-money.svg";
import staking_reward from "../../Assets/Images/staking-reward.svg";
import { FaYoutube } from "react-icons/fa";
const DashBoard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [RewardData, setRewardData] = useState([]);
  const [SalaryData, setSalaryData] = useState([]);
  const [News, setNews] = useState([]);
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  let URL = window.location.origin;

  const [Days_here_booster, setDays_here_booster] = useState(0);
  const [Hours_here_booster, setHours_here_booster] = useState(0);
  const [Munits_here_booster, setMunits_here_booster] = useState(0);
  const [Seconds_booster, setSeconds_booster] = useState(0);

  const Dashboard_API = async () => {
    try {
      let res = await API.get(`/userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("API Response", res.data.data[0][0]);
      setData(res.data.data[0][0]);

      let responce = await API.get("GetNews", {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0];
      //console.log("GetNews-->", responce);
      setNews(responce);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const RewardStrip_API = async () => {
    try {
      let res = await API.get(`RewardStrips?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      //console.log("RewardResponse", res.data.data[0]);
      setRewardData(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const SalaryStrip_API = async () => {
    try {
      let res = await API.get(`SalaryStrips?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      //console.log("RewardResponse", res.data.data[0]);
      setSalaryData(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const inputRef = useRef(null);
  const inputRefRight = useRef(null);
  const [copied, setCopied] = useState(false);
  const [copiedRight, setCopiedRight] = useState(false);

  const copyToClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  };

  const copyToClipboardright = () => {
    if (inputRefRight.current) {
      inputRefRight.current.select();
      document.execCommand("copy");
      setCopiedRight(true);

      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  };

  const GetCurrentCycleTime = async () => {
    try {
      let responce = await API?.get(`getCurrentCycleTime?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data;
      //console.log("currentCycleTime", responce);
      localStorage.setItem("Timer2", responce);
    } catch (e) {
      console.log("Error While calling dashboard API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const Timer2 = localStorage.getItem("Timer2");
  console.log("first", Timer2);
  const timer2 = async () => {
    try {
      if (Timer2 === "Booster Qualify" || Timer2 === "Time is over") {
      } else {
        var currentDateTime = new Date();
        //console.log("currentDateTime", currentDateTime);
        let resultInSeconds = currentDateTime.getTime() / 1000;
        let topupInSeconds = new Date(Timer2).getTime() / 1000;
        let Time_here = topupInSeconds - resultInSeconds;
        let TimeFinal = parseInt(Time_here);
        if (TimeFinal <= 0 || Timer2 === "") {
          setDays_here_booster(0);
          setHours_here_booster(0);
          setMunits_here_booster(0);
          setSeconds_booster(0);
        } else {
          let days = parseInt(TimeFinal / 86400);
          setDays_here_booster(days);
          TimeFinal = TimeFinal % 86400;
          let hours = parseInt(TimeFinal / 3600);
          setHours_here_booster(hours);
          TimeFinal %= 3600;
          let munites = parseInt(TimeFinal / 60);
          setMunits_here_booster(munites);
          TimeFinal %= 60;
          let second_here = parseInt(TimeFinal);
          setSeconds_booster(second_here);
        }
      }
    } catch (e) {
      console.log("Error While Timer", e);
    }
  };

  useEffect(() => {
    setInterval(() => {
      timer2();
    }, 1000);
    Dashboard_API();
    RewardStrip_API();
    SalaryStrip_API();
    GetCurrentCycleTime();
  }, []);

  return (
    <div>
      <DashBoardNav />

      <div className="bg dashb pad-tp-100 pad-bt-250 text-center">
        <div className="container-fluid">
          {/* <div className="bg marg-bt-10 pad-lt-5 pad-rt-5 mt-20">
          <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
            <div className="flex-md-50">
              <span className="disp-block color-grad1 text-big font-thik">
                0{" "}
                <small style={{ fontSize: "0.6em", color: "inherit" }}>
                  PV
                </small>
              </span>
              <span className="disp-block color-gray">Power Team</span>
            </div>
            <div className="flex-md-50 text-right">
              <span className="disp-block color-grad1 text-big font-thik">
                0{" "}
                <small style={{ fontSize: "0.6em", color: "inherit" }}>
                  PV
                </small>
              </span>
              <span className="disp-block color-gray">Support Team</span>
            </div>
          </div>
        </div> */}

          <div className="row pad-rt-10 pad-lt-10 marg-bt-5 pad-tp-15 pad-bt-5 g-3">
            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div className=" one bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100">
                  <div className="borderss pb-2">
                    <img src={stakingcolor} className="icon-xs" />
                  </div>
                  <div className="flexbox">
                    <div className="flex-md-50">
                      <span className="disp-block textss text-big font-thik">
                        User Id
                      </span>
                      <span className="disp-block textss text-big font-thik">
                        {data?.Username}
                      </span>
                    </div>
                    <div className="flex-md-50">
                      <span className="disp-block textss text-big font-thik">
                        Name
                      </span>
                      <span className="disp-block textss text-big font-thik">
                        {data?.f_name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div className="one1 bg pad-tp-15 pad-bt-10 bdr-rad-sm h-100">
                  <div className="borderss pb-2">
                    <img src={stakingcolor} className="icon-xs" />
                  </div>
                  <div className="flexbox">
                    <div className="flex-md-50">
                      <span className="disp-block textss text-big font-thik">
                        Package
                      </span>
                      <span className="disp-block textss text-big font-thik">
                        {data?.TotalInvest}
                      </span>
                    </div>
                    <div className="flex-md-50">
                      <span className="disp-block textss text-big font-thik">
                        BV
                      </span>
                      <span className="disp-block textss text-big font-thik">
                        {data?.selfpv}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div className="one2 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100">
                  <div className="borderss pb-2">
                    <img src={stakingcolor} className="icon-xs" />
                  </div>
                  <div className="flexbox">
                    <div className="flex-md-50">
                      <span className="disp-block textss text-big font-thik">
                        Roi %
                      </span>
                      <span className="disp-block textss text-big font-thik">
                        {data?.roiper} %
                      </span>
                    </div>
                    <div className="flex-md-50">
                      <span className="disp-block textss text-big font-thik">
                        Roi Income
                      </span>
                      <span className="disp-block textss text-big font-thik">
                        {data?.RoiInome}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div className="one3 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100">
                  <div className="borderss pb-2">
                    <img src={stakingcolor} className="icon-xs" />
                  </div>
                  <div className="">
                    <div className="flex-md-55">
                      <span className="disp-block textss text-big font-thik">
                        Activation Date
                      </span>
                      <span className="disp-block textss text-big font-thik">
                        {data?.topupdate}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="mt-20" style={{ marginTop: "20px", color: "white" }}>
          <h6 className="text-white">User Id :</h6>
          <h6 className="text-white">Name : </h6>
        </div> */}
        {/* <span className="pad-tp-0 fts  mt-1 bg text-sm color-yellow">
          Team Turnover
        </span>
        <span className="pad-bt-25 bg text-xl color-white">
          ₹ {data?.totalTeamBusiness}
        </span> */}
        <div className="container-fluid">
          <div className="row g-3">
            <div className="col-md-6">
              <div
                style={{
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <input
                  className="mb-3"
                  ref={inputRef}
                  type="text"
                  value={`${URL}/SignUp?userid=${data?.Username}&position=1`}
                  readOnly
                  onClick={copyToClipboard}
                  style={{
                    cursor: "pointer",
                    maxWidth: "31vw", // Limit the width to 80% of the viewport width
                    padding: "8px",
                    borderRadius: "15px",
                    border: "1px solid #ccc",
                    marginRight: "8px", // Add some spacing to the right
                  }}
                />
                <button
                  onClick={copyToClipboard}
                  style={{
                    padding: "8px 16px",
                    borderRadius: "15px",
                    cursor: "pointer",
                    backgroundColor: "#ffb200",
                  }}
                  disabled={copied}
                >
                  {copied ? "Copied Left Referral" : "Copy Left Referral"}
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div
                style={{
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <input
                  className="mb-3"
                  ref={inputRefRight}
                  type="text"
                  value={`${URL}/SignUp?userid=${data?.Username}&position=2`}
                  readOnly
                  onClick={copyToClipboardright}
                  style={{
                    cursor: "pointer",
                    maxWidth: "31vw", // Limit the width to 80% of the viewport width
                    padding: "8px",
                    borderRadius: "15px",
                    border: "1px solid #ccc",
                    marginRight: "8px", // Add some spacing to the right
                  }}
                />
                <button
                  onClick={copyToClipboardright}
                  style={{
                    padding: "8px 16px",
                    borderRadius: "15px",
                    cursor: "pointer",
                    backgroundColor: "#ffb200",
                  }}
                  disabled={copiedRight}
                >
                  {copiedRight
                    ? "Copied Right Referral"
                    : "Copy Right Referral"}
                </button>
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="bg pad-bt-25"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                <ul className="list_none social_icon d-md-flex mt-3">
                  <li
                    className="animation animated fadeInUp"
                    data-animation="fadeInUp"
                    data-animation-delay="0.4s"
                    style={{ animationDelay: "0.4s", opacity: 1 }}
                  >
                    <a href="#">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth={0}
                        viewBox="0 0 320 512"
                        className=" fa fa-facebook"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                      </svg>
                    </a>
                  </li>
                  <li
                    className="animation animated fadeInUp"
                    data-animation="fadeInUp"
                    data-animation-delay="0.5s"
                    style={{ animationDelay: "0.5s", opacity: 1 }}
                  >
                    <a href="/">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth={0}
                        viewBox="0 0 512 512"
                        className=" fa fa-twitter"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M496 109.5a201.8 201.8 0 0 1-56.55 15.3 97.51 97.51 0 0 0 43.33-53.6 197.74 197.74 0 0 1-62.56 23.5A99.14 99.14 0 0 0 348.31 64c-54.42 0-98.46 43.4-98.46 96.9a93.21 93.21 0 0 0 2.54 22.1 280.7 280.7 0 0 1-203-101.3A95.69 95.69 0 0 0 36 130.4c0 33.6 17.53 63.3 44 80.7A97.5 97.5 0 0 1 35.22 199v1.2c0 47 34 86.1 79 95a100.76 100.76 0 0 1-25.94 3.4 94.38 94.38 0 0 1-18.51-1.8c12.51 38.5 48.92 66.5 92.05 67.3A199.59 199.59 0 0 1 39.5 405.6a203 203 0 0 1-23.5-1.4A278.68 278.68 0 0 0 166.74 448c181.36 0 280.44-147.7 280.44-275.8 0-4.2-.11-8.4-.31-12.5A198.48 198.48 0 0 0 496 109.5z" />
                      </svg>
                    </a>
                  </li>
                  <li
                    className="animation animated fadeInUp"
                    data-animation="fadeInUp"
                    data-animation-delay="0.6s"
                    style={{ animationDelay: "0.6s", opacity: 1 }}
                  >
                    <a href="#">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth={0}
                        viewBox="0 0 448 512"
                        className=" fa fa-instagram"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                      </svg>
                    </a>
                  </li>
                  <li
                    className="animation animated fadeInUp"
                    data-animation="fadeInUp"
                    data-animation-delay="0.7s"
                    style={{ animationDelay: "0.7s", opacity: 1 }}
                  >
                    <a href="/">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth={0}
                        viewBox="0 0 448 512"
                        className=" fa fa-telegram"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z" />
                      </svg>
                    </a>
                  </li>
                  <li
                    className="animation animated fadeInUp"
                    data-animation="fadeInUp"
                    data-animation-delay="0.7s"
                    style={{ animationDelay: "0.7s", opacity: 1 }}
                  >
                    <a href="/">
                      <FaYoutube />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row g-3" style={{ display: "none" }}>
            <div className="col-md-6 col-lg-3 col-sm-6 col-12col-md-3">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div className="one4 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100">
                  <img src={stakingcolor} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap textss">
                    Self Purchase
                    <br />₹ {data?.grossINvestment}
                    <br />
                    BV {data?.selfpv}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-sm-6 col-12col-md-3">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div className="bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100">
                  <img src={team_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap color-grad1">
                    Total Team ({data?.TotalTeam})
                    <br />
                    <div className="bank_detail1">
                      <div className="">
                        {" "}
                        Total Left : {data?.TotalLeftTeam}{" "}
                      </div>
                      <div className="">
                        {" "}
                        Left Active : {data?.TotalLeftActiveTeam}{" "}
                      </div>
                      {/* <div className="">{" "} Left InActive: {data?.TotalLeftInactiveTeam}{" "}</div> */}

                      <div className="">
                        {" "}
                        Total Right : {data?.TotalRightTeam}{" "}
                      </div>
                      <div className="">
                        {" "}
                        Right active : {data?.TotalRightActiveTeam}{" "}
                      </div>
                      {/* <div className="">{" "} Right InActive : {data?.TotalRightInactiveTeam}{" "}</div> */}
                    </div>
                    {/* {data?.TotalTeam} */}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-sm-6 col-12col-md-3">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div
                  className="bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100"
                  onclick="return goPage('transfer');"
                >
                  <img src={transfer_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap color-grad1">
                    Direct Team ({data?.TotalReferral})
                    <br />
                    <div className="bank_detail1">
                      <div className="">
                        {" "}
                        Total Left : {data?.TotalLeftReferral}{" "}
                      </div>
                      <br />
                      <div className="">
                        {" "}
                        Left Active : {data?.TotalLeftActiveReferral}{" "}
                      </div>
                      {/* <div className="">{" "} Left InActive: {data?.TotalLeftInactiveReferral}{" "}</div> */}

                      <div className="">
                        {" "}
                        Total Right : {data?.TotalRightReferral}{" "}
                      </div>
                      <br />
                      <div className="">
                        {" "}
                        Right active : {data?.TotalRightActiveReferral}{" "}
                      </div>
                      {/* <div className="">{" "} Right InActive : {data?.TotalRightInactiveReferral}{" "}</div> */}
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-sm-6 col-12col-md-3">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div
                  className="bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100"
                  onclick="return goPage('incomesummary');"
                >
                  <img src={income_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap color-grad1">
                    Total Income
                    <br />₹ {parseFloat(data?.WorkingTotalIncome).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="row g-3">
            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div
                  className="one4 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100"
                  onclick="return goPage('incomesummary');"
                >
                  <img src={income_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap textss">
                    Fund Wallet
                    <br />
                    {data?.Net_AdminFund}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div
                  className="one5 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100"
                  onclick="return goPage('incomesummary');"
                >
                  <img src={income_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap textss">
                    Current Reward
                    <br />
                    {data?.user_award}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div
                  className="one6 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100"
                  onclick="return goPage('incomesummary');"
                >
                  <img src={income_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap textss">
                    Next Reward
                    <br />
                    {data?.nextReward}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div
                  className="one7 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100"
                  onclick="return goPage('incomesummary');"
                >
                  <img src={income_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap textss">
                    Royality Rank
                    <br />
                    {data?.Royality_Rank}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="row g-3">
            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div className="one8 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100">
                  <img src={team_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap textss">
                    Differential Rank
                    <br />
                    <div className="flexbox">
                      <div className="flex-md-50">
                        <span className="disp-block textss text-big font-thik">
                          Rank Name
                        </span>
                        <span className="disp-block textss text-big font-thik">
                          {data?.Rank_name}
                        </span>
                      </div>
                      <div className="flex-md-50">
                        <span className="disp-block textss text-big font-thik">
                          Rank %
                        </span>
                        <span className="disp-block textss text-big font-thik">
                          {data?.RankRate}
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div className="one9 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100">
                  <img src={team_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap textss">
                    Direct Referral ({data?.TotalReferral})
                    <br />
                    <div className="flexbox">
                      <div className="flex-md-50">
                        <span className="disp-block textss text-big font-thik">
                          Total Active
                        </span>
                        <span className="disp-block textss text-big font-thik">
                          {data?.TotalActiveReferral}
                        </span>
                      </div>
                      <div className="flex-md-50">
                        <span className="disp-block textss text-big font-thik">
                          Total Inactive
                        </span>
                        <span className="disp-block textss text-big font-thik">
                          {data?.TotalInactiveReferral}
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div className="one10 bg bg-white  pad-tp-15 pad-bt-10 bdr-rad-sm h-100">
                  <img src={team_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap textss">
                    Team ({data?.TotalTeam})
                    <br />
                    <div className="flexbox">
                      <div className="flex-md-50">
                        <span className="disp-block textss text-big font-thik">
                          Total Active
                        </span>
                        <span className="disp-block textss text-big font-thik">
                          {data?.TotalActiveTeam}
                        </span>
                      </div>
                      <div className="flex-md-50">
                        <span className="disp-block textss text-big font-thik">
                          Total Inactive
                        </span>
                        <span className="disp-block textss text-big font-thik">
                          {data?.TotalInactiveTeam}
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div className="one11 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100">
                  {/* <img src={team_lines} className="icon-xs" /> */}
                  <span className="disp-block color-white text-nowrap textss">
                    Business
                    <br />
                    <div className="flexbox">
                      <div className="flex-md-50">
                      <span className="disp-block textss text-big font-thik">
                          Total 
                        </span>
                        <span className="disp-block textss text-big font-thik">
                           Left : {data?.totalTeamBusiness_left}
                        </span>
                        <span className="disp-block textss text-big font-thik">
                         Right :  {data?.totalTeamBusiness_Right}
                        </span>
                      </div>
                      <div className="flex-md-50">
                      <span className="disp-block textss text-big font-thik">
                          Today
                        </span>
                       
                        <span className="disp-block textss text-big font-thik">
                         Left : {data?.todayTeamBusiness_left}
                        </span>
                        <span className="disp-block textss text-big font-thik">
                           Right {data?.todayTeamBusiness_Right}
                          
                        </span>
                       
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <br></br>
          <div className="row g-3">
            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div className="one12 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100">
                  <img src={team_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap textss">
                    Working Income
                    <br />
                    <div className="flexbox">
                      <div className="flex-md-50">
                        <span className="disp-block textss text-big font-thik">
                          Total
                        </span>
                        <span className="disp-block textss text-big font-thik">
                          {data?.WorkingTotalIncome}
                        </span>
                      </div>
                      <div className="flex-md-50">
                        <span className="disp-block textss text-big font-thik">
                          Today
                        </span>
                        <span className="disp-block textss text-big font-thik">
                          {data?.WorkingTodayIncome}
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div className="one13 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100">
                  <img src={team_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap textss">
                    Team BV
                    <br />
                    <div className="flexbox">
                      <div className="flex-md-50">
                        <span className="disp-block textss text-big font-thik">
                          Total
                        </span>
                        <span className="disp-block textss text-big font-thik">
                          {data?.totalpv}
                        </span>
                      </div>
                      <div className="flex-md-50">
                        <span className="disp-block textss text-big font-thik">
                          Today
                        </span>
                        <span className="disp-block textss text-big font-thik">
                          {data?.todaypv}
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div className="one14 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100">
                  <img src={team_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap textss">
                    Working Payout
                    <br />
                    <div className="flexbox">
                      <div className="flex-md-50">
                        <span className="disp-block textss text-big font-thik">
                          Pending
                        </span>
                        <span className="disp-block textss text-big font-thik">
                          {data?.workingPayoutpending}
                        </span>
                      </div>
                      <div className="flex-md-50">
                        <span className="disp-block textss text-big font-thik">
                          Paid
                        </span>
                        <span className="disp-block textss text-big font-thik">
                          {data?.workingPayoutPaid}
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 col-sm-6 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5">
                <div className="one15 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100">
                  <img src={team_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap textss">
                    Roi Payout
                    <br />
                    <div className="flexbox">
                      <div className="flex-md-50">
                        <span className="disp-block textss text-big font-thik">
                          Pending
                        </span>
                        <span className="disp-block textss text-big font-thik">
                          {data?.RoiPayoutPending}
                        </span>
                      </div>
                      <div className="flex-md-50">
                        <span className="disp-block textss text-big font-thik">
                          Paid
                        </span>
                        <span className="disp-block textss text-big font-thik">
                          {data?.RoiPayoutPaid}
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="row g-3">
            <div className="col-lg-3 col-md-6 col-sm-4 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5 h-100">
                <div
                  className="one16 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100"
                  onclick="return goPage('incomesummary');"
                >
                  <img src={income_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap textss">
                    <h5 className="textss">Cycle Timer</h5>
                    <p></p>
                    <h5 className="textss">
                      {Timer2 === "Booster Qualify"
                        ? "Booster Qualify"
                        : Timer2 === "Time is over"
                        ? "Time is over"
                        : Days_here_booster +
                          "d " +
                          Hours_here_booster +
                          "h " +
                          Munits_here_booster +
                          "m " +
                          Seconds_booster +
                          "s"}
                    </h5>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-4 col-12">
              <div className="flex-md-25 pad-lt-5 pad-rt-5 h-100">
                <div
                  className="one17 bg bg-white pad-tp-15 pad-bt-10 bdr-rad-sm h-100"
                  onclick="return goPage('incomesummary');"
                >
                  <img src={income_lines} className="icon-xs" />
                  <span className="disp-block color-white text-nowrap textss">
                    <h5 className="textss">Bonanza Gift</h5>
                    <p></p>
                    <h5 className="textss">{data?.bonanzaGift}</h5>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6  col-md-6 col-sm-8 col-12">
              <div className="h-100">
                <marquee
                  className="newsmarpuee one18"
                  //width="40%"
                  direction="up"
                  height="200px"
                  scrollamount="3"
                >
                  {News.map((item, index) => (
                    <h6 className="text-black">{item.content}</h6>
                  ))}
                </marquee>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg dashb pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 statarea"
        style={{ display: "none" }}
      >
        <div className="flexbox text-center marg-bt-5 pad-tp-15 pad-bt-5"></div>
        {/* <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
          <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
            <div className="flex-md-50">
              <span className="disp-block color-grad1 text-big font-thik">
                0{" "}
                <small style={{ fontSize: "0.6em", color: "inherit" }}>
                  PV
                </small>
              </span>
              <span className="disp-block color-gray">Power Team</span>
            </div>
            <div className="flex-md-50 text-right">
              <span className="disp-block color-grad1 text-big font-thik">
                0{" "}
                <small style={{ fontSize: "0.6em", color: "inherit" }}>
                  PV
                </small>
              </span>
              <span className="disp-block color-gray">Support Team</span>
            </div>
          </div>
        </div> */}
        <div className="bg pad-tp-20 pad-bt-100 pad-lt-10 pad-rt-10  bdr-rad-md">
          <div className="flexbox pad-bt-15 marg-bt-15 text-center bg-white rounded-3 py-4 px-2">
            <div className="flex-md-100">
              <span className="text-md disp-block color-grad1">
                My Business Portfolio
              </span>
              <span className="text-sm disp-inline-block bg-grad1 color-white bdr-rad-full marg-tp-5 pad-tp-5 pad-bt-5 pad-lt-10 pad-rt-10">
                Quick Overvew
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={income_receive} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <span className="disp-block text-loose-xs text-md">
                Activation Date
              </span>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">{data?.topupdate}</span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={income_receive} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/RoiIncome">
                <span className="disp-block text-loose-xs text-md">Fund</span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  {/* Busines Income */}
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {parseFloat(data?.Net_AdminFund).toFixed(2)}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Total Amount
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={income_receive} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/RoiIncome">
                <span className="disp-block text-loose-xs text-md">
                  Roi Income
                </span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  Busines Income
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {parseFloat(data?.RoiInome).toFixed(2)}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Total Amount
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={income_receive} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/BinaryIncome">
                <span className="disp-block text-loose-xs text-md">
                  Binary Income
                </span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  Business Income
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {parseFloat(data?.BinaryIncome).toFixed(2)}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Total Amount
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={income_receive} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/Deffirencialincome">
                <span className="disp-block text-loose-xs text-md">
                  Deffirencial Income
                </span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  Business Income
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {parseFloat(data?.Deffirencialincome).toFixed(2)}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Total Amount
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={income_receive} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/RoyalityIncome">
                <span className="disp-block text-loose-xs text-md">
                  Royality Income
                </span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  Busines Income
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {parseFloat(data?.RoyalityIncome).toFixed(2)}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Total Amount
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={income_receive} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/DirectIncome">
                <span className="disp-block text-loose-xs text-md">
                  Direct Income
                </span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  Busines Income
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {parseFloat(data?.DirectIncome).toFixed(2)}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Total Amount
              </span>
            </div>
          </div>
          {/* <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15">
            <div className="flex-md-10">
              <img src={income_receive} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/levelIncome">
                <span className="disp-block text-loose-xs text-md">
                  Level Income
                </span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  Busines Income
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {parseFloat(data?.LevelIncome).toFixed(2)}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Total Amount
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={income_receive} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/SalaryIncome">
                <span className="disp-block text-loose-xs text-md">
                  Salary Income
                </span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  Business Income
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {parseFloat(data?.SalaryIncome).toFixed(2)}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Total Amount
              </span>
            </div>
          </div> */}
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={income_receive} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/FranchiseCommission">
                <span className="disp-block text-loose-xs text-md">
                  Franchisee Activation Income
                </span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  Business Income
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {parseFloat(data?.FranchiseeLevelIncome).toFixed(2)}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Total Amount
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={income_receive} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/SalesCommission">
                <span className="disp-block text-loose-xs text-md">
                  Product Sales Comission
                </span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  Business Income
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {parseFloat(data?.SalesComission).toFixed(2)}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Total Amount
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={income} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <span
                onclick="return goPage('./incomesummary');"
                className="disp-block text-md"
              >
                Total Income
              </span>
              <span className="text-st marg-tp-5 disp-block color-gray">
                All Time Income
              </span>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {data?.WorkingTotalIncome}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Till Date
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={income} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/Withdraw">
                <span className="disp-block text-loose-xs text-md">
                  Withdrawal Amount
                </span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  Business Withdrawal
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {parseFloat(data?.TotalWithdrawalWorking).toFixed(2)}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Net Withdrawal
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={income} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/Withdraw">
                <span className="disp-block text-loose-xs text-md">
                  Net Balance
                </span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  Net Income Balance
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {parseFloat(data?.WorkingNetBalance).toFixed(2)}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Net Amount
              </span>
            </div>
          </div>

          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={team_network} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/TeamBusiness">
                <span
                  onclick="return goPage('./directteam');"
                  className="disp-block text-md"
                >
                  Team Network
                </span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  Team Members
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                <span className="color-green">
                  {" "}
                  Active: {data?.TotalActiveTeam}{" "}
                </span>
                /{" "}
                <span className="color-red">
                  {" "}
                  Inactive: {data?.TotalInactiveTeam}{" "}
                </span>{" "}
              </span>

              <span className="disp-block color-green text-st marg-tp-5">
                All Team({data?.TotalTeam})
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={team_active} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/Network">
                <span
                  onclick="return goPage('./directteam');"
                  className="disp-block text-md"
                >
                  Direct Team
                </span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  Premium Members
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                <span className="color-green">
                  {" "}
                  Active: {data?.TotalActiveReferral}{" "}
                </span>
                /{" "}
                <span className="color-red">
                  {" "}
                  Inactive: {data?.TotalInactiveReferral}{" "}
                </span>{" "}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Total Direct ({data?.TotalReferral})
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={graph} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <a href="/Hipayteam">
                <span className="disp-block text-md">Team Business</span>
                <span className="text-st marg-tp-5 disp-block color-gray">
                  All Time Business
                </span>
              </a>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {data?.totalTeamBusiness}{" "}
              </span>
              <span className="disp-block color-red text-st marg-tp-5">
                Total Business
              </span>{" "}
            </div>
          </div>

          {/* <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={wallet_money} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <span
                onclick="return goPage('./deposit');"
                className="disp-block text-md"
              >
                Admin Wallet
              </span>
              <span className="text-st marg-tp-5 disp-block color-gray">
                Primary Balance
              </span>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block text-md">
                ₹ {data?.Net_AdminFund}
              </span>
              <span className="disp-block color-green text-st marg-tp-5">
                Available Bal
              </span>
            </div>
          </div> */}
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={staking_reward} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <span
                onclick="return goPage('./stakinghistory');"
                className="disp-block text-md"
              >
                Current Award
              </span>
              <span className="text-st marg-tp-5 disp-block color-gray">
                Award
              </span>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block color-green text-md">
                {data?.user_award}
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={staking_reward} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <span
                onclick="return goPage('./stakinghistory');"
                className="disp-block text-md"
              >
                Rank
              </span>
              <span className="text-st marg-tp-5 disp-block color-gray">
                Rank
              </span>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block color-green text-md">
                {data?.Rank_name}
              </span>
            </div>
          </div>
          <div className="flexbox pad-bt-15 border-bottom-gray marg-bt-15 bg-white rounded-3 py-4 px-2">
            <div className="flex-md-10">
              <img src={staking_reward} className="icon-mx-st" />
            </div>
            <div className="flex-md-50 pad-lt-5">
              <span
                onclick="return goPage('./stakinghistory');"
                className="disp-block text-md"
              >
                Royality Rank
              </span>
              <span className="text-st marg-tp-5 disp-block color-gray">
                Rank
              </span>
            </div>
            <div className="flex-md-40 text-right">
              <span className="disp-block color-green text-md">
                {data?.Royality_Rank}
              </span>
            </div>
          </div>
          <div className="col-lg-6">
            <span className="disp-block text-md color-grad1 text-center marg-bt-20">
              My Rewards
            </span>
            {RewardData?.map((ele, index) => (
              <div className="flexbox border-bottom-gray pad-lt-10 pad-rt-10 pad-tp-20 pad-bt-15 bdr-rad-md marg-bt-20 shad-box-xxs bg-white">
                <div className="flex-md-70">
                  <span className="disp-block text-md color-grad1">
                    {ele.Reward} (Leg 1: ₹ {ele.Leg1} / Other Leg: ₹ {ele.Leg2})
                  </span>
                </div>
                <div className="flex-md-30 text-right">
                  <span className="bg-purple-light color-white bdr-rad-md pad-tp-5 pad-bt-5 pad-lt-10 pad-rt-10">
                    {ele.Id}
                  </span>
                </div>
                <div className="flex-md-100 pad-tp-10">
                  <div className="bg progblock bg-gray-light bdr-rad-md overflow-hidden">
                    <div
                      data-progress-value="r1"
                      className="bar text-white"
                      style={{ width: `${ele.Percentage}` + `%` }}
                    />
                  </div>
                  <div className="bg pad-tp-5">
                    <div className="flexbox">
                      <div className="flex-md-30">
                        <span
                          className="color-gray disp-inline-block marg-tp-5"
                          data-progress-label="r1"
                        >
                          {ele.Percentage} %
                        </span>
                      </div>
                      <div className="flex-md-70 text-right">
                        <span
                          className="color-grad1 disp-block marg-tp-5"
                          data-progress-label="r1"
                        >
                          Target:₹ {ele.TargetBV}/ Net:₹ {ele.NetBV}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-md-100 pad-tp-10">
                  <Link to="/rewardIncome">
                    <span className="disp-block bdr-rad-xs pad-tp-10 pad-bt-10 text-center bg-gray-light">
                      {parseInt(ele.Percentage) === 100 ? (
                        <span>Eligible</span>
                      ) : (
                        <span>Not Eligible</span>
                      )}
                    </span>
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-6">
            <span className="disp-block text-md color-grad1 text-center marg-bt-20">
              Salary Details
            </span>
            {SalaryData?.map((ele, index) => (
              <div className="flexbox border-bottom-gray pad-lt-10 pad-rt-10 pad-tp-20 pad-bt-15 bdr-rad-md marg-bt-20 shad-box-xxs">
                <div className="flex-md-70">
                  <span className="disp-block text-md color-grad1">
                    ₹ {ele.Reward} (Leg 1: ₹ {ele.Leg1} / Other Leg: ₹{" "}
                    {ele.Leg2})
                  </span>
                </div>
                <div className="flex-md-30 text-right">
                  <span className="bg-purple-light color-white bdr-rad-md pad-tp-5 pad-bt-5 pad-lt-10 pad-rt-10">
                    {ele.Id}
                  </span>
                </div>
                <div className="flex-md-100 pad-tp-10">
                  <div className="bg progblock bg-gray-light bdr-rad-md overflow-hidden">
                    <div
                      data-progress-value="r1"
                      className="bar text-white"
                      style={{ width: `${ele.Percentage}` + `%` }}
                    />
                  </div>
                  <div className="bg pad-tp-5">
                    <div className="flexbox">
                      <div className="flex-md-30">
                        <span
                          className="color-gray disp-inline-block marg-tp-5"
                          data-progress-label="r1"
                        >
                          {ele.Percentage} %
                        </span>
                      </div>
                      <div className="flex-md-70 text-right">
                        <span
                          className="color-grad1 disp-block marg-tp-5"
                          data-progress-label="r1"
                        >
                          Target:₹ {ele.TargetBV}/ Net:₹ {ele.NetBV}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-md-100 pad-tp-10">
                  <Link to="/SalaryIncome">
                    <span className="disp-block bdr-rad-xs pad-tp-10 pad-bt-10 text-center bg-gray-light">
                      {parseInt(ele.Percentage) === 100 ? (
                        <span>Eligible</span>
                      ) : (
                        <span>Not Eligible</span>
                      )}
                    </span>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
