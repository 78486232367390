import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider } from "@mui/material";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../API/Api";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAuth } from "../../Redux/AuthSlice";
import Table from "../Table/Table";
import TableButton from "../TableButton/TableButton";

const Withdraw = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [pendingWithdrawApi, setPendingWithdrawApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const [otp, setOTP] = useState("");
  const [otpvalid, setOTPValid] = useState("0");

  const SendOtp_API = async () => {
    try {
      let res = await API.post(
        "withdrawalOtp",
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("SendOtp_API", res);
      res = res.data.data;
      if (res === "Successfull") {
        setOTPValid("1");
        toast.success("OTP Send Successfully !!");
      } else {
        setOTPValid("0");
        toast.error(res);
      }
    } catch (e) {
      console.log("Somthing Error in SendOtp_API", e);
    }
  };

  const hendleOTP = (event) => {
    const newValue = event.replace(/[^0-9]/gi, "");
    setOTP(newValue);
  };

  const Dashboard_API = async () => {
    try {
      let res = await API.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("API Response", res.data.data[0]);
      setData(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const Pwithdraw_API = async () => {
    try {
      let res = await API.post(
        `/withdrawalHistory`,
        {
          uid: userId,
          type: 1,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      res = res.data.data[0];
      setPendingWithdrawApi([]);
      console.log("penddingwithdrwa", res);
      let arr = [];
      res.forEach((item, index) => {
        arr.push({
          sno: item.row,
          userid: item.Request_amount,
          pname: item.admincharge,
          pamount: item.amount,
          drecived: item.Accountaddress,
          status: item.statuss,
          date: item.rdate,
        });
      });

      setPendingWithdrawApi(arr);
    } catch (e) {
      console.log("pending Withdraw API not working", e);
    }
  };

  useEffect(() => {
    Dashboard_API();
    Pwithdraw_API();
  }, []);

  // eslint-disable-next-line no-unused-vars
  var [pendingWithdraw, setpendingWithdraw] = new useState({
    cols: [
      { Header: "Payout ID", accessor: "sno" },
      { Header: "Amount", accessor: "userid" },
      { Header: "Charge", accessor: "pname" },
      { Header: "Payout Amount", accessor: "pamount" },
      { Header: "Status", accessor: "status" },
      { Header: "Date", accessor: "date" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = pendingWithdrawApi.slice(
    indexOfFirstPage,
    indexOfLastPost
  );

  const [Amount, setAmount] = useState("");
  const [Charge, setCharge] = useState(0);
  const [FinalAmount, setFinalAmount] = useState(0);

  const CalculateAmount = async (value) => {
    try {
      setAmount(value);
      // setCharge(
      //   (
      //     (parseFloat(value) * parseFloat(data[0]?.withdrawal_charge)) /
      //     parseFloat(100)
      //   ).toFixed(2)
      // );
      // setFinalAmount(
      //   (
      //     parseFloat(value) -
      //     (parseFloat(value) * parseFloat(data[0]?.withdrawal_charge)) /
      //       parseFloat(100)
      //   ).toFixed(2)
      // );
    } catch (e) {
      console.log("Error While calling CalculateAmount", e);
    }
  };

  const WithdrawalByUser = async (event) => {
    try {
      // event.preventDefault();

      if (Amount === undefined || Amount === null) {
        toast.error("Enter Amount");
        return;
      }
      if (Amount === "") {
        toast.error("Enter Amount");
        return;
      }
      if (parseFloat(Amount) === 0) {
        toast.error("Enter Amount");
        return;
      }
      // if (parseFloat(Amount) < parseFloat(100)) {
      //   toast.error("You can withdrawal Minimum 100 ₹");
      //   return;
      // }

      let res = await API.post(
        `/withdrawal`,
        {
          uid: userId,
          amount: Amount,
          Withtype: "WorkingNetincome",
          otp: otp,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      //console.log("activationByFund", res);
      if (res.data.data === "Withdrawal request has been sent !!") {
        toast.success("Payout Successfully!!");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <DashBoardNav />
      <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
        <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
          My Payout
        </span>
        <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
          Dashboard &gt; Payout
        </span>
      </div>
      <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
        <div className="card col-lg-12">
          <div className="card-header d-flex justify-content-between py_10 mb21">
            {/* <div className="header-title">
              <h4 className="card-title">Withdraw</h4>
            </div> */}
            <div
              className="card-header-form col-lg-12 text-center"
              style={{ backgroundColor: "rgb(194, 16, 16)" }}
            >
              <Button onClick={handleOpen} className="Addbtn">
                <i className="fas fa-plus" />{" "}
                <span style={{ color: "white" }}>Click Here For Payout</span>
              </Button>
            </div>
            <Modal
              open={open}
              // onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Payout
                </Typography>
                <Divider />
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="modal-body">
                    <div className="form-group text-center">
                      <label>
                        Current Balance:{" "}
                        <b>
                          {parseFloat(data[0]?.WorkingNetBalance).toFixed(2)} ₹
                        </b>
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label className="my14">Amount:</label>
                        <input
                          type="text"
                          id="requestAmount"
                          name="requestAmount"
                          className="form-control"
                          placeholder="Enter Amount"
                          required=""
                          value={Amount}
                          onChange={(e) => CalculateAmount(e.target.value)}
                        />
                      </div>

                      {otpvalid === "1" ? (
                        <>
                          <div className="col-md-12">
                            <label className="my14">OTP:</label>
                            <input
                              type="text"
                              name="otp"
                              maxLength={10}
                              className="form-control"
                              required=""
                              title="OTP is required"
                              placeholder="OTP"
                              onChange={(e) => hendleOTP(e.target.value)}
                              value={otp}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* <div className="col-md-12">
                                <label className="my14">Fee:</label>
                                <input
                                  type="text"
                                  id="fee"
                                  name="fee"
                                  className="form-control"
                                  value={Charge}
                                  readOnly=""
                                />
                              </div> */}
                      {/* <div className="col-md-12">
                                <label className="my14">Final Amount:</label>
                                <input
                                  type="text"
                                  id="famount"
                                  name="famount"
                                  className="form-control"
                                  value={FinalAmount}
                                  readOnly=""
                                />
                              </div> */}
                      {/* <div className="col-md-12">
                                <label className="text-danger font-14">
                                  <br />
                                  Note:
                                  <br />
                                  Min withdraw amount is{" "}
                                  {data[0]?.min_withdrawal} $ <br />
                                  Withdraw charge is{" "}
                                  {data[0]?.withdrawal_charge} %
                                </label>
                              </div> */}
                    </div>
                  </div>

                  <div>
                    <div className="modal-footer">
                      <button
                        className="btn btn-danger light"
                        style={{
                          textDecoration: "none",
                          height: "auto",
                        }}
                        onClick={handleClose}
                      >
                        Close
                      </button>
                      {otpvalid === "0" ? (
                        <button
                          className="btn btn-dark light"
                          onClick={SendOtp_API}
                          style={{
                            textDecoration: "none",
                            height: "auto",
                          }}
                        >
                          Send OTP
                        </button>
                      ) : (
                        <button
                          className="btn btn-dark light"
                          style={{
                            textDecoration: "none",
                            height: "auto",
                          }}
                          onClick={(e) => WithdrawalByUser(e)}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-20 pad-rt-20 bg-white bdr-rad-md">
          {/* table */}
          <Table data={[...currentPost]} columns={pendingWithdraw.cols} />
          <div className="container">
            <TableButton
              indexOfFirstPage={indexOfFirstPage}
              indexOfLastPost={indexOfLastPost}
              setcurrentPage={setcurrentPage}
              currentPage={currentPage}
              totalData={pendingWithdrawApi.length}
              listPerpage={listPerpage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
