import React, { useEffect, useState } from "react";
import { API } from "../../API/Api";
import { useSelector } from "react-redux";
import TableButton from "../TableButton/TableButton";
import Table from "../Table/Table";
import DashBoardNav from "../DashBoardNav/DashBoardNav";

export default function ROIPayout() {
  const [pendingWithdrawApi, setPendingWithdrawApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const WithdrawH_API = async () => {
    try {
      let res = await API.post(
        `/withdrawalHistory`,
        {
          uid: userId,
          type: 4,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      res = res.data.data[0];
      console.log("response", res);
      setPendingWithdrawApi([]);
      // console.log("withdrwahistory", res);
      let arr = [];
      res.forEach((item, index) => {
        arr.push({
          wid: item.uid,
          Username: item.Username,
          amount: item.Request_amount,
          charge: item.admincharge,
          wamount: item.amount,
          paymode: item.paymode,
          status: item.statuss,
          tnx: (
            <a
              href={`https://tronscan.org/#/transaction/${item.txn}`}
              target="_blank"
            >
              {" "}
              View Txn
            </a>
          ),
          rtdate: item.rdate,
          rsdate: item.tdate,
        });
      });

      setPendingWithdrawApi(arr);
    } catch (e) {
      console.log(" Withdraw history API not working", e);
    }
  };

  useEffect(() => {
    WithdrawH_API();
  }, []);

  // eslint-disable-next-line no-unused-vars
  var [pendingWithdraw, setpendingWithdrawApi] = new useState({
    cols: [
      { Header: "User ID", accessor: "Username" },
      { Header: "Amount", accessor: "amount" },
      { Header: "Charge", accessor: "charge" },
      { Header: "Payout Amount", accessor: "wamount" },
      { Header: "Payout Type", accessor: "paymode" },
      { Header: "Status", accessor: "status" },
      // { Header: "Tnx", accessor: "tnx" },
      { Header: "Request Date", accessor: "rtdate" },
      { Header: "Response Date", accessor: "rsdate" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = pendingWithdrawApi.slice(
    indexOfFirstPage,
    indexOfLastPost
  );

  return (
    <>
      <div>
        <DashBoardNav />
        <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
          <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
            Payout Log
          </span>
          <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
            Dashboard &gt; Payout
          </span>
        </div>
        <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
          {/* <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
            <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
              <span className="float-left text-sm filterbtn color-black bdr-rad-xs">
                Click For Fund Received
              </span>
              <div className="card col-lg-12">
                <div className="card-header d-flex justify-content-between py_10 mb21">
                  
                </div>
              </div>
            </div>
          </div> */}
          <div className="bg pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
            <div className="bg text-center pad-tp-20 pad-lt-5 pad-rt-10">
              <span className="text-md font-bold color-grad1 float-left">
                ROI Payout Log
              </span>
            </div>
            <br />
            <br />
            {/* table */}
            <Table data={[...currentPost]} columns={pendingWithdraw.cols} />
            <div className="container">
              <TableButton
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={pendingWithdrawApi.length}
                listPerpage={listPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
