import React, { useEffect, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import EditProfile from "./EditProfile";
import Defult from "./default.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../API/Api";
import { UpdateAuth } from "../../Redux/AuthSlice";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const Dashboard_API = async () => {
    try {
      let res = await API.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      setData(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Dashboard_API();
  }, []);

  const user = useSelector((state) => state.Auth.userId);
  const [userData, setUserData] = useState({});
  const [bankData, setbankData] = useState({});

  const UserInfo_API = async () => {
    try {
      let response = await API.post(
        `getUserDetail_byuserid`,
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const userData = response.data.data[0][0];
      setUserData(userData);

      let responseBank = await API.post(
        `getbankDetails_byuserid`,
        {
          userId: userId,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Response-->Bank", responseBank);
      const BankData = responseBank.data.data[0][0];
      setbankData(BankData);
    } catch (error) {
      console.log("Error While calling userinfomyReferrals API", error);
    }
  };

  useEffect(() => {
    UserInfo_API();
  }, []);

  return (
    <div>
      <DashBoardNav />
      <div className="bg dashb pad-tp-30 pad-bt-150 text-center">
        <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
          My Profile
        </span>
        <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
          Dashboard &gt; Profile
        </span>
      </div>
      <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea">
        <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
          <>
            <div className="content-page ms-0">
              <div className="content">
                {/* <section className=" profileSetting"> */}
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-4">
                      <div className="card author-box">
                        <div className="card-body">
                          <div className="author-box-center text-center">
                            <img
                              src={Defult}
                              alt="img"
                              className="rounded-circle author-box-picture"
                            />
                            <div className="clearfix" />
                            <div
                              className="author-box-name text-center"
                              style={{ color: "rgb(194, 16, 16)" }}
                            >
                              {userData.f_name}
                            </div>
                            <div className="author-box-job text-center">
                              Member
                            </div>
                          </div>
                          <div className="text-center">
                            <div className="author-box-description"></div>
                            <div className="w-100 d-sm-none" />
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <h4 style={{ color: "rgb(194, 16, 16)" }}>
                            Personal Details
                          </h4>
                        </div>
                        <div className="card-body">
                          <div className="py-4">
                            <div className="clearfix">
                              <span
                                className="float-left"
                                style={{ color: "rgb(194, 16, 16)" }}
                              >
                                Birthday
                              </span>
                              <span className="float-right text-muted">
                                {userData.dob}
                              </span>
                            </div>
                            <div className="clearfix">
                              <span
                                className="float-left"
                                style={{ color: "rgb(194, 16, 16)" }}
                              >
                                Phone
                              </span>
                              <span className="float-right text-muted">
                                {userData.mobile}
                              </span>
                            </div>
                            <div className="clearfix">
                              <span
                                className="float-left"
                                style={{ color: "rgb(194, 16, 16)" }}
                              >
                                Email
                              </span>
                              <span className="float-right text-muted">
                                {userData.email}
                              </span>
                            </div>
                            <div className="clearfix">
                              <span
                                className="float-left"
                                style={{ color: "rgb(194, 16, 16)" }}
                              >
                                UserId
                              </span>
                              <span className="float-right text-muted">
                                {userData.Username}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-8">
                      <div className="card">
                        <div className="padding-20">
                          <EditProfile
                            userData={userData}
                            userBankData={bankData}
                            user={user}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </section> */}
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default Profile;
