import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashShopNav from "../DashShopNav/DashShopNav";
import "./Shop_Dasboard.css";
import shopDashF from "../../Assets/shopDashF.png";
import shopDashS from "../../Assets/shopDashS.png";
import shopDashT from "../../Assets/shopDashT.png";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { Api_Fren } from "../../API/Api_Fren";

export default function Shop_Dasboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const Dashboard_API = async () => {
    try {
      let res = await Api_Fren.get(`userDashboard_Fre?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("API Response", res.data.data[0]);
      setData(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Dashboard_API();
  }, []);

  return (
    <div>
      <DashShopNav />
      <div className="content-page_See">
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-md-3">
                    <div className="custom_card">
                      <a href="/Receive_Stock">
                      <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-1wxaqej">
                        <div className="MuiStack-root css-1xhj18k">
                        <p>Receive Stock</p>
                          <div className="MuiCardContent-root css-1qw96cp">
                            <img
                              className="MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img css-rhsghg"
                              src={shopDashF}
                              height={140}
                              alt="green iguana"
                            />                            
                          </div>
                        </div>
                      </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="custom_card">
                    <a href="/Stock_Statement">
                      <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-1wxaqej">
                        <div className="MuiStack-root css-1xhj18k">
                          <p>Stock Statement</p>
                          <div className="MuiCardContent-root css-1qw96cp">
                            <img
                              className="MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img css-rhsghg"
                              src={shopDashS}
                              height={140}
                              alt="green iguana"
                            />
                          </div>
                        </div>
                      </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="custom_card">
                    <a href="/Activation_Product_Order_Request">
                      <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-1wxaqej">
                        <div className="MuiStack-root css-1xhj18k">
                          <p>Product Order Request</p>
                          <div className="MuiCardContent-root css-1qw96cp">
                            <img
                              className="MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img css-rhsghg"
                              src={shopDashT}
                              height={140}
                              alt="green iguana"
                            />
                          </div>
                        </div>
                      </div>
                      </a>
                    </div>
                  </div> 
                  <div className="col-md-3">
                    <div className="custom_card">
                    <a href="/Activation_Product_Order_History">
                      <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-1wxaqej">
                        <div className="MuiStack-root css-1xhj18k">
                          <p>Product Order History</p>
                          <div className="MuiCardContent-root css-1qw96cp">
                            <img
                              className="MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img css-rhsghg"
                              src={shopDashT}
                              height={140}
                              alt="green iguana"
                            />
                          </div>
                        </div>
                      </div>
                      </a>
                    </div>
                  </div>                 
                 {/*<div className="col-md-4">
                    <div className="card_info bdrTop4">
                      <div className="card_header">
                        <h4>Franchisee ROI Income</h4>
                      </div>
                      <div className="card_footer">
                        <h2>
                          ₹{" "}
                          {parseFloat(data[0]?.FranchiseeModel).toFixed(
                            2
                          )}
                        </h2>
                      </div>
                    </div>
                  </div> */} 
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
