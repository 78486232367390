import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import TableButton from "../TableButton/TableButton";
import Table from "../Table/Table";
import { API } from "../../API/Api";
import { Api_Fren } from "../../API/Api_Fren";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UpdateAuth } from "../../Redux/AuthSlice";
import DashShopNav from "../DashShopNav/DashShopNav";

export default function FrenWithdraw() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: alpha(theme.palette.common.white, 0.15),
    // "&:hover": {
    //   backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
    marginLeft: 0,
    marginBottom: "5px",
    width: "100%",
    backgroundColor: "#0000002e",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));

  const [pendingWithdrawApi, setPendingWithdrawApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const Dashboard_API = async () => {
    try {
      let res = await Api_Fren.get(`userDashboard_Fre?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("API Response", res.data.data[0]);
      setData(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/ShopLogin");
      }
    }
  };

  const Pwithdraw_API = async () => {
    try {
      let res = await Api_Fren.post(
        `/withdrawalHistory_Fre`,
        {
          uid: userId
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      res = res.data.data[0];
      setPendingWithdrawApi([]);
      console.log("penddingwithdrwa", res);
      let arr = [];
      res.forEach((item, index) => {
        arr.push({
          sno: item.row,
          userid: item.Request_amount,
          pname: item.admincharge,
          pamount: item.amount,
          drecived: item.Accountaddress,
          status: item.statuss,
          date: item.rdate,
        });
      });

      setPendingWithdrawApi(arr);
    } catch (e) {
      console.log("pending Withdraw API not working", e);
    }
  };

  useEffect(() => {
    Dashboard_API();
    Pwithdraw_API();
  }, []);

  // eslint-disable-next-line no-unused-vars
  var [pendingWithdraw, setpendingWithdraw] = new useState({
    cols: [
      { Header: "Withdrawal ID", accessor: "sno" },
      { Header: "Amount", accessor: "userid" },
      { Header: "Charge", accessor: "pname" },
      { Header: "Withdrawal Amount", accessor: "pamount" },      
      { Header: "Status", accessor: "status" },
      { Header: "Date", accessor: "date" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = pendingWithdrawApi.slice(
    indexOfFirstPage,
    indexOfLastPost
  );

  const [Amount, setAmount] = useState(0);
  const [Charge, setCharge] = useState(0);
  const [FinalAmount, setFinalAmount] = useState(0);

  const CalculateAmount = async (value) => {
    try {
      setAmount(value);
      // setCharge(
      //   (
      //     (parseFloat(value) * parseFloat(data[0]?.withdrawal_charge)) /
      //     parseFloat(100)
      //   ).toFixed(2)
      // );
      // setFinalAmount(
      //   (
      //     parseFloat(value) -
      //     (parseFloat(value) * parseFloat(data[0]?.withdrawal_charge)) /
      //       parseFloat(100)
      //   ).toFixed(2)
      // );
    } catch (e) {
      console.log("Error While calling CalculateAmount", e);
    }
  };

  const WithdrawalByUser = async (event) => {
    try {
      event.preventDefault();
     
      if (Amount === undefined || Amount === null) {
        toast.error("Enter Amount");
        return;
      }
      if (Amount === "") {
        toast.error("Enter Amount");
        return;
      }
      if (parseFloat(Amount) === 0) {
        toast.error("Enter Amount");
        return;
      }
      if (parseFloat(Amount) < parseFloat(100)) {
        toast.error(
          "You can withdrawal Minimum 100 ₹"
        );
        return;
      }

      let res = await Api_Fren.post(
        `/withdrawal_Fre`,
        {
          uid: userId,
          Request_amount: Amount,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.data === "Withdrawal request has been sent !!") {
        toast.success("Withdrawal Successfully!!");
        window.location.reload();
      } else {
        toast.error(res.data.data);
      }
      // console.log("activationByFund",res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <DashShopNav />
      <div className="content-page_See">
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between py_10 mb21">
                    <div className="header-title">
                      <h4 className="card-title">Withdraw</h4>
                    </div>
                    <div className="card-header-form">
                      <Button onClick={handleOpen} className="Addbtn">
                        <i className="fas fa-plus" /> Withdraw Now
                      </Button>
                    </div>
                    <Modal
                      open={open}
                      // onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Withdraw
                        </Typography>
                        <Divider />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          <div className="modal-body">
                            <div className="form-group text-center">
                              <label>
                                Current Balance:{" "}
                                <b>
                                  {parseFloat(data[0]?.NetBalanceFren).toFixed(2)} ₹
                                </b>
                              </label>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <label className="my14">Amount:</label>
                                <input
                                  type="text"
                                  id="requestAmount"
                                  name="requestAmount"
                                  className="form-control"
                                  placeholder="Enter Amount"
                                  required=""
                                  value={Amount}
                                  onChange={(e) =>
                                    CalculateAmount(e.target.value)
                                  }
                                />
                              </div>
                              {/* <div className="col-md-12">
                                <label className="my14">Fee:</label>
                                <input
                                  type="text"
                                  id="fee"
                                  name="fee"
                                  className="form-control"
                                  value={Charge}
                                  readOnly=""
                                />
                              </div> */}
                              {/* <div className="col-md-12">
                                <label className="my14">Final Amount:</label>
                                <input
                                  type="text"
                                  id="famount"
                                  name="famount"
                                  className="form-control"
                                  value={FinalAmount}
                                  readOnly=""
                                />
                              </div> */}
                              {/* <div className="col-md-12">
                                <label className="text-danger font-14">
                                  <br />
                                  Note:
                                  <br />
                                  Min withdraw amount is{" "}
                                  {data[0]?.min_withdrawal} $ <br />
                                  Withdraw charge is{" "}
                                  {data[0]?.withdrawal_charge} %
                                </label>
                              </div> */}
                            </div>
                          </div>

                          <div>
                            <div className="modal-footer">
                              <button
                                className="btn btn-danger light"
                                style={{
                                  textDecoration: "none",
                                  height: "auto",
                                }}
                                onClick={handleClose}
                              >
                                Close
                              </button>
                              <button
                                className="btn btn-dark light"
                                style={{
                                  textDecoration: "none",
                                  height: "auto",
                                }}
                                onClick={(e) => WithdrawalByUser(e)}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </Typography>
                      </Box>
                    </Modal>
                  </div>
                  <div className="card-body p-2">
                    <div className="table-responsive">
                      <div
                        id="example_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="dt-buttons btn-group">
                          <button type="button" className="btn">
                            CSV
                          </button>
                          <button type="button" className="btn">
                            Excel
                          </button>
                          <button type="button" className="btn">
                            PDF
                          </button>
                        </div>
                        <div
                          id="example_filter"
                          className="dataTables_filter fldset"
                        >
                          <Search>
                            <SearchIconWrapper>
                              <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                              placeholder="Search…"
                              inputProps={{ "aria-label": "search" }}
                            />
                          </Search>
                        </div>
                      </div>
                    </div>
                    {/* table */}
                    <Table
                      data={[...currentPost]}
                      columns={pendingWithdraw.cols}
                    />
                    <div className="container">
                      <TableButton
                        indexOfFirstPage={indexOfFirstPage}
                        indexOfLastPost={indexOfLastPost}
                        setcurrentPage={setcurrentPage}
                        currentPage={currentPage}
                        totalData={pendingWithdrawApi.length}
                        listPerpage={listPerpage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
