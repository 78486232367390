import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import bgimg from "../../Assets/WebImages/idcardimage.png";
import Logo from "../../Assets/WebImages/logo.png";
import "./id.css";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { API } from "../../API/Api";
import { useDispatch, useSelector } from "react-redux";

const IDCardOne = () => {
  const handlePrint = () => {
    window.print();
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const [userData, setUserData] = useState({});

  const UserInfo_API = async () => {
    try {
      let response = await API.post(
        `getUserDetail_byuserid`,
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const userData = response.data.data[0][0];
      console.log("userData", userData);
      setUserData(userData);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    UserInfo_API();
  }, []);

  return (
    <>
      <div>
        <div className="container idcardsection">
          <div className="row py-5">
            <div className="col-md-8 mx-auto col-12">
              <div
                className="idcard py-5"
                style={{
                  backgroundImage: `url(${bgimg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                }}
              >
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr style={{ borderBottom: "0px" }}>
                      <td>
                        <div
                          className="my-2 logomain"
                          style={{
                            width: "330px",
                            height: "170px",
                            margin: "auto",
                          }}
                        >
                          <img
                            className="w-100 h-100"
                            src={Logo}
                            alt="logoes"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div
                          className="my-3 userimage"
                          style={{
                            width: "200px",
                            height: "200px",
                            border: "10px solid #3AB34C",
                            borderRadius: "50%",
                            margin: "auto",
                          }}
                        >
                          <img
                            className="w-100 h-100 rounded-pill"
                            src={Logo}
                            alt="imagess"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "0px" }}>
                      <td className="text-center py-3">
                        <div
                          className="textmain1"
                          style={{
                            fontSize: "40px",
                            fontWeight: "bold",
                            color: "black",
                            textTransform: "uppercase",
                            letterSpacing: "3px",
                          }}
                        >
                          {userData.f_name}
                        </div>
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "0px" }}>
                      <td className="text-center py-sm-4 py-2">
                        <div
                          className="fw-normal text-black text2main"
                          style={{ fontSize: "35px", letterSpacing: "4px" }}
                        >
                          {userData.rank_name}
                        </div>
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "0px" }}>
                      <td className="text-center py-sm-4 py-2">
                        <div
                          className="fw-normal text-black textmainmail"
                          style={{ fontSize: "30px", letterSpacing: "4px" }}
                        >
                          {userData.mobile}
                        </div>
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "0px" }}>
                      <td className="text-center py-sm-4 py-2">
                        <div
                          className="fw-normal text-black textmainmail"
                          style={{ fontSize: "30px", letterSpacing: "4px" }}
                        >
                          {userData.email}
                        </div>
                      </td>
                    </tr>
                    {/* <tr style={{ borderBottom: "0px" }}>
                      <td className="text-center py-4">
                        <div
                          className="fw-normal text-black"
                          style={{ fontSize: "30px", letterSpacing: "4px" }}>
                          ID - <span>{userId}</span>
                        </div>
                      </td>
                    </tr> */}
                    <tr
                      className="pb-sm-4 pb-2"
                      style={{ borderBottom: "0px" }}
                    >
                      <td>
                        <div
                          style={{
                            borderBottom: "3px solid #D3A067",
                            width: "50%",
                            margin: "auto",
                          }}
                          className=""
                        ></div>
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "0px" }}>
                      <td className="text-center pb-sm-4 pb-2">
                        <div
                          className="fw-bold text-black pb-sm-5 pb-3 addres"
                          style={{
                            fontSize: "26px",
                            letterSpacing: "3px",
                            lineHeight: "48px",
                          }}
                        >
                          Head Office : 3C/104 ,avas Vikas buddhi vihar,
                          muradabad Pin-244001
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ textAlign: "center", marginTop: "20px" }}
        className="no-print"
      >
        <button
          className="btn btn-success"
          onClick={handlePrint}
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          Download
        </button>
      </div>
    </>
  );
};
export default IDCardOne;
