import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoTwitter } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import footerimage1 from "../../Assets/WebImages/rv-9-footer-article-1.jpg";
import footerimage2 from "../../Assets/WebImages/rv-9-footer-article-2.jpg";
import PhoneIcon from "../../Assets/WebImages/phone-alt.png";
import Emailicon from "../../Assets/WebImages/envelope.png";
import watchicon from "../../Assets/WebImages/wtach.png";
import arrow from "../../Assets/WebImages/arrow-right.png";
import logo from "../../Assets/Logo/logo.png";
import phonecolor from "../../Assets/WebImages/phone-altcolor.png";
import emailcolor from "../../Assets/WebImages/envelopecolor.png";
import mapcolor from "../../Assets/WebImages/map-marker-altcolor.png";
import { API } from "../../API/Api";
import swal from "sweetalert";
const Hero = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [query, setQuery] = useState("");

  const Dashboard_API = async () => {
    try {
      let res = await API.post(`saveQueryData`, {
        name: name,
        email: email,
        mobile: mobile,
        query: query,
      });
      //console.log("API Response", res.data.data);
      if (res.data.data === "success") {
        swal({
          title: "Success..!",
          text: "Yoyr message has been sent successfully.",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result) {
            setName("");
            setEmail("");
            setMobile("");
            setQuery("");
          }
        });
      } else {
        swal({
          title: "Info..!",
          text: `${res.data.data}`,
          icon: "info",
          confirmButtonText: "Ok",
        });
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  // useEffect(() => {

  //     const images = [bg1, bg2, bg];
  //     images.forEach((imageSrc) => {
  //         const img = new Image();
  //         img.src = imageSrc;
  //     });
  // }, []);

  return (
    <div>
      <div className="v_light_purple" data-spy="scroll">
        <Header />
        <section className="container-fluid contactsection pt-0">
          <div className="row mb-4">
            <div className="col-12 text-center py-5 headingpoint">
              <div className="py-5">
                <h1 className="fw-bold text-black  w-auto">
                  <span className="border-bottom">Contact</span>
                </h1>
                <div className="d-flex align-items-center gap-3 justify-content-center">
                  <span>
                    <a href="/home">
                      <span className="imagehome"></span>Home
                    </a>
                    / <a href="/">Contact</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container py-md-4">
            <div className="row mb-5">
              <div className="col-md-3">
                <div className="phoneicon mb-3">
                  <img className="w-100 h-100" src={PhoneIcon} alt="phone" />
                </div>
                <h5 className="fw-bold text-black mb-3">Contact</h5>
                <ul className="list-unstyled">
                  <li className="text-black">
                    <a className="text-black" href="tel:+919068282716">
                      +91 9068282716
                    </a>
                  </li>
                  <li className="text-black">
                    <a className="text-black" href="mailto:info@jivandhara.org">
                      info@jivandhara.org
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-5">
                <div className="phoneicon mb-3">
                  <img className="w-100 h-100" src={Emailicon} alt="phone" />
                </div>
                <h5 className="fw-bold text-black mb-3">Address</h5>
                <p className="text-black">
                  3C/104, Avas Vikas, Buddhi Bihar, Moradabad,244001
                </p>
              </div>
              <div className="col-md-4">
                <div className="phoneicon mb-3">
                  <img className="w-100 h-100" src={watchicon} alt="phone" />
                </div>
                <h5 className="fw-bold text-black mb-3">Hours of Operation</h5>
                <ul class="rv-5-footer-timings">
                  <li className="mb-2">
                    <span class="key">Monday - Friday : </span>
                    <span class="value">08:30 am - 10:00 pm</span>
                  </li>
                  <li className="mb-2">
                    <span class="key">Saturday - Sunday : </span>
                    <span class="value">10:30 am - 08:00 pm</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-12">
                <h3 className="fw-bold text-black">Let's Connect.</h3>
              </div>
              <div className="col-md-6">
                <input
                  className="border"
                  type="text"
                  name="name"
                  id="rv-2-contact-name"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
              <div className="col-md-6">
                <input
                  className="border"
                  type="email"
                  name="email"
                  id="rv-2-contact-email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </div>

              <div className="col-md-6">
                <input
                  className="border"
                  type="text"
                  name="mobile"
                  id="rv-2-contact-email"
                  placeholder="mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                ></input>
              </div>

              <div class="col-12">
                <textarea
                  className="w-100 rounded-3"
                  rows="4"
                  name="message"
                  id="rv-2-contact-message"
                  placeholder="Message"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                ></textarea>
              </div>
              <div class="col-12">
                <button
                  className="w-100 rounded-0"
                  type="button"
                  onClick={() => Dashboard_API()}
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </section>
        <footer className="purple_dark_bg footer_pattern footermain">
          <div className="top_footer">
            <div className="container">
              <div className="row align-items-center g-3 border-bottom pb-3">
                <div className="col-md-3 col-sm-6 col-12">
                  <a href="#" className="logofooter">
                    <img className="w-100 h-100" src={logo} alt="logo" />
                  </a>
                </div>
                <div className="col-md-3 col-sm-6 col-12">
                  <div className="footericon_group">
                    <a href="tel:+919068282716" className="icons">
                      <img
                        className="w-100 h-100"
                        src={phonecolor}
                        alt="icon"
                      />
                    </a>
                    <div className="footertopsection">
                      <div className="footerheadingtop text-white fw-bold">
                        Our Phone
                      </div>
                      <a
                        className="numbertext text-secondary"
                        href="tel:+919068282716"
                      >
                        +91 9068282716
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-12">
                  <div className="footericon_group">
                    <a href="mailto:info@jivandhara.org" className="icons">
                      <img
                        className="w-100 h-100"
                        src={emailcolor}
                        alt="icon"
                      />
                    </a>
                    <div className="footertopsection">
                      <div className="footerheadingtop text-white fw-bold">
                        Our Email
                      </div>
                      <a
                        className="numbertext text-secondary"
                        href="mailto:info@jivandhara.org"
                      >
                        info@jivandhara.org
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-12 pb-3 pb-sm-0">
                  <div className="footericon_group">
                    <div className="icons">
                      <img className="w-100 h-100" src={mapcolor} alt="icon" />
                    </div>
                    <div className="footertopsection">
                      <div className="footerheadingtop text-white fw-bold">
                        Our Address
                      </div>
                      <div className="numbertext text-secondary">
                        3C/104, Avas Vikas, <br /> Buddhi Bihar, Moradabad,
                        <br /> 244001
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 py-4">
                <div className="col-md-3">
                  <div className="footerheading">About us</div>
                  <p className="subtext">
                    We transforming our “waste” stream through active landfill
                    diversion and local disposal of food residuals and other
                    compostables.
                  </p>
                  <ul className="iconlistsocial">
                    <li>
                      <a href="">
                        <FaFacebookF className=" fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <IoLogoTwitter className=" fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <FaInstagram className=" fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <FaTelegramPlane className=" fa fa-telegram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2">
                  <div className="footerheading">Our Services</div>
                  <ul>
                    <li>
                      <a href="/">Kitchen Gardning</a>
                    </li>
                    <li>
                      <a href="/">Contract Farming </a>
                    </li>
                    <li>
                      <a href="/">Plantation </a>
                    </li>
                    <li>
                      <a href="/">Vermi Compose </a>
                    </li>
                    <li>
                      <a href="/">Organic Product </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <div className="footerheading">Recent Posts</div>
                  <div className="imagetextgroup mb-3">
                    <div className="imagefooter">
                      <img
                        className="w-100 h-100"
                        src={footerimage1}
                        alt="imagefooter"
                      />
                    </div>
                    <div className="d-flex gap-1 flex-column">
                      <div className="text-white datetext">June 16, 2023</div>
                      <div className="fw-bold text-white querytext">
                        Ways to Freshen Up Curtains & Drapes Home.
                      </div>
                    </div>
                  </div>
                  <div className="imagetextgroup mb-3">
                    <div className="imagefooter">
                      <img
                        className="w-100 h-100"
                        src={footerimage2}
                        alt="imagefooter"
                      />
                    </div>
                    <div className="d-flex gap-1 flex-column">
                      <div className="text-white datetext">July 21, 2023</div>
                      <div className="fw-bold text-white querytext">
                        Streamlining Your Laundry Routine Through Automation.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="footerheading">Newsletter Signup</div>
                  <div class="nwsltr-top mb-3 position-absolute">
                    <input
                      type="email"
                      name="email"
                      id="rv-8-subs-form"
                      placeholder="Enter your Email..."
                    />
                    <button>
                      <span>
                        <img
                          className="w-100 h-100"
                          src={arrow}
                          alt="imagearrow"
                        />
                      </span>
                    </button>
                  </div>
                  <div class="rv-6-footer-nwsltr__checkbox d-flex align-items-center gap-3">
                    <input
                      type="checkbox"
                      className="w-auto"
                      id="nwsltr-checkbox"
                      name="checkbox"
                      value="1"
                    />
                    <label className="mb-0" for="nwsltr-checkbox">
                      {" "}
                      I agree to the <a href="#">Privacy Policy</a>.
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-footer">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer_bottom">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <p className="copyright">
                          Copyright © 2024 ekopowerev.com | All Rights Reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};
export default Hero;
