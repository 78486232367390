import React, { useEffect, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import TableButton from "../TableButton/TableButton";
import Table from "../Table/Table";
import { API } from "../../API/Api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAuth } from "../../Redux/AuthSlice";

export default function DirectIncome() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [levelIncomeApi, setLevelIncomeApi] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [listPerpage, setlistPerpage] = useState(10);
    const [totalIncome, setTotalIncome] = useState();

    const { userId, jwt_token } = useSelector((state) => state.Auth);
    const LevelIncome_API = async (fromDate, toDate) => {
        try {
            let res = await API.post(
                `DirectIncome`,
                {
                    uid: userId,
                    fdate: fromDate || "",
                    tdate: toDate || "",
                },
                {
                    headers: {
                        Authorization: `${jwt_token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            // if (res.data.data && res.data.data.totalIncome) {
            //   setTotalIncome(res.data.data.totalIncome);
            // }

            console.log("level income", res);
            let arr = [];
            res.data.data[0].forEach((item, index) => {
                arr.push({
                    RowNumber: item.RowNumber,
                    Username: item.Username,
                    From_Username: item.From_Username,
                    onamount: item.onamount,
                    income: parseFloat(item.income).toFixed(2),
                    dd: item.dd,
                });
            });

            setLevelIncomeApi(arr);
        } catch (e) {
            if (
                e.response.data.showableMessage ==
                "Please login to access this resource"
            ) {
                dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
                navigate("/");
            }
        }
    };

    const handleSearch = () => {
        const fromDate = document.getElementById("fdate").value;
        const toDate = document.getElementById("tdate").value;
        LevelIncome_API(fromDate, toDate);
    };

    useEffect(() => {
        LevelIncome_API();
    }, []);

    // eslint-disable-next-line no-unused-vars
    var [myTeam, setMyTeam] = new useState({
        cols: [
            { Header: "S.No.", accessor: "RowNumber" },
            { Header: "User Id", accessor: "Username" },
            { Header: "From ID", accessor: "From_Username" },
            { Header: "On amount", accessor: "onamount" },
            { Header: "Income", accessor: "income" },
            { Header: "Created At", accessor: "dd" },
        ],
    });

    const indexOfLastPost = currentPage * listPerpage;
    const indexOfFirstPage = indexOfLastPost - listPerpage;
    const currentPost = levelIncomeApi.slice(indexOfFirstPage, indexOfLastPost);

    return (
        <>
            <div>
                <DashBoardNav />
                <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
                    <span className="pad-tp-20 bg marg-tp-50 text-nowrap reText text-xl color-white">
                        Direct Income
                    </span>
                    <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
                        Dashboard &gt; Report
                    </span>
                </div>
                <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
                    <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
                        <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
                            <span
                                // onclick=""
                                className="float-left text-sm filterbtn color-black bdr-rad-xs">
                                Advance Filter
                            </span>
                            <div className="card col-lg-12">
                                <div className="card-body">
                                    <form
                                        action=""
                                        method="post"
                                        className="ng-pristine ng-valid">
                                        <div className="box-body activation_pannel">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>
                                                            From Date :
                                                            <input
                                                                className="form-control form-control-sm"
                                                                id="fdate"
                                                                name="fdate"
                                                                type="date"
                                                                defaultValue=""
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label style={{ "margin-left": "5px" }}>
                                                            To Date : {" "}
                                                            <input
                                                                className="form-control form-control-sm"
                                                                id="tdate"
                                                                name="tdate"
                                                                type="date"
                                                                defaultValue=""
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success mpy"
                                                        onClick={handleSearch}>
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
                        <div className="bg text-center pad-tp-20 pad-lt-5 pad-rt-10">
                            <span className="text-md font-bold color-grad1 float-left">
                            Direct Income
                            </span>
                        </div>
                        <br />
                        <br />
                        <div className="card-body p-2">
                            <div className="table-responsive">
                                <div
                                    // style={{ maxHeight: 400, overflow: "scroll" }}
                                    id="example_wrapper"
                                    className="dataTables_wrapper dt-bootstrap4 no-footer"></div>
                                {/* table */}
                                <Table data={[...currentPost]} columns={myTeam.cols} />
                                <div className="container">
                                    <TableButton
                                        indexOfFirstPage={indexOfFirstPage}
                                        indexOfLastPost={indexOfLastPost}
                                        setcurrentPage={setcurrentPage}
                                        currentPage={currentPage}
                                        totalData={levelIncomeApi.length}
                                        listPerpage={listPerpage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
