import React, { useEffect, useState } from "react";
import TableButton from "../TableButton/TableButton";
import Table from "../Table/Table";
import DashShopNav from "../DashShopNav/DashShopNav";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { Api_Fren } from "../../API/Api_Fren";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Receive_Stock() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [levelIncomeApi, setLevelIncomeApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [totalIncome, setTotalIncome] = useState();
  const [selectedLevel, setSelectedLevel] = useState(0);
  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const Pwithdraw_API = async () => {
    try {
      let res = await Api_Fren.post(
        `/receiveStockACTProduct`,
        {
          sid: userId,
          fromDt: "",
          toDt: "",
          search: "",
          type: "0",
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // if (res.data.data && res.data.data.totalIncome) {
      //   setTotalIncome(res.data.data.totalIncome);
      // }

      console.log("level income", res);
      let arr = [];
      res.data.data[0].forEach((item, index) => {
        arr.push({
          sno: item.Sno,
          poid: item.poid,
          PoNumber: item.PoNumber,
          brname: item.brname,
          vname: item.vname,
          NoofItem: item.NoofItem,
          TotalAmount: item.TotalAmount,
          PoDate: item.PoDate,
          status: item.status,
        });
      });

      setLevelIncomeApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const handleSearch = () => {
    const fromDate = document.getElementById("fdate").value;
    const toDate = document.getElementById("tdate").value;
    Pwithdraw_API();
  };

  useEffect(() => {
    Pwithdraw_API();
  }, []);
  var [myTeam, setMyTeam] = new useState({
    cols: [
      { Header: "S.No", accessor: "sno" },
      { Header: "Order No.", accessor: "poid" },
      { Header: "From", accessor: "vname" },
      { Header: "No. of Item", accessor: "NoofItem" },
      { Header: "Order Date", accessor: "PoDate" },
      { Header: "Status", accessor: "status" },
      { Header: "Total Amount", accessor: "TotalAmount" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = levelIncomeApi.slice(indexOfFirstPage, indexOfLastPost);

  return (
    <>
      <div>
        <DashShopNav />
        <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
          <span className="pad-tp-20 bg marg-tp-50 text-nowrap reText text-xl color-white">
            Receive Stock
          </span>
          <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
            Dashboard &gt; Product
          </span>
        </div>
        <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
          <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
            <div className="bg text-center pad-tp-20 pad-lt-5 pad-rt-10">
              <span className="text-md font-bold color-grad1 float-left">
                Receive Stock
              </span>
            </div>
            <br />
            <br />
            <div className="card-body p-2">
              <div className="table-responsive">
                <div
                  // style={{ maxHeight: 400, overflow: "scroll" }}
                  id="example_wrapper"
                  className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  {/* <div className="dt-buttons btn-group">
                          <button type="button" className="btn">
                            CSV
                          </button>
                          <button type="button" className="btn">
                            Excel
                          </button>
                          <button type="button" className="btn">
                            PDF
                          </button>
                        </div> */}
                  {/* <div
                          id="example_filter"
                          className="dataTables_filter fldset"
                        >
                          <label>
                            <b>From Date : </b>{" "}
                            <input
                              className="form-control form-control-sm"
                              id="fdate"
                              name="fdate"
                              type="date"
                              defaultValue=""
                            />
                          </label>
                          <label>
                            <b>To Date : </b>{" "}
                            <input
                              className="form-control form-control-sm"
                              id="tdate"
                              name="tdate"
                              type="date"
                              defaultValue=""
                            />
                          </label>

                          <label>
                            <button
                              // type="submit"
                              className="btn btn-success btn-sm"
                              style={{ height: "auto" }}
                              onClick={handleSearch}
                            >
                              Search
                            </button>
                          </label>
                        </div> */}
                </div>
                {/* table */}
                <Table data={[...currentPost]} columns={myTeam.cols} />
                <div className="container">
                  <TableButton
                    indexOfFirstPage={indexOfFirstPage}
                    indexOfLastPost={indexOfLastPost}
                    setcurrentPage={setcurrentPage}
                    currentPage={currentPage}
                    totalData={levelIncomeApi.length}
                    listPerpage={listPerpage}
                  />
                </div>
              </div>
            </div>

            {/* <div className="flexbox tr bg-purple marg-bt-10">
            <div className="flex-md-40 color-white">User</div>
            <div className="flex-md-60 color-white">Business</div>
          </div>
          <div className="bg pad-tp-10 pad-bt-20 marg-bt-20">
            <span className="text-big disp-block marg-bt-5">
              No Record Saved
            </span>
            <span className="text-st color-gray disp-block">
              Invite your friends to join our network.
            </span>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
