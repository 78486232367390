import logo from "../../Assets/Logo/logo.png";
import React, { useEffect, useState } from "react";
import DashShopNav from "../DashShopNav/DashShopNav";
import Table from "../Table/Table";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../API/Api";
import { PausePresentationOutlined } from "@mui/icons-material";

export default function Invoice() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const [OrderId, setOrderId] = useState("");
  const [OrderData, setOrderData] = useState("");
  const [OrderDetails, setOrderDetails] = useState([]);

  const OrderDetail_API = async () => {
    let URL_ID = new URLSearchParams(window.location.search).get("OrderNo");
    try {
      setOrderId(URL_ID);
      let res = await API.post(
        `showorderActProductmain`,
        {
          uid: userId,
          id: URL_ID,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setOrderData(res.data.data[0]);
      console.log("OrderData-->", res.data.data[0]);

      let resProduct = await API.post(
        `showorderActProduct`,
        {
          uid: userId,
          id: URL_ID,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setOrderDetails(resProduct.data.data[0]);

      console.log("OrderData-->", resProduct.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    OrderDetail_API();
  }, []);

  const printDiv = () => {
    window.print();
  };

  return (
    <>
      <div
        className="content"
        style={{
          //   width: "1030px",
          //   padding: "30px",
          border: "solid 1px #ccc",
        }}
      >
      
        <div
          className="container"
          id="InvoiceView"
          style={{ fontFamily: "Verdana, Geneva, sans-serif" }}
        >
          <div className="box">
            <table
              align="center"
              width="100%"
              border={0}
              cellSpacing={5}
              cellPadding={5}
            >
              <tbody>
                <tr>
                  <td
                    colSpan={2}
                    style={{ textAlign: "center", padding: "26px 0 0 0" }}
                  >
                    <div className="invoice_heading">
                      <div className="section_left">
                        <img src={logo} width="100px" alt="" />
                      </div>
                      <div className="section_right">
                        <h2>JIVANDHARA</h2>
                        <p>
                          Headquarter office No-5 The Opus Tower by Zaid saffron
                          Dubai
                        </p>
                        <p>
                          <span>
                            <b>Mobile</b>: +91 000000000
                          </span>{" "}
                          <span>
                            <b>GSTIN</b>: 00000000
                          </span>
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="invoice_detail">
                      <p>
                        Invoice No.: <span>{OrderData[0]?.invoicenumber}</span>
                      </p>
                      <p>
                        Invoice Date: <span>{OrderData[0]?.dt}</span>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr className="border_btm_set">
                  <td width="50%" style={{ color: "black" }}>
                    <div className="invoiceName">
                      <h2 style={{ fontFamily: "Arial", fontSize: "large" }}>
                        <b>BILL TO</b>
                      </h2>
                    </div>
                    <div className="invoiceName">
                      <label>User ID : </label>
                      <span>{OrderData[0]?.Uid}</span>
                    </div>
                    <div className="invoiceName">
                      <label>Name : </label>
                      <span>{OrderData[0]?.username}</span>
                    </div>
                    <div className="invoiceName">
                      <label>Address : </label>

                      <span>
                        {OrderData[0]?.address} , {OrderData[0]?.DistrictName}
                      </span>
                    </div>
                    <div className="invoiceName">
                      <label>PinCode : </label>
                      <span>{OrderData[0]?.pincode[1]}</span>
                    </div>
                    <div className="invoiceName">
                      <label>Mobile : </label>
                      <span>{OrderData[0]?.MobileNo1}</span>
                    </div>
                  </td>
                  <td width="50%" style={{ color: "black" }}>
                    <div className="invoiceName">
                      <h2 style={{ fontFamily: "Arial", fontSize: "large" }}>
                        <b>SHIP TO</b>
                      </h2>
                    </div>
                    <div className="invoiceName">
                      <label>User ID : </label>
                      <span>{OrderData[0]?.Uid}</span>
                    </div>
                    <div className="invoiceName">
                      <label>Name : </label>
                      <span>{OrderData[0]?.username}</span>
                    </div>
                    <div className="invoiceName">
                      <label>Address : </label>

                      <span>
                        {OrderData[0]?.address} , {OrderData[0]?.DistrictName}
                      </span>
                    </div>
                    <div className="invoiceName">
                      <label>PinCode : </label>
                      <span>{OrderData[0]?.pincode[1]}</span>
                    </div>
                    <div className="invoiceName">
                      <label>Mobile : </label>
                      <span>{OrderData[0]?.MobileNo1}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div id="customer">
                      <div className="person-detail">
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover invoice_custom_set">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>ITEMS</th>
                                {/* <th>HSN</th> */}
                                <th>QTY</th>
                                <th>DP</th>
                                <th>TAX</th>
                                <th>TOTAL AMOUNT</th>
                              </tr>
                            </thead>
                            <tbody>
                              {OrderDetails?.map((ele, index) => (
                                <tr>
                                  <td>{ele.row}</td>
                                  <td>{ele.pname}</td>
                                  {/* <td>{ele.HSN}</td> */}
                                  <td>{ele.Qty}</td>
                                  <td>{ele.MRP}</td>
                                  <td>{ele.taxamount}</td>
                                  <td>
                                    {parseFloat(ele.MRP) +
                                      parseInt(ele.taxamount)}
                                  </td>
                                </tr>
                              ))}
                              {/* <tr>
                              <td>1</td>
                              <td>EKO HERBS</td>
                              <td>232044</td>
                              <td>1</td>
                              <td>1542.4</td>
                              <td>10.0000</td>
                              <td>
                                <span>₹</span>277.6
                                <br />
                                <span>
                                  (18<span>%</span>)
                                </span>
                              </td>
                              <td>
                                <span>₹</span>1820
                              </td>
                              <td>10</td>
                            </tr> */}
                              <tr
                                style={{ fontWeight: "bold" }}
                                className="border_btm_set"
                              >
                                <td colSpan={3}>SUB TOTAL</td>
                                <td>₹ {OrderData[0]?.TotalMRP}</td>
                                <td>₹ {OrderData[0]?.TotalTaxAmount}</td>
                                <td>
                                  ₹{" "}
                                  {parseFloat(OrderData[0]?.TotalMRP) +
                                    parseFloat(OrderData[0]?.TotalTaxAmount)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>

                {/* <tr className="border_btm_set">
                <td width="50%" style={{color:"black"}}>
                  <div className="invoicefotr">
                    
                    <h2>
                      <b>BANK DETAILS</b>
                    </h2>
                    
                    <div className="bank_detail">
                      <p>Name:</p>
                      <p>EKO POWER</p>
                    </div>
                    <div className="bank_detail">
                      <p>IFSC Code:</p>
                      <p>ICIC0001639</p>
                    </div>
                    <div className="bank_detail">
                      <p>Account No:</p>
                      <p>163905001081</p>
                    </div>
                    <div className="bank_detail">
                      <p>Bank &amp; Branch:</p>
                      <p>ICICI BANK,DWARKA SECTOR 11, DELHI</p>
                    </div>
                    
                    
                    
                  </div>
                </td>
                <td width="50%" style={{color:"black"}}>
                  <div className="invoicefotr set_rate_right">
                    <div className="bank_detail">
                      <p>TAXABLE AMOUNT</p>
                      <p>
                        ₹ <span>{OrderData[0]?.TotalMRP}</span>
                      </p>
                    </div>
                    <div className="bank_detail">
                      <p>TAX </p>
                      <p>
                        ₹ <span>{OrderData[0]?.TotalTaxAmount}</span>
                      </p>
                    </div>                   
                    <div className="bank_detail border_top_set bold_set">
                      <p>TOTAL (Inc. Tax)</p>
                      <p>₹ <span>{ parseFloat(OrderData[0]?.TotalMRP) + parseFloat(OrderData[0]?.TotalTaxAmount)}</span></p>
                    </div>
                  </div>
                </td>
               
              </tr>
              <tr>
              <td> 
                <h4>
                      <b>TERMS AND CONDITIONS:</b>
                    </h4>
                    <p>
                      Delivery in India In India,
                      <br />
                      We Ship The Product Via.
                    </p>
                    <p>
                      First, By DTDC/ Delhivery Courier and it takes around 6 to
                      7 days to deliver the package.
                    </p>
                    <p>
                      Second, In some places where DTDC/Delhivery courier
                      services are not available/applicable than we ship the
                      product via Indian Post and it takes around 10 to 15 days
                      to deliver the product. For free shipping, a customer
                      requires to purchase at least a product of Rs 1500/- &amp;
                      above. If the amount of the product is less than Rs 1500/-
                      then customer has to pay extra shipping charges and that
                      is Rs 150/-
                    </p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="invoicefotr"></div>
                </td>
                
                <td width="26%">
                  <div className="invoicesignature">
                    <h2>Invoice Amount (in words)</h2>
                    <p style={{ marginBottom: 40 }}>
                      {" "}
                      One Thousand Eight Hundred And Twenty Only.
                    </p>
                    <p>
                      AUTHORISED SIGNATORY FOR
                      <br />
                      <span>EKO POWER</span>
                    </p>
                  </div>
                </td>
              </tr> */}
              </tbody>
            </table>
            <div className="row">
              <div className="col-md-12">
                <h4>
                  <b>BANK DETAILS</b>
                </h4>
              </div>
              <div className="col-md-6">
                <div className="bor">
                  <div className="bank_detail">
                    <p>Name:</p>
                    <p>JIVANDHARA</p>
                  </div>
                  <div className="bank_detail">
                    <p>IFSC Code:</p>
                    <p>ICIC0001639</p>
                  </div>
                  <div className="bank_detail">
                    <p>Account No:</p>
                    <p>163905001081</p>
                  </div>
                  <div className="bank_detail">
                    <p>Bank &amp; Branch:</p>
                    <p>ICICI BANK,DWARKA SECTOR 11, DELHI</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="bor">
                  <div className="bank_detail">
                    <p>TAXABLE AMOUNT</p>
                    <p>
                      ₹ <span>{OrderData[0]?.TotalMRP}</span>
                    </p>
                  </div>
                  <div className="bank_detail">
                    <p>TAX </p>
                    <p>
                      ₹ <span>{OrderData[0]?.TotalTaxAmount}</span>
                    </p>
                  </div>
                  <div className="bank_detail border_top_set bold_set">
                    <p>TOTAL (Inc. Tax)</p>
                    <p>
                      ₹{" "}
                      <span>
                        {parseFloat(OrderData[0]?.TotalMRP) +
                          parseFloat(OrderData[0]?.TotalTaxAmount)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-md-6">
                <h4>
                  <b>TERMS AND CONDITIONS:</b>
                </h4>
                <div className="bor">
                  <p>Delivery in India In India, We Ship The Product Via.</p>
                  <p>
                    First, By DTDC/ Delhivery Courier and it takes around 6 to 7
                    days to deliver the package.
                  </p>
                  <p>
                    Second, In some places where DTDC/Delhivery courier services
                    are not available/applicable than we ship the product via
                    Indian Post and it takes around 10 to 15 days to deliver the
                    product. For free shipping, a customer requires to purchase
                    at least a product of Rs 1500/- &amp; above. If the amount
                    of the product is less than Rs 1500/- then customer has to
                    pay extra shipping charges and that is Rs 150/-
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <h4>
                  <b>INVOICE AMOUNT (in words)</b>
                </h4>
                <div className="bor">
                  <p style={{ marginBottom: 40 }}>
                    {" "}
                    One Thousand Eight Hundred And Twenty Only.
                  </p>
                  <p>
                    AUTHORISED SIGNATORY FOR
                    <br />
                    <span>EKO POWER</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="invoiceprint">
            <center>
              <input
                type="button"
                className="hidden-print"
                id="btn"
                value="Print"
                onClick={printDiv}
              />
            </center>
          </div>
        </div>
      </div>
    </>
  );
}
