import React from 'react'
import Header from '../Header/Header'
import logo from "../../Assets/Logo/logo.png"
// import pdf from "../../Assets/WebImages/PDF.png"
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoTwitter } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import emailcolor from "../../Assets/WebImages/envelopecolor.png";
import phonecolor from "../../Assets/WebImages/phone-altcolor.png";
import servicecard1 from "../../Assets/WebImages/home-6-service-1.png";
import arrow from "../../Assets/WebImages/arrow-right.png";
import footerimage1 from "../../Assets/WebImages/rv-9-footer-article-1.jpg";
import footerimage2 from "../../Assets/WebImages/rv-9-footer-article-2.jpg";
import mapcolor from "../../Assets/WebImages/map-marker-altcolor.png";


function VermiCompost() {
    return (
        <div>
            <div className="v_light_purple" data-spy="scroll" data-offset={110}>

                <Header />
                <div className="container-fluid sectionimagecontent1">
                        <div className="row">
                            <div className="col-12 px-0">
                            <div className="mainimage">
                                <img className='w-100 h-100 ' src={servicecard1} alt="" />
                            </div>
                            </div>
                        </div>
                </div>
                <div className="container-fluid sectionimagecontent">
                    <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h4 className='fw-bold text-black'> Benefits of Vermicomposting -</h4>
                            <ul className='listiner'>
                                <li>Soil Health</li>
                                <li>Sustainable Waste Management</li>
                                <li>Agricultural Productivity</li>
                                <li>Environmental Impact</li>
                            </ul>
                            <h4 className='fw-bold text-black'>SCOPE OF VERMICOMPOST IN INDIAN MARKET
                            </h4>
                            <p>With a significant portion of India's population dependent on
                                agriculture, there is a rising demand for sustainable and organic
                                farming practices. Vermicompost enriches the soil, reduces the
                                need for chemical fertilizers, and improves crop yields.
                            </p>
                            <p>
                                With increasing awareness of the health and environmental
                                benefits of organic produce, more farmers are likely to transition
                                to organic farming practices. Vermicompost, being a crucial
                                components of organic farming, will see heightened demand.

                            </p>
                            <h4 className='fw-bold text-black'> Nutrient-Rich -</h4>
                            <ul className='ps-0'>
                                <li>Vermicompost is rich in essential nutrients such as:</li>
                                <li>Organic Carbon 9 -17%</li>
                                <li>Nitrogen  1.5 - 2.1%</li>
                                <li>Phosphorus 1 - 1.5%</li>
                                <li>Potassium 0.15 - 0.5% </li>
                                <li>Calcium 22 me/100g </li>
                                <li>magnesium 70 me/100g</li>
                                <li>Sulphur 128 - 548 me/kg</li>
                                <li>Copper 100 ppm</li>
                                <li>Iron 1800 ppm</li>
                                <li>Zinc 50 ppm</li>
                            </ul>
                            <h4 className="fw-bold text-black">Reduction in Chemical Fertilizer Use :
                            </h4>
                            <p>The nutrients in vermicompost are in forms
                            </p>
                            <p>hat are readily available to plants, leading to healthier and faster plant growth.
                            </p>
                            <h4 className="fw-bold text-black">
                            Carbon Sequestration 

                            </h4>
                            <p>Vermicompost enhances soil organic matter, which helps in
                            carbon sequestration, thus mitigating climate change.</p>
                        </div>
                    </div>
                    </div>
                </div>
                <footer className="purple_dark_bg footer_pattern footermain">
                    <div className="top_footer">
                        <div className="container">
                            <div className="row align-items-center g-3 border-bottom pb-3">
                                <div className="col-md-3 col-sm-6 col-12">
                                    <a href="#" className="logofooter">
                                        <img className="w-100 h-100" src={logo} alt="logo" />
                                    </a>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12">
                                    <div className="footericon_group">
                                        <a href="tel:+919068282716" className="icons">
                                            <img
                                                className="w-100 h-100"
                                                src={phonecolor}
                                                alt="icon"
                                            />
                                        </a>
                                        <div className="footertopsection">
                                            <div className="footerheadingtop text-white fw-bold">
                                                Our Phone
                                            </div>
                                            <a
                                                className="numbertext text-secondary"
                                                href="tel:+911169652119"
                                            >
                                                +911169652119
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12">
                                    <div className="footericon_group">
                                        <a href="mailto:info@jivandhara.org" className="icons">
                                            <img
                                                className="w-100 h-100"
                                                src={emailcolor}
                                                alt="icon"
                                            />
                                        </a>
                                        <div className="footertopsection">
                                            <div className="footerheadingtop text-white fw-bold">
                                                Our Email
                                            </div>
                                            <a
                                                className="numbertext text-secondary"
                                                href="mailto:info@jivandhara.org"
                                            >
                                                info@jivandhara.live
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 col-12 pb-3 pb-sm-0">
                                    <div className="footericon_group">
                                        <div className="icons">
                                            <img className="w-100 h-100" src={mapcolor} alt="icon" />
                                        </div>
                                        <div className="footertopsection">
                                            <div className="footerheadingtop text-white fw-bold">
                                                Our Address
                                            </div>
                                            <div className="numbertext text-secondary">
                                                3C/104, Avas Vikas, <br /> Buddhi Bihar, Moradabad,
                                                <br /> 244001
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 py-4">
                                <div className="col-md-3">
                                    <div className="footerheading">WHAT WE DO</div>
                                    <p className="subtext">
                                        We empowering farmers on vermicompost in India involves various initiatives aimed at providing them with the knowledge, resources, and support needed to adopt and implement vermicomposting practices effectively. Here are some ways farmers are empowered in this regard:
                                    </p>
                                    <ul className="iconlistsocial">
                                        <li>
                                            <a href="">
                                                <FaFacebookF className=" fa fa-facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                <IoLogoTwitter className=" fa fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                <FaInstagram className=" fa fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="">
                                                <FaTelegramPlane className=" fa fa-telegram" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-2">
                                    <div className="footerheading">Our Services</div>
                                    <ul>
                                        <li>
                                            <Link className="linkf" to="/VermiCompost">Vermi Compost</Link>
                                        </li>
                                        <li>
                                            <Link className="linkf" to="/Plantation">Plantation</Link>
                                        </li>
                                        <li>
                                            <Link className="linkf" to="/Kitchengardening">Kitchen gardening</Link>
                                        </li>
                                        <li>
                                            <a href="/">Contract Farming </a>
                                        </li>
                                        <li>
                                            <a href="/">Organic Product </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <div className="footerheading">Recent Posts</div>
                                    <div className="imagetextgroup mb-3">
                                        <div className="imagefooter">
                                            <img
                                                className="w-100 h-100"
                                                src={footerimage1}
                                                alt="imagefooter"
                                            />
                                        </div>
                                        <div className="d-flex gap-1 flex-column">
                                            <div className="text-white datetext">June 16, 2023</div>
                                            <div className="fw-bold text-white querytext">
                                                Ways to Freshen Up Curtains & Drapes Home.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="imagetextgroup mb-3">
                                        <div className="imagefooter">
                                            <img
                                                className="w-100 h-100"
                                                src={footerimage2}
                                                alt="imagefooter"
                                            />
                                        </div>
                                        <div className="d-flex gap-1 flex-column">
                                            <div className="text-white datetext">July 21, 2023</div>
                                            <div className="fw-bold text-white querytext">
                                                Streamlining Your Laundry Routine Through Automation.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="footerheading">Newsletter Signup</div>
                                    <div class="nwsltr-top mb-3 position-absolute">
                                        <input
                                            type="email"
                                            name="email"
                                            id="rv-8-subs-form"
                                            placeholder="Enter your Email..."
                                        />
                                        <button>
                                            <span>
                                                <img
                                                    className="w-100 h-100"
                                                    src={arrow}
                                                    alt="imagearrow"
                                                />
                                            </span>
                                        </button>
                                    </div>
                                    <div class="rv-6-footer-nwsltr__checkbox d-flex align-items-center gap-3">
                                        <input
                                            type="checkbox"
                                            className="w-auto"
                                            id="nwsltr-checkbox"
                                            name="checkbox"
                                            value="1"
                                        />
                                        <label className="mb-0" for="nwsltr-checkbox">
                                            {" "}
                                            I agree to the <a href="#">Privacy Policy</a>.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="footer_bottom">
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <p className="copyright">
                                                    Copyright © 2024 jivandhara.live | All Rights
                                                    Reserved.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

            </div>
        </div>
    )
}

export default VermiCompost