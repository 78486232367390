import React, { useEffect, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import "./TreeView.css";
import UserIcon from "./user_icon.png";
import { Tooltip } from "antd";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { API } from "../../API/Api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const TreeView = () => {

  const [firstTree, setfirstTree] = useState("");
  const [secondtree, setsecondtree] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const getTreeData1 = async () => {
    try {
      let res = await API.post(
        `/LevelTree`,
        {
          uid: userId,
          type: 2,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // console.log("ResTreee", res.data.data[0][0]);
      setfirstTree(res.data.data[0][0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };


  const getTreeData2 = async () => {
    try {
      let res = await API.post(
        `/LevelTree`,
        {
          uid: userId,
          type: 1,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("ResTreee2", res.data.data[0]);
      setsecondtree(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    getTreeData1();
    getTreeData2();
  }, []);


  return (
    <div>
      <DashBoardNav />
      <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
        <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
          Tree View
        </span>
        <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
          Dashboard &gt; Tree
        </span>
      </div>
      <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
        <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
          <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
            <div className="flex-md-50">
              <span className="disp-block color-grad1 text-big font-thik">
                {firstTree.totaldownline}
              </span>
              <span className="disp-block color-gray">Team Network</span>
            </div>
            <div className="flex-md-50 text-right">
              <span className={`disp-block ${firstTree.status === 'Active' ? 'color-green' : 'color-red'} text-big font-thik`}>
                {firstTree.status}
              </span>
              <span className="disp-block color-gray">Team Activation Status</span>
            </div>
          </div>
        </div>
        <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
          <div className="flexbox">
            <div className="flex-md-100 flex-xs-100">
              <div className="flexbox pad-bt-15">
                <div className="flex-md-100">
                  <span className="text-md disp-block color-grad1">
                    Tree view
                  </span>
                  <span className="text-sm disp-inline-block bg-grad1 color-white bdr-rad-full marg-tp-5 pad-tp-5 pad-bt-5 pad-lt-10 pad-rt-10">
                    Nested View
                  </span>
                </div>
              </div>

              {/* ecopower tree start */}
              <div className="card-body p-2">
                    <div className="side-app">
                      <div className="main_top">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                            <div className="card shadow">
                              <div className="card-body p-0">
                                <div className="grid-margin">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="tree_view">
                                        <ul>
                                          <li>
                                            <div
                                              className="tree_view_parent"
                                              onmouseover="bigImg(this)"
                                              onmouseout="normalImg(this)"
                                            >
                                              <img
                                                src={UserIcon}
                                                style={{ height: 70 }}
                                                alt=""
                                              />{" "}
                                              <br />
                                              <Tooltip
                                                title={
                                                  <>
                                                    <div>
                                                      <p className="text-white">
                                                        Name :{" "}
                                                        {firstTree.f_name}
                                                      </p>
                                                      <p className="text-white">
                                                        Referral By :{" "}
                                                        {firstTree.sname}
                                                      </p>
                                                      <p className="text-white">
                                                        Team :{" "}
                                                        {
                                                          firstTree.totaldownline
                                                        }
                                                      </p>
                                                      <p className="text-white">
                                                        Self Business :{" "}
                                                        {firstTree.package}
                                                      </p>
                                                      <p className="text-white">
                                                        Team Business :{" "}
                                                        {firstTree.business}
                                                      </p>
                                                    </div>
                                                  </>
                                                }
                                              >
                                                <button
                                                  type="button"
                                                  className={`btn ${firstTree.btnstatus} btn-sm`}
                                                  data-html="true"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title=""
                                                  data-original-title="Name: Jivandhara<br> Referred By: NA<br> Team Size: 92<br>Self Business: 5000<br> Team Business: 6500"
                                                >
                                                  You
                                                </button>
                                              </Tooltip>
                                              {/* <div
                                                id="txtbussiness"
                                                style={{ fontSize: 13 }}
                                              >
                                                Bussiness:6500
                                              </div> */}
                                            </div>
                                            <ul>
                                              {secondtree.map(
                                                (items, index) => {
                                                  return (
                                                    <>
                                                      <li
                                                        className="tree_element"
                                                        data-id={637975}
                                                        data-show={1}
                                                      >
                                                        <div
                                                          className="tree_view_parent"
                                                          data-id={637975}
                                                        >
                                                          <img
                                                            src={UserIcon}
                                                            alt=""
                                                            style={{
                                                              height: 50,
                                                              width: "auto",
                                                            }}
                                                          />
                                                          <br />
                                                          <Tooltip
                                                            title={
                                                              <>
                                                                <div>
                                                                  <p className="text-white">
                                                                    Name :{" "}
                                                                    {
                                                                      items.f_name
                                                                    }
                                                                  </p>
                                                                  <p className="text-white">
                                                                    Referral By
                                                                    :{" "}
                                                                    {
                                                                      items.sname
                                                                    }
                                                                  </p>
                                                                  <p className="text-white">
                                                                    Team :{" "}
                                                                    {
                                                                      items.totaldownline
                                                                    }
                                                                  </p>
                                                                  <p className="text-white">
                                                                    Self
                                                                    Business :{" "}
                                                                    {
                                                                      items.package
                                                                    }
                                                                  </p>
                                                                  <p className="text-white">
                                                                    Team
                                                                    Business :{" "}
                                                                    {
                                                                      items.business
                                                                    }
                                                                  </p>
                                                                </div>
                                                              </>
                                                            }
                                                          >
                                                            <button
                                                              type="button"
                                                              className={`btn ${items.btnstatus} btn-sm`}
                                                              data-html="true"
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title=""
                                                              data-original-title="Name: Jivandhara<br> Referred By: NA<br> Team Size: 92<br>Self Business: 5000<br> Team Business: 6500"
                                                              onClick={() =>
                                                                getTreeData1()
                                                              }
                                                            >
                                                              {items.uid}
                                                            </button>
                                                          </Tooltip>
                                                        </div>
                                                      </li>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              {/* ecopower tree end */}

              {/* <div className="bg treeview">
                <ul>
                  <li data-content="-">
                    <span>
                      <span>-</span>
                      <span onclick="return fatch_tree(this,`TF780929`);">
                        sumi (TF780929)
                      </span>
                    </span>
                  </li>
                  <li data-content="-">
                    <span>
                      <span>-</span>
                      <span onclick="return fatch_tree(this,`MF507486`);">
                        Rajesh Aggarwal (MF507486)
                      </span>
                    </span>
                  </li>
                  <li data-content="-">
                    <span>
                      <span>-</span>
                      <span onclick="return fatch_tree(this,`MF949597`);">
                        Rj (MF949597)
                      </span>
                    </span>
                    <ul className="inner">
                      <li data-content="+">
                        <span>
                          <span>+</span>
                          <span onclick="return fatch_tree(this,`MF842417`);">
                            RPM (MF842417)
                          </span>
                        </span>
                      </li>
                      <li data-content="-">
                        <span>
                          <span>-</span>
                          <span onclick="return fatch_tree(this,`MF662415`);">
                            Rj (MF662415)
                          </span>
                        </span>
                        <ul className="inner">
                          <li data-content="+">
                            <span>
                              <span>+</span>
                              <span onclick="return fatch_tree(this,`MF531168`);">
                                Rj (MF531168)
                              </span>
                            </span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li data-content="+">
                    <span>
                      <span>+</span>
                      <span onclick="return fatch_tree(this,`WJ937292`);">
                        Priyanka pohar (WJ937292)
                      </span>
                    </span>
                  </li>{" "}
                </ul>
              </div> */}
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreeView;
