import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuth: false,
  userId: "",
  jwt_token: "",
  LoginId: "",
};

export const AuthSlice = createSlice({
  name: "UserAuth",
  initialState,
  reducers: {
    UpdateAuth: (state, action) => {
      state.isAuth = action.payload.isAuth;
      state.userId = action.payload.userId;
      state.jwt_token = action.payload.jwt_token;
      state.LoginId = action.payload.LoginId;
    },
  },
});
export const { UpdateAuth, SetDashboardData } = AuthSlice.actions;
export default AuthSlice.reducer;
