import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import bgimgtwo from "../../Assets/WebImages/IDCardtwobg.png";
import Logo from "../../Assets/WebImages/logo.png";
import "./id.css";
import { API } from "../../API/Api";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";

const IDCardtwo = () => {
  const handlePrint = () => {
    window.print();
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const [userData, setUserData] = useState({});

  const UserInfo_API = async () => {
    try {
      let response = await API.post(
        `getUserDetail_byuserid`,
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const userData = response.data.data[0][0];
      //console.log("userData", userData);
      setUserData(userData);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    UserInfo_API();
  }, []);

  return (
    <>
      <div>
        <div className="container idcardsection">
          <div className="row py-5">
            <div className="col-md-10 mx-auto col-12">
              <div className="idcard">
                <div
                  className="px-4 w-100"
                  style={{
                    backgroundImage: `url(${bgimgtwo})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%",
                  }}
                >
                  <div
                    className="my-2 logomain"
                    style={{
                      width: "330px",
                      height: "170px",
                      margin: "auto",
                    }}
                  >
                    <img className="w-100 h-100" src={Logo} alt="logoes" />
                  </div>
                  <div
                    className="name"
                    style={{
                      fontSize: "40px",
                      fontWeight: "bold",
                      color: "#5C3E1A",
                      textTransform: "uppercase",
                      letterSpacing: "3px",
                      margin: "5px",
                    }}
                  >
                    {userData.f_name}
                  </div>
                  <div
                    className="fw-normal textmain2"
                    style={{
                      fontSize: "30px",
                      letterSpacing: "4px",
                      color: "#5C3E1A",
                      margin: "5px",
                    }}
                  >
                    {userData.rank_name}
                  </div>
                  <div
                    className="fw-normal text-white textmain2"
                    style={{
                      fontSize: "30px",
                      letterSpacing: "4px",
                      margin: "5px",
                    }}
                  >
                    {userData.mobile}
                  </div>
                  <div
                    className="fw-normal text-white lattext"
                    style={{
                      fontSize: "30px",
                      letterSpacing: "4px",
                      margin: "5px",
                    }}
                  >
                    {userData.email}
                  </div>
                  <div className="text-center text-dark">
                    <p
                      className="text-white text-center py-3"
                      href="www.jivandhara.org"
                    >
                      www.jivandhara.org || Head Office- 3C/104, Avas Vikas,
                      Buddhi Bihar, Moradabad,244001{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ textAlign: "center", marginTop: "20px" }}
        className="no-print"
      >
        <button
          className="btn btn-success"
          onClick={handlePrint}
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          Download
        </button>
      </div>
    </>
  );
};
export default IDCardtwo;
