import React, { useEffect, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import Table from "../Table/Table";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../API/Api";
import toast from "react-hot-toast";

const BuyProduct = () => {
  const [data, setData] = useState({});
  const [ActBy, setActBy] = useState("Franchisee");
  const [Fr_list, setFr_list] = useState([]);
  const [Fr_id, setFr_id] = useState(0);
  const [Fr_uid, setFr_uid] = useState(0);
  const [Fr_data, setFr_data] = useState({});

  const [Product_list, setProduct_list] = useState([]);
  const [Product_id, setProduct_id] = useState("");
  const [Product_data, setProduct_data] = useState({});
  const [quantity, setQuantity] = useState();

  const [tableRows, setTableRows] = useState([]);
  const [totalmrp, setTotalMRP] = useState("");
  const [totalbvt, setTotalBVT] = useState("");
  const [totalmrpwithtax, settotalmrpwithtax] = useState("");
  const [totalProductPrice, setTotalProductPrice] = useState();
  const [totalProductPriceWTax, setTotalProductPriceWTax] = useState();
  const [totalBV, setTotalBV] = useState();
  const [totalPayable, setTotalPayable] = useState();

  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ResetData = () => {
    setActBy("");
    setFr_list([]);
    setFr_id(0);
    setFr_uid(0);
    setFr_data({});

    setProduct_list([]);
    setProduct_id("");
    setProduct_data({});
    setQuantity();

    setTableRows([]);
    setTotalMRP("");
    setTotalBVT("");
    setTotalProductPrice();
    setTotalProductPriceWTax();
    setTotalBV();
    setTotalPayable();

    Dashboard_API();
  };

  const Dashboard_API = async () => {
    try {
      let res = await API.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("API Response", res.data.data[0]);
      setData(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const ActivateBy_API = async (ActBy) => {
    try {
      ResetData();

      if (ActBy === "FUNDS") {
        setActBy(ActBy);
        FranchiseeList_API(ActBy);
      } else if (ActBy === "Franchisee") {
        setActBy(ActBy);
        FranchiseeList_API(ActBy);
      }
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const FranchiseeList_API = async (ActBy) => {
    try {
      setFr_list([]);
      setFr_id(0);
      setFr_uid(0);
      setFr_data({});

      setProduct_list([]);
      setProduct_id("");
      setProduct_data({});
      setQuantity();

      setTableRows([]);
      setTotalMRP("");
      setTotalBVT("");
      setTotalProductPrice();
      setTotalProductPriceWTax();
      setTotalBV();
      setTotalPayable();

      let Action = "1";
      if (ActBy === "FUNDS") {
        Action = "11";
      } else if (ActBy === "Franchisee") {
        Action = "1";
      }

      let res = await API.get(
        `GetfrenchiseProductListuserQty?uid=${userId}&action=${Action}&id=0`,
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setFr_list(res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const FranchiseeDetail_API = async (frenid) => {
    try {
      setFr_id(0);
      setFr_uid(0);
      setFr_data({});

      setProduct_list([]);
      setProduct_id("");
      setProduct_data({});
      setQuantity();

      setTableRows([]);
      setTotalMRP("");
      setTotalBVT("");
      setTotalProductPrice();
      setTotalProductPriceWTax();
      setTotalBV();
      setTotalPayable();

      setFr_id(frenid);
      let res = await API.get(
        `GetfrenchiseProductListuserQty?uid=${userId}&action=2&id=${frenid}`,
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setFr_data(res.data.data[0]);

      let fuid = res.data.data[0][0].uid;
      setFr_uid(res.data.data[0][0].uid);
      let resProduct = await API.get(
        `GetfrenchiseProductListuserQty?uid=${userId}&action=8&id=${fuid}`,
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setProduct_list(resProduct.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const ProductDetail_API = async (Prod_id) => {
    try {
      setProduct_id("");
      setProduct_data({});
      // setQuantity(0);

      // setTableRows([]);
      // setTotalMRP("");
      // setTotalBVT("");
      // setTotalProductPrice();
      // setTotalBV();
      // setTotalPayable();

      setProduct_id(Prod_id);
      let res = await API.get(
        `GetfrenchiseProductListuserQty?uid=${userId}&action=9&id=${Prod_id}&brid=${Fr_uid}`,
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setProduct_data(res.data.data[0]);
      console.log("setProduct_data-->", res.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    ResetData();
    FranchiseeList_API();
  }, []);

  const handleRemoveButtonClick = (index) => {
    setTableRows((prevRows) => {
      const updatedRows = prevRows.filter((_, i) => i !== index);
      calculateTotalProductPrice(updatedRows);
      calculateTotalProductPricewtax(updatedRows);
      calculateTotalBV(updatedRows);
      calculateTotalPayable(updatedRows);
      return updatedRows;
    });
  };

  const calculateTotalAmount = () => {
    const price = parseFloat(Product_data[0]?.product_price) || 0;
    const qty = parseInt(quantity) || 0;
    return (price * qty).toFixed(2);
  };
  const calculateTotalAmountwtax = () => {
    const price = parseFloat(Product_data[0]?.MRP) || 0;
    const qty = parseInt(quantity) || 0;
    return (price * qty).toFixed(2);
  };

  const calculateTotalBVT = () => {
    const bv = parseFloat(Product_data[0]?.bv) || 0;
    const qty = parseInt(quantity) || 0;
    return bv * qty;
  };

  const calculateTotalProductPrice = (rows) => {
    const totalPrice = rows.reduce((total, row) => {
      const amount = parseFloat(row.totalAmount) || 0;
      return total + amount;
    }, 0);

    setTotalProductPrice(totalPrice.toFixed(2));
  };

  const calculateTotalProductPricewtax = (rows) => {
    const totalPrice = rows.reduce((total, row) => {
      const amount = parseFloat(row.totalAmountwtax) || 0;
      return total + amount;
    }, 0);

    setTotalProductPriceWTax(totalPrice.toFixed(2));
  };

  const calculateTotalBV = (rows) => {
    setTotalBV(0);
    const totalBVAmount = rows.reduce((total, row) => {
      const bv = parseFloat(row.bv) || 0;
      return total + bv;
    }, 0);

    setTotalBV(totalBVAmount);
  };

  const calculateTotalPayable = (rows) => {
    const totalPayableAmount = rows.reduce((total, row) => {
      const amount = parseFloat(row.totalAmount) || 0;
      return total + amount;
    }, 0);

    setTotalPayable(totalPayableAmount.toFixed(2));
  };

  const handleAddButtonClick = () => {
    if (!quantity || quantity <= 0) {
      toast.error("Please Fill Details");
      return;
    }

    if (Fr_uid !== "225588") {
      if (Product_data[0]?.Balance < quantity) {
        toast.error("Stock Not Avaialable");
        return;
      }
    }

    const newRow = {
      franchiseId: Fr_uid,
      franchiseName: Fr_data[0]?.uid,
      franchiseMobile: Fr_data[0]?.contact,
      franchiseEmail: Fr_data[0]?.email,
      productId: Product_id,
      productName: Product_data[0]?.ProductName,
      productCode: Product_data[0]?.pcode,
      productPrice: Product_data[0]?.product_price,
      taxRate: Product_data[0]?.taxrate,
      taxAmount: parseFloat(Product_data[0]?.tax_amount) * parseFloat(quantity),
      productPricewith: Product_data[0]?.MRP,
      bv: calculateTotalBVT(),
      quantity: quantity,
      totalAmount: calculateTotalAmount(),
      totalAmountwtax: calculateTotalAmountwtax(),
    };

    setTotalMRP(calculateTotalAmount());
    setTotalBVT(calculateTotalBVT());
    settotalmrpwithtax(calculateTotalAmountwtax());

    setTableRows((prevRows) => {
      const updatedRows = [...prevRows, newRow];
      calculateTotalProductPrice(updatedRows);
      calculateTotalProductPricewtax(updatedRows);
      calculateTotalBV(updatedRows);
      calculateTotalPayable(updatedRows);
      return updatedRows;
    });
    toast.success("Product Added successfully!");
    setQuantity("");
    setProduct_data([]);
    setProduct_id("");
  };

  var [pendingWithdraw, setpendingWithdrawApi] = new useState({
    cols: [
      {
        Header: "Row",
        accessor: "serialNumber",
        Cell: ({ row }) => row.index + 1,
      },
      { Header: "Code", accessor: "productCode" },
      { Header: "Product Name", accessor: "productName" },
      { Header: "Product Price (Inc. Tax)", accessor: "productPricewith" },
      { Header: "Quantity", accessor: "quantity" },
      { Header: "Total Price", accessor: "totalAmountwtax" },
      { Header: "Total BV", accessor: "bv" },
      {
        Header: "Remove",
        accessor: "remove",
        Cell: ({ row }) => (
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() => handleRemoveButtonClick(row.index)}
          >
            Remove
          </button>
        ),
      },
    ],
  });

  const handleSubmitButtonClick = async () => {
    let OrderNO = "";
    calculateTotalProductPrice(tableRows);
    calculateTotalProductPricewtax(tableRows);
    calculateTotalBV(tableRows);
    calculateTotalPayable(tableRows);

    try {
      if (ActBy === "FUNDS") {
        if (
          parseFloat(totalProductPriceWTax) >=
          parseFloat(data[0]?.Net_AdminFund)
        ) {
          toast.error("Insufficient Admin Fund !!!");
          return;
        }
      }

      const response1 = await API.get(`/getrandomorderid?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      OrderNO = response1.data.data[0][0].OrderNo;

      const Product = [];
      tableRows.forEach((newRow) => {
        for (let i = 0; i < 1; i++) {
          Product.push({
            uid: userId,
            Brid: Fr_uid,
            orderno: OrderNO,
            pcode: newRow.productId,
            Pname: newRow.productName,
            totalqty: newRow.quantity,
            TotalMRP: newRow.totalAmount,
            taxrate: newRow.taxRate,
            TotalTaxAmount: newRow.taxAmount,
            OrderType: ActBy,
            TotalBV: newRow.bv,
            //,TotalMRPWTax: newRow.totalAmountwtax,
          });
        }
      });

      console.log("Product Array Before API Call: ", Product);
      let response;
      for (let i = 0; i < Product.length; i++) {
        response = await API.post(`/BUYACTProduct_New`, Product[i], {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        });
      }

      console.log("API response: ", response.data.data);
      toast.success(response.data.data.result);
      ResetData();
    } catch (error) {
      console.error("Error submitting handleSubmitButtonClick", error);
    }
  };

  return (
    <div>
      <DashBoardNav />
      <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
        <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
          Buy Product
        </span>
        <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
          Dashboard &gt; Buy Product
        </span>
      </div>
      <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
        <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
          <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
            <div className="panel panel-primary col-lg-12">
              <div className="panel-heading">
                <h4 className="panel-title">Buy Product</h4>
              </div>
              <div className="panel-body">
                <div className="form-horizontal table-responsive">
                  <div id="divProductDetails" className="panel-primary">
                    <div className="product_field">
                      {/* <div className="row"> */}
                      {/* <div className="col-md-4">
                        <span>Activation By</span>
                        <select
                          className="form-control"
                          id="ActivationBy"
                          name="ActivationBy"
                          onChange={(e) => ActivateBy_API(e.target.value)}
                        >
                          <option value="">Select Activation Type</option>
                          <option value="FUNDS">By Admin Fund</option>
                          <option value="Franchisee">By Franchisee</option>
                        </select>
                      </div> */}
                      {ActBy === "FUNDS" ? (
                        <>
                          <div className="col-md-4">
                            <span>Admin Fund</span>
                            <input
                              className="form-control"
                              disabled="True"
                              id="adminfund"
                              name="adminfund"
                              type="text"
                              Value={`Admin Fund (${
                                "₹ " +
                                  parseFloat(data[0]?.Net_AdminFund).toFixed(
                                    2
                                  ) || "$ 0.00"
                              })`}
                            />
                          </div>
                        </>
                      ) : (
                        <>{""}</>
                      )}
                      <div className="col-md-4">
                        <span>Select Franchise</span>
                        <select
                          className="form-control"
                          id="franchiseId"
                          name="franchiseId"
                          onChange={(e) => FranchiseeDetail_API(e.target.value)}
                        >
                          <option value="">Select Franchise</option>
                          {Fr_list?.map((ele, index) => (
                            <option value={ele.id}>{ele.fname}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-4">
                        <span>Franchise id</span>
                        <input
                          className="form-control"
                          disabled="True"
                          id="franchiseName"
                          name="franchiseName"
                          type="text"
                          Value={Fr_data[0]?.uid}
                        />
                      </div>
                      <div className="col-md-4">
                        <span>Franchise Mobile</span>
                        <input
                          className="form-control"
                          disabled="True"
                          id="franchiseMobile"
                          name="franchiseMobile"
                          type="text"
                          Value={Fr_data[0]?.contact}
                        />
                      </div>
                      <div className="col-md-4">
                        <span>Franchise Email</span>
                        <input
                          className="form-control"
                          disabled="True"
                          id="franchiseEmail"
                          name="franchiseEmail"
                          type="text"
                          Value={Fr_data[0]?.email}
                        />
                      </div>
                      <div className="col-md-4">
                        <span>Select Product</span>
                        <select
                          className="form-control"
                          id="pcode"
                          name="pcode"
                          onChange={(e) => {
                            ProductDetail_API(e.target.value);
                          }}
                        >
                          <option value="">Select Product</option>
                          {Product_list?.map((ele, index) => (
                            <option value={ele.pcode}>{ele.pname}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-4">
                        <span>Product Name</span>
                        <input
                          className="form-control"
                          disabled="True"
                          id="productname"
                          name="productname"
                          type="text"
                          Value={Product_data[0]?.ProductName}
                        />
                      </div>
                      <div className="col-md-4">
                        <span>Product Price</span>
                        <input
                          className="form-control"
                          disabled="True"
                          id="mrp"
                          name="mrp"
                          type="text"
                          Value={Product_data[0]?.product_price}
                        />
                      </div>
                      <div className="col-md-4">
                        <span>Tax Rate(%)</span>
                        <input
                          className="form-control"
                          disabled="True"
                          id="taxrate"
                          name="taxrate"
                          type="text"
                          Value={Product_data[0]?.taxrate}
                        />
                      </div>
                      <div className="col-md-4">
                        <span>Tax Amount</span>
                        <input
                          className="form-control"
                          disabled="True"
                          id="taxamount"
                          name="taxamount"
                          type="text"
                          Value={Product_data[0]?.tax_amount}
                        />
                      </div>
                      <div className="col-md-4">
                        <span>Product Price(With Tax)</span>
                        <input
                          className="form-control"
                          disabled="True"
                          type="text"
                          Value={Product_data[0]?.MRP}
                        />
                      </div>
                      <div className="col-md-4">
                        <span>Product BV</span>
                        <input
                          className="form-control"
                          disabled="True"
                          type="text"
                          Value={Product_data[0]?.bv}
                        />
                      </div>
                      {Fr_uid !== "224466" ? (
                        <>
                          <div className="col-md-4">
                            <span>Available QTY</span>
                            <input
                              className="form-control"
                              disabled="True"
                              id="avlqty"
                              name="avlqty"
                              type="text"
                              Value={Product_data[0]?.Balance}
                            />
                          </div>
                        </>
                      ) : (
                        <>{""}</>
                      )}
                      <div className="col-md-4">
                        <span>Quantity</span>
                        <input
                          className="form-control"
                          id="totalqty"
                          name="totalqty"
                          // oninput="numbervalidation(this)"
                          type="number"
                          // defaultValue=""
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-12" style={{ marginTop: 20 }}>
                        <div
                          className="add_btn"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            id="btnAdd"
                            type="button"
                            className="btn btn-success"
                            onClick={handleAddButtonClick}
                            disabled={
                              !quantity ||
                              quantity <= 0 ||
                              !Product_data[0]?.product_price
                            }
                          >
                            ADD
                          </button>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-20 pad-rt-20 bg-white bdr-rad-md">
          <div className="row">
            <div className="col-md-12">
              <Table data={tableRows} columns={pendingWithdraw.cols} />
            </div>
            <div className="col-md-3">
              <span>Total Product BV</span>
              <input
                className="form-control"
                disabled="True"
                id="totalmrp"
                name="totalmrp"
                type="text"
                Value={totalBV}
              />
            </div>

            <div className="col-md-3">
              <span>Total Product Price</span>
              <input
                className="form-control"
                disabled="True"
                id="totalmrp"
                name="totalmrp"
                type="text"
                Value={totalProductPrice}
              />
            </div>

            <div className="col-md-3">
              <span>Total Payable (Inc. Tax)</span>
              <input
                className="form-control"
                disabled="True"
                id="payableamount"
                name="payableamount"
                type="text"
                Value={totalProductPriceWTax}
              />
            </div>

            <div className="col-md-3 add_btn hxnaddBtn">
              <button
                type="button"
                id="btnsave"
                className="btn btn-success"
                onClick={handleSubmitButtonClick}
                disabled={!totalProductPrice || totalProductPrice <= 0}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyProduct;
