import React, { useEffect } from "react";
import "./WelcomePage.css";
import { useState } from "react";
import { API } from "../../API/Api";

export default function WelcomePage() {
  const [data, setData] = useState({});

  const LoninApi = async () => {
    try {
      let URL = window.location.href;
      let URL_ID = new URLSearchParams(window.location.search).get("userid");

      let res = await API.get(`WelcomeDetails?uid=${URL_ID}`);
      setData(res.data.data[0]);

      if (res != undefined) {
        setData(res.data.data[0]);
      } else {
        LoninApi();
      }
    } catch (e) {
      console.log("WelcomPage Api", e);
    }
  };

  useEffect(() => {
    LoninApi();
  }, []);

  return (
    <>
      <div className="wrapper_main">
        <div className="wrapper">
          <div className="main1">
            <div className="main">
              <h2 style={{ textAlign: "center", color: "red" }}>
                WELCOME TO JIVANDHARA
              </h2>
              <h3 style={{ textAlign: "center", color: "#ff8400" }}>
                WELCOME LETTER
              </h3>
              <h4>
                TO Mr./ Mrs./ Miss,{" "}
                <sapn style={{ color: "red" }}>{data[0]?.f_name} </sapn>{" "}
              </h4>
              <p style={{ color: "white" }}>
                THANK YOU FOR THE REGISTRATION WITH JIVANDHARA YOUR CORDIAL
                ASSOCIATION IS WELCOME IN THE BUSINESS FAMILY WISH YOU BRIGHT
                FUTURE FOR GROWTH.
              </p>
              <br />
              <h5 style={{ color: "red" }}>NAME : {data[0]?.f_name} </h5>
              <h5 style={{ color: "red" }}>USER ID : {data[0]?.Username}</h5>
              <h5 style={{ color: "red" }}>PASSWORD : {data[0]?.passwd}</h5>
              <h5 style={{ color: "red" }}>EMAIL : {data[0]?.email} </h5>
              <h5 style={{ color: "red" }}>MOBILE : {data[0]?.mobile} </h5>
              <br />
              <p style={{ width: "100%", float: "left", textAlign: "center" }}>
                <a
                  href="/login"
                  type="button"
                  style={{
                    color: "#0d0d0d",
                    textDecoration: "none",
                    backgroundColor: "#fedc00",
                    borderRadius: 10,
                    padding: "6px 14px",
                  }}
                >
                  Go To Login
                </a>
              </p>
            </div>
            {/*-main ends-*/}
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
}
