import React, { useEffect, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import TableButton from "../TableButton/TableButton";
import Table from "../Table/Table";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { API } from "../../API/Api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Hipayteam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [levelIncomeApi, setLevelIncomeApi] = useState([]);
  const [totalSelfBV, settotalSelfBV] = useState(0);
  const [totalTeamBV, settotalTeamBV] = useState(0);
  const [totalBV, settotalBV] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [totalIncome, setTotalIncome] = useState();

  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const LevelIncome_API = async (fromDate, toDate) => {
    try {
      let res = await API.post(
        `directLegBusiness`,
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // if (res.data.data && res.data.data.totalIncome) {
      //   setTotalIncome(res.data.data.totalIncome);
      // }

      console.log("level income", res);
      let arr = [];
      let totslfbv = 0;
      let tottmbv = 0;
      let totbv = 0;
      res.data.data[0].forEach((item, index) => {
        arr.push({
          amount: parseFloat(item.income).toFixed(2),
          RowNumber: item.row,
          fid: item.uid,
          Username: item.Username,
          des: item.f_name,
          remark: item.SelfBusiness,
          cdate: item.TeamBusiness,
          onamount: item.TotalBusiness,
        });
        totslfbv += item.SelfBusiness;
        tottmbv += item.TeamBusiness;
        totbv += item.TotalBusiness;
      });
      settotalSelfBV(totslfbv);
      settotalTeamBV(tottmbv);
      settotalBV(totbv);
      setLevelIncomeApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const handleSearch = () => {
    const fromDate = document.getElementById("fdate").value;
    const toDate = document.getElementById("tdate").value;
    LevelIncome_API(fromDate, toDate);
  };

  useEffect(() => {
    LevelIncome_API();
  }, []);

  // eslint-disable-next-line no-unused-vars
  var [myTeam, setMyTeam] = new useState({
    cols: [
      { Header: "S.No.", accessor: "RowNumber" },
      { Header: "User Id", accessor: "Username" },
      { Header: "Name", accessor: "des" },
      { Header: "Self BV", accessor: "remark" },
      { Header: "Team BV", accessor: "cdate" },
      { Header: "Total BV", accessor: "onamount" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = levelIncomeApi.slice(indexOfFirstPage, indexOfLastPost);

  return (
    <div>
      <DashBoardNav />
      <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
        <span className="pad-tp-20 bg marg-tp-50 text-nowrap reText text-xl color-white">
          Direct Leg BV
        </span>
        <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
          Dashboard &gt; Team
        </span>
      </div>
      <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
        {/* <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
          <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
            <div className="flex-md-50">
              <span className="disp-block color-grad1 text-big font-thik">
                17
              </span>
              <span className="disp-block color-gray">Team Network</span>
            </div>
            <div className="flex-md-50 text-right">
              <span className="disp-block color-grad1 text-big font-thik">
                0
              </span>
              <span className="disp-block color-gray">Team Activation</span>
            </div>
          </div>
        </div> */}
        <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
          <div className="bg text-center pad-tp-20 pad-lt-5 pad-rt-10">
            <span className="text-md font-bold color-grad1 float-left">
              Direct Leg BV
            </span>
          </div>
          <br />

          <br />
          <div className="card-body p-2">
            <div className="table-responsive">
              <div
                // style={{ maxHeight: 400, overflow: "scroll" }}
                id="example_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <label>
                  <span style={{ "margin-left": "35px" }}>
                    Total Self BV : {totalSelfBV},
                  </span>
                  <span style={{ "margin-left": "15px" }}>
                    {" "}
                    Total Team BV : {totalTeamBV},
                  </span>
                  <span style={{ "margin-left": "15px" }}>
                    Total BV : {totalBV}
                  </span>
                </label>
              </div>
              {/* table */}
              <Table data={[...currentPost]} columns={myTeam.cols} />
              <div className="container">
                <TableButton
                  indexOfFirstPage={indexOfFirstPage}
                  indexOfLastPost={indexOfLastPost}
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={levelIncomeApi.length}
                  listPerpage={listPerpage}
                />
              </div>
            </div>
          </div>

          {/* <div className="flexbox tr bg-purple marg-bt-10">
            <div className="flex-md-40 color-white">User</div>
            <div className="flex-md-60 color-white">Business</div>
          </div>
          <div className="bg pad-tp-10 pad-bt-20 marg-bt-20">
            <span className="text-big disp-block marg-bt-5">
              No Record Saved
            </span>
            <span className="text-st color-gray disp-block">
              Invite your friends to join our network.
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Hipayteam;
