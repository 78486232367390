import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../API/Api";
import { useNavigate } from "react-router-dom";
import { UpdateAuth } from "../../Redux/AuthSlice";
const styles = {
  display: "block",
  listStyleType: "disc",
  marginBlockStart: "1em",
  marginBlockEnd: "1em",
  marginInlineStart: "0px",
  marginInlineEnd: "0px",
  paddingInlineStart: "40px",
  unicodeBidi: "isolate",
};
function MakeInvoice() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const [OrderDetails, setOrderDetails] = useState([]);
  const [OrderId, setOrderId] = useState("");

  const OrderDetail_API = async () => {
    let URL_ID = new URLSearchParams(window.location.search).get("id");
    setOrderId(URL_ID);
    try {
      let resProduct = await API.post(
        `activationOrderDtails`,
        {
          uid: userId,
          id: URL_ID,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("OrderData-->", resProduct.data.data[0][0]);
      setOrderDetails(resProduct.data.data[0][0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    OrderDetail_API();
  }, []);

  return (
    <div>
      <div className="content-page mx-auto">
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div id="main-content">
                  <div id="InvoiceView">
                    <div style={{ width: "780px", margin: "0 auto" }}>
                      <div
                        style={{
                          float: "left",
                          width: "95%",
                          marginTop: "0px",
                          paddingBottom: "20px",
                        }}
                      >
                        <div style={{ clear: "both" }}></div>
                        <br></br>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="">
                              <h4 className="text-black">
                                <b>{OrderDetails.f_name}</b>
                              </h4>
                              <h6 className="text-black">
                                <b>Mobile:-</b>
                                <span> {OrderDetails.mobile}</span>
                              </h6>

                              <h6 className="text-black">
                                <b>Email:-</b>
                                <span> {OrderDetails.email}</span>
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="text-right">
                              <h1 className="text-black mt-3">Jivan Dhara</h1>
                            </div>
                          </div>
                        </div>

                        <table
                          cellspacing="0"
                          cellpadding="2"
                          rules="all"
                          border="1"
                          style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            float: "left",
                            borderLeft: "none",
                            borderRight: "none",
                            fontSize: "13px",
                            color: "black",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          <tbody>
                            <tr>
                              <th
                                className="re"
                                style={{ border: "1px solid black" }}
                                scope="col"
                              >
                                <h6 className="text-black">
                                  <b>Discription</b>
                                </h6>
                                <br></br>
                                <h6 className="text-black">
                                  Activation on {OrderDetails.dd1}
                                </h6>
                              </th>
                              <th
                                style={{ border: "1px solid black" }}
                                scope="col"
                                className="re auto-style1"
                              >
                                <h6 className="text-black">
                                  <b>Package Amount</b>
                                </h6>
                                <br></br>
                                <h6 className="text-black">
                                  ₹ {OrderDetails.Amount}.00/-{" "}
                                </h6>
                              </th>
                            </tr>
                            <tr>
                              <td
                                className="ree"
                                style={{ border: "1px solid black" }}
                                valign="top"
                              >
                                <h6 className="text-black text-right mt-1">
                                  <b>Total Amonut</b>
                                </h6>
                              </td>
                              <td
                                style={{ border: "1px solid black" }}
                                valign="top"
                                className=" re auto-style1"
                              >
                                <h6 className="text-black mt-1">
                                  ₹ {OrderDetails.Amount}.00/-{" "}
                                </h6>
                              </td>
                            </tr>

                            <tr>
                              <td
                                className="ree"
                                style={{ border: "1px solid black" }}
                                valign="top"
                              >
                                <h3 className="text-black text-right mt-1">
                                  <b>Total </b>
                                </h3>
                              </td>
                              <td
                                style={{ border: "1px solid black" }}
                                valign="top"
                                className=" re auto-style1"
                              >
                                <h3 className="text-black mt-1">
                                  <b>₹ {OrderDetails.Amount}.00/- </b>
                                </h3>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="row">
                          <div className="col-md-6 mt-20">
                            <div className="">
                              <h6 className="text-black">
                                <b>Date:-</b>
                                <span> {OrderDetails.dd2}</span>
                                <br></br>
                                <br></br>
                                <b>Phone No:-</b>
                                <span> 01169652119</span>
                                <br></br>
                                <br></br>
                                <b>Email:-</b>
                                <span> info@jivandhara.live</span>
                                <br></br>
                                <br></br>
                                <b>Address:-</b>
                                <span>
                                  {" "}
                                  3C/104, Avas Vikas,Buddhi Bihar,
                                  Moradabad,244001
                                </span>
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-6 mt-20">
                            <div className="text-right mt-20">
                              <h6 className="text-black mt-20">
                                <b>REGARDS,</b>
                              </h6>
                              <br></br>
                              <h6 className="text-black">
                                <b> JIVANDHARA </b>
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="invoiceprint">
                            <center>
                              <input
                                type="button"
                                style={{ background: "green" }}
                                className="btn text-white shadow-none w-auto"
                                id="btn"
                                value="Print"
                                onClick={() => window.print()}
                              />
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </div>

                  <div id="InvoiceView" style={{ display: "none" }}>
                    <div style={{ width: "780px", margin: "0 auto" }}>
                      <div
                        style={{
                          float: "left",
                          width: "95%",
                          marginTop: "0px",
                          paddingBottom: "20px",
                        }}
                      >
                        <div style={{ clear: "both" }}></div>
                        <label
                          className="text-black"
                          style={{
                            backgroundColor: "transparent",
                            width: "100%",
                            fontFamily: "Roboto, sans-serif",
                            textAlign: "center",
                            margin: "5px 6px 5px 0px",
                            height: "10px",
                          }}
                        >
                          <b style={{ fontSize: "25px" }}>
                            JIVANDHARA GROWING (OPC) PRIVATE LIMITED
                          </b>
                        </label>
                        <span
                          style={{
                            width: "100%",
                            float: "left",
                            textAlign: "center",
                          }}
                        >
                          <p
                            style={{
                              margin: "0px",
                              marginBottom: "7px",
                              marginTop: "0px",
                            }}
                          >
                            <span style={{ fontSize: "14px", color: "black" }}>
                              <b>Corporate Office:</b> 3C/104, Avas Vikas,
                              Buddhi Bihar, Moradabad,244001
                            </span>
                          </p>
                        </span>
                        <table
                          width="100%"
                          className="ctable dtable"
                          style={{
                            fontFamily: "Roboto, sans-serif",
                            border: "solid 1px #333",
                            borderCollapse: "collapse",
                            fontSize: "13px",
                            marginLeft: "0px",
                          }}
                        >
                          <tbody>
                            <tr className="th">
                              <td
                                colSpan="2"
                                className="tb1"
                                style={{
                                  border: "solid 1px #333",
                                  width: "352px",
                                }}
                              >
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>
                                      <td
                                        width="354"
                                        valign="top"
                                        className="tb1"
                                        style={{ padding: "0px" }}
                                      >
                                        <table
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            color: "black",
                                            fontSize: "13px",
                                            lineHeight: "11px",
                                          }}
                                        >
                                          <tbody>
                                            <tr className="th">
                                              <td
                                                className="tb1"
                                                style={{
                                                  lineHeight: "25px !important",
                                                  borderBottom: "none",
                                                }}
                                              >
                                                <strong
                                                  style={{ color: "black" }}
                                                >
                                                  Sold To
                                                </strong>
                                              </td>
                                              <td>:</td>
                                              <td
                                                className="tb1"
                                                style={{
                                                  lineHeight: "25px !important",
                                                  borderBottom: "none",
                                                }}
                                              >
                                                Jivan Dhara
                                              </td>
                                            </tr>
                                            <tr className="th">
                                              <td
                                                className="tb1"
                                                style={{
                                                  lineHeight: "25px !important",
                                                  borderBottom: "none",
                                                }}
                                              >
                                                <strong>Address</strong>
                                              </td>
                                              <td>:</td>
                                              <td
                                                className="tb1"
                                                style={{
                                                  lineHeight: "25px !important",
                                                  borderBottom: "none",
                                                }}
                                              >
                                                <span>, ,</span>
                                              </td>
                                            </tr>
                                            <tr
                                              className="th"
                                              style={{
                                                borderBottom: "none",
                                                lineHeight: "25px !important",
                                              }}
                                            >
                                              <td
                                                className="tb1"
                                                style={{
                                                  lineHeight: "25px !important",
                                                  borderBottom: "none",
                                                }}
                                              >
                                                <strong>Phone</strong>
                                              </td>
                                              <td>:</td>
                                              <td
                                                className="tb1"
                                                style={{ lineHeight: "16px" }}
                                              >
                                                12345678
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td
                                colSpan="2"
                                align="left"
                                valign="top"
                                className="tb1"
                                style={{ border: "solid 1px #333" }}
                              >
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    <tr>
                                      <td
                                        width="354"
                                        valign="top"
                                        className="tb1"
                                        style={{ padding: "0px" }}
                                      >
                                        <table
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            color: "black",
                                            fontSize: "13px",
                                            lineHeight: "11px",
                                          }}
                                        >
                                          <tbody>
                                            <tr className="th">
                                              <td
                                                className="tb1"
                                                style={{
                                                  lineHeight: "25px !important",
                                                  borderBottom: "none",
                                                }}
                                              >
                                                <strong>Invoice No.</strong>
                                              </td>
                                              <td>:</td>
                                              <td
                                                className="tb1"
                                                style={{
                                                  lineHeight: "25px !important",
                                                  borderBottom: "none",
                                                }}
                                              >
                                                1
                                              </td>
                                            </tr>
                                            <tr className="th">
                                              <td
                                                className="tb1"
                                                style={{
                                                  lineHeight: "25px !important",
                                                  borderBottom: "none",
                                                }}
                                              >
                                                <strong>GSTIN No.</strong>
                                              </td>
                                              <td>:</td>
                                              <td
                                                className="tb1"
                                                style={{
                                                  lineHeight: "25px !important",
                                                  borderBottom: "none",
                                                }}
                                              >
                                                07AAICG5455G1ZH
                                              </td>
                                            </tr>
                                            <tr
                                              className="th"
                                              style={{
                                                borderBottom: "none",
                                                lineHeight: "25px !important",
                                              }}
                                            >
                                              <td
                                                className="tb1"
                                                style={{
                                                  lineHeight: "25px !important",
                                                  borderBottom: "none",
                                                }}
                                              >
                                                <strong>Date</strong>
                                              </td>
                                              <td>:</td>
                                              <td
                                                className="tb1"
                                                style={{
                                                  lineHeight: "25px !important",
                                                  borderBottom: "none",
                                                }}
                                              >
                                                <span
                                                  style={{ lineHeight: "16px" }}
                                                >
                                                  04/06/2024, 06:57 PM
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          cellspacing="0"
                          cellpadding="2"
                          rules="all"
                          border="1"
                          style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            float: "left",
                            borderLeft: "none",
                            borderRight: "none",
                            fontSize: "13px",
                            color: "black",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          <tbody>
                            <tr>
                              <th
                                style={{ border: "1px solid black" }}
                                scope="col"
                              >
                                S.No.
                              </th>
                              <th
                                style={{ border: "1px solid black" }}
                                scope="col"
                                className="auto-style1"
                              >
                                BV
                              </th>
                              <th
                                style={{ border: "1px solid black" }}
                                scope="col"
                              >
                                HSN Code.
                              </th>
                              <th
                                style={{ border: "1px solid black" }}
                                scope="col"
                              >
                                QTY
                              </th>
                              <th
                                style={{ border: "1px solid black" }}
                                scope="col"
                              >
                                Rate
                              </th>
                              <th
                                style={{ border: "1px solid black" }}
                                scope="col"
                              >
                                Amount
                              </th>
                            </tr>
                            <tr style={{ height: "200px" }}>
                              <td
                                style={{ border: "1px solid black" }}
                                valign="top"
                              >
                                1
                              </td>
                              <td
                                style={{ border: "1px solid black" }}
                                valign="top"
                                className="auto-style1"
                              >
                                {" "}
                                {OrderDetails.bv}
                              </td>
                              <td
                                style={{ border: "1px solid black" }}
                                valign="top"
                              >
                                {" "}
                                {OrderDetails.hsncode}
                              </td>
                              <td
                                style={{ border: "1px solid black" }}
                                valign="top"
                              >
                                1
                              </td>
                              <td
                                style={{ border: "1px solid black" }}
                                valign="top"
                              >
                                {" "}
                                {OrderDetails.Amount}
                              </td>
                              <td
                                style={{ border: "1px solid black" }}
                                valign="top"
                              >
                                {OrderDetails.Amount}
                              </td>
                            </tr>
                            <tr style={{ border: "1px solid black" }}>
                              <td
                                colSpan="2"
                                rowSpan="4"
                                style={{
                                  fontWeight: 600,
                                  fontSize: "20px",
                                  border: "1px solid black",
                                }}
                              >
                                Total Amount: {OrderDetails.Amount}
                              </td>
                              <td
                                style={{ border: "1px solid black" }}
                                colSpan="3"
                              >
                                Cartage
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                0.00
                              </td>
                            </tr>
                            <tr style={{ border: "1px solid black" }}>
                              <td
                                style={{ border: "1px solid black" }}
                                colSpan="3"
                              >
                                Less Discount
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                0.00
                              </td>
                            </tr>
                            <tr style={{ border: "1px solid black" }}>
                              <td
                                style={{ border: "1px solid black" }}
                                colSpan="3"
                              >
                                Total Value
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {OrderDetails.Amount}
                              </td>
                            </tr>
                            <tr style={{ border: "1px solid black" }}></tr>
                            <tr style={{ border: "1px solid black" }}>
                              <td
                                style={{ border: "1px solid black" }}
                                colSpan="2"
                                rowSpan="4"
                              >
                                <ul style={styles}>
                                  <b>Declaration:</b>
                                  <li
                                    style={{
                                      listStyle: "unset",
                                      fontSize: "14px",
                                      lineHeight: "20px",
                                    }}
                                  >
                                    No money or product will be refunded once
                                    purchased and courier charges will be extra.
                                  </li>
                                  <li
                                    style={{
                                      listStyle: "unset",
                                      fontSize: "14px",
                                      lineHeight: "20px",
                                    }}
                                  >
                                    The company does not give any guarantee of
                                    interest rate and doubling at any cost.
                                  </li>
                                  <li
                                    style={{
                                      listStyle: "unset",
                                      fontSize: "14px",
                                      lineHeight: "20px",
                                    }}
                                  >
                                    We declare that this invoice shows the
                                    actual price of the goods described and that
                                    all particular are true and correct.
                                  </li>
                                  <li
                                    style={{
                                      listStyle: "unset",
                                      fontSize: "14px",
                                      lineHeight: "20px",
                                    }}
                                  >
                                    Subject to Noida jurisdiction only.
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            <tr style={{ border: "1px solid black" }}></tr>
                            <tr style={{ border: "1px solid black" }}>
                              <td
                                style={{ border: "1px solid black" }}
                                colSpan="3"
                              >
                                Total Taxable Amount
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {OrderDetails.Amount}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          cellspacing="0"
                          cellpadding="2"
                          rules="all"
                          border="1"
                          style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            float: "left",
                            borderLeft: "none",
                            borderRight: "none",
                            fontSize: "13px",
                            color: "black",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          <tbody>
                            <tr style={{ border: "1px solid black" }}>
                              <td
                                style={{ border: "1px solid black" }}
                                className="auto-style4"
                              >
                                FOR JIVANDHARA GROWING (OPC) PRIVATE LIMITED
                                <br />
                                <br />
                                This is a computer generated invoice hence
                                signature not required.
                                {/* <span style={{ float: 'right' }}><b>Email:</b> info@jivandhara.org</span> */}
                                <br />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                          style={{
                            borderLeft: "solid 1px #333",
                            width: "100%",
                            marginBottom: "-4px",
                            float: "left",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                  <div className="col-md-12" style={{ display: "none" }}>
                    <div className="invoiceprint">
                      <center>
                        <input
                          type="button"
                          style={{ background: "green" }}
                          className="btn text-white shadow-none w-auto"
                          id="btn"
                          value="Print"
                          onClick={() => window.print()}
                        />
                      </center>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MakeInvoice;
