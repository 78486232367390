import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { API } from "../../API/Api";
import sl3 from "../../Assets/WebImages/sl3.jpeg";
import sl1 from "../../Assets/WebImages/sl1.jpeg";
import sl2 from "../../Assets/WebImages/sl2.jpeg";
import sl4 from "../../Assets/WebImages/sl4.jpeg";
import { Carousel } from "antd";

export default function ForgetPassword() {
  const history = useNavigate();
  const [LoginId, setLoginId] = useState("");
  const [PasswordId, setPasswordId] = useState("");
  const [showPassword, setshowPassword] = useState(0);
  const dispatch = useDispatch();

  const ForgetpassToApi = async (e) => {
    e.preventDefault();
    // if (LoginId.length != 6) {
    //   toast.error(`Invalid Login Id or Password`);
    //   return;
    // }
    let res = await API.post("/forgetpassword", {
      uid: LoginId,
    });
    console.log("res--->", res);

    if (
      res.data.data ===
      "Password has been successfully sent to registered email Id"
    ) {
      toast.success(res.data.data);
      history("/Login");
    } else {
      toast.error(`Invalid Login Id`);
    }
  };

  useEffect(() => {
    const images = [sl1, sl2, sl3, sl4];
    images.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
    });
  }, []);

  return (
    <div>
      <div className="flexbox logpage ht-full pattern-background-primary">
        <div className="flex-md-15 flex-xs-100" />
        <div className="flex-md-70 flex-xs-100">
          <div className="flexbox logarea bg-white">
            {/* <div className="flex-md-50 flex-xs-100 ltblock" /> */}
            <section
              id="home_section"
              className="flex-md-50 flex-xs-100 section_banner section_gradiant2"
              data-z-index={1}
              data-parallax="scroll"
              data-image-src="~/assets_front/images/banner_bg2.png"
            >
              <Carousel autoplay speed={300} className="banners hxnbbnnrs">
                <div className="">
                  <img src={sl1} class="d-block w-100 banners" alt="Slide 1" />
                </div>
                <div className="">
                  <img src={sl2} class="d-block w-100 banners" alt="Slide 2" />
                </div>
                <div className="">
                  <img src={sl3} class="d-block w-100 banners" alt="Slide 3" />
                </div>
                {/* <div className="">
            <img src={sl4} class="d-block w-100 banners" alt="Slide 4"/>

            </div> */}
              </Carousel>
            </section>
            <div className="flex-md-50 flex-xs-100 pad-tp-20 pad-bt-20">
              <div className="bg mainlog dologin bdr-rad-xs">
                {/* <div class="bg seculock text-center"><img src="./assest/icon/lock.svg" height="80px" alt="Secure Login"></div> */}
                <div className="bg webdialog">
                  <div className="bg myform">
                    <div
                      className="bg my-login-block"
                      style={{ display: "block" }}
                    >
                      <form
                        data-form-action="./process/ajax/login"
                        method="post"
                        encType="multipart/form-data"
                      >
                        <div className="bg">
                          <p className="form-h1 color-grad1">
                            Get Your Password
                          </p>
                        </div>
                        <div className="bg form-area">
                          <label>UserID</label>
                          <input
                            type="text"
                            data-type="alpha"
                            className="form-elm"
                            name="userid"
                            placeholder="UserID"
                            required=""
                            onChange={(e) => {
                              setLoginId(e.target.value);
                            }}
                          />
                        </div>
                        <div className="bg form-area">
                          <button
                            type="submit"
                            className="btn btn-primary bounce-top"
                            onClick={(e) => {
                              ForgetpassToApi(e);
                            }}
                          >
                            Get Password
                          </button>
                        </div>

                        <div className="bg form-area">
                          <span className="text-xs">
                            {"If you dont have any account, then please"}
                            <Link to="/Signup">Signup Here</Link> or{" "}
                            <Link to="/Login">Login Now</Link>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-md-15 flex-xs-100" />
        {/* <div className="flex-md-100 text-center">
          <span className="text-sm disp-block color-gray">
            Created By <a href="https://innowise-group.com/">Innowise Group</a>.
            All Rights Reserved By REEO.
          </span>
        </div> */}
      </div>
    </div>
  );
}
