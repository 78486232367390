import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Api_Fren } from "../../API/Api_Fren";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { toast } from "react-toastify";
import sl3 from "../../Assets/WebImages/sl3.jpeg";
import sl1 from "../../Assets/WebImages/sl1.jpeg";
import sl2 from "../../Assets/WebImages/sl2.jpeg";
import sl4 from "../../Assets/WebImages/sl4.jpeg";
import { Carousel } from "antd";

export default function ShopLogin() {
  const history = useNavigate();
  const [LoginId, setLoginId] = useState("");
  const [PasswordId, setPasswordId] = useState("");
  const [showPassword, setshowPassword] = useState(0);
  const dispatch = useDispatch();
  const LoginToApi = async (e) => {
    e.preventDefault();
    // if (LoginId.length != 6) {
    //   toast.error(`Invalid Login Id or Password`);
    //   return;
    // }
    let res = await Api_Fren.post("/login_Fre", {
      user_id: LoginId,
      passsword: PasswordId,
      ipaddress: "",
    });
    //console.log("loginResult", res.data);
    if (res.data.data.result === "Successfull") {
      toast.success(`Login Successful`);
      dispatch(
        UpdateAuth({
          isAuth: res.data.success,
          userId: res.data.data.outuid,
          jwt_token: res.data.token,
        })
      );

      history("/Shop_Dasboard");
    } else {
      toast.error(res.data.data.result);
    }
  };

  const Changepasswordtype = async (e) => {
    if (parseInt(showPassword) === 0) {
      setshowPassword(1);
    }
    if (parseInt(showPassword) === 1) {
      setshowPassword(0);
    }
  };

  useEffect(() => {
    const images = [sl1, sl2, sl3, sl4];
    images.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
    });
  }, []);

  return (
    <div>
      <div className="flexbox logpage ht-full pattern-background-primary">
        <div className="flex-md-15 flex-xs-100" />
        <div className="flex-md-70 flex-xs-100">
          <div className="flexbox logarea bg-white">
            {/* <div className="flex-md-50 flex-xs-100 ltblock" /> */}
            <section
              id="home_section"
              className="flex-md-50 flex-xs-100 section_banner section_gradiant2 h-100"
              data-z-index={1}
              data-parallax="scroll"
              data-image-src="~/assets_front/images/banner_bg2.png"
            >
              <Carousel autoplay speed={300} className="banners hxnbbnnrs">
                <div className="">
                  <img src={sl1} class="d-block w-100 banners" alt="Slide 1" />
                </div>
                <div className="">
                  <img src={sl2} class="d-block w-100 banners" alt="Slide 2" />
                </div>
                <div className="">
                  <img src={sl3} class="d-block w-100 banners" alt="Slide 3" />
                </div>
                <div className="">
                  <img src={sl4} class="d-block w-100 banners" alt="Slide 4" />
                </div>
              </Carousel>
            </section>
            <div className="flex-md-50 flex-xs-100 pad-tp-20 pad-bt-20">
              <div className="bg mainlog dologin bdr-rad-xs">
                <div className="bg webdialog">
                  <div className="bg myform">
                    <div
                      className="bg my-login-block"
                      style={{ display: "block" }}
                    >
                      <div className="bg">
                        <p className="form-h1 color-grad1">
                          Sign in your account
                        </p>
                        <p className="mb-4">
                          To Keep connected with us please login with your
                          personal info.
                        </p>
                      </div>
                      <div className="bg form-area">
                        <label>Login ID</label>
                        <input
                          type="text"
                          data-type="alpha"
                          className="form-elm"
                          name="userid"
                          placeholder="UserID"
                          required=""
                          onChange={(e) => {
                            setLoginId(e.target.value);
                          }}
                        />
                      </div>
                      <div className="bg form-area">
                        <label>
                          Password{" "}
                          <span
                            className="text-sm color-green"
                            onClick={(e) => {
                              Changepasswordtype(e);
                            }}
                          >
                            (SHOW/HIDE)
                          </span>
                        </label>
                        <input
                          type={showPassword === 1 ? "text" : "password"}
                          className="form-elm logpassinp"
                          name="password"
                          placeholder="xxxxxxxx"
                          onChange={(e) => {
                            setPasswordId(e.target.value);
                          }}
                          required=""
                        />
                      </div>
                      <div className="bg form-area">
                        <button
                          type="submit"
                          className="btn btn-primary bounce-top"
                          onClick={(e) => {
                            LoginToApi(e);
                          }}
                        >
                          Login Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-md-15 flex-xs-100" />
      </div>
    </div>
  );
}
