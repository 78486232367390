import React, { useEffect, useState } from "react";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { API } from "../../API/Api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import TableButton from "../TableButton/TableButton";

const PoolTeam = () => {
  const [myTeamApi, setMyTeamApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [totalTeam, setTotalTeam] = useState();
  const [fName, setFName] = useState();
  const [levelType, setLevelType] = useState();
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(2);
  const [toDate, settoDate] = useState("");
  const [FromDate, setFromDate] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const Myteam_API = async () => {
    try {
      let res = await API.post(
        `/levelDetails`,
        {
          uid: userId,
          status: selectedStatus,
          fdate: FromDate,
          tdate: toDate,
          level: selectedLevel,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // console.log("myteam", res.data.data);

      // if (res.data.data && res.data.data.totalTeam) {
      //   setTotalTeam(res.data.data.totalTeam);
      // }
      // if (
      //   res.data.data.record[0] &&
      //   res.data.data.record[0] &&
      //   res.data.data.record[0].length > 0
      // ) {
      //   setFName(res.data.data.record[0][0].f_name);
      //   setLevelType(res.data.data.record[0][0].Leveltype);
      // }

      let arr = [];
      res.data.data[0].forEach((item, index) => {
        const statusColor = item.idType === "Active" ? "green" : "red";
        const isBlockColor = item.isblock === "Yes" ? "green" : "red";
        arr.push({
          RowNumber: item.RowNumber,
          userid: item.uid,
          Username: item.Username,
          sponser_id: item.sponser_id,
          name: item.f_name,
          number: item.mobile,
          status: <span style={{ color: statusColor }}>{item.idType}</span>,
          block: <span style={{ color: isBlockColor }}>{item.isblock}</span>,
          Adate: item.activationDate,
          registrationDate: item.registrationDate,
          pamt: item.packageamount,
          sid: item.sid,
          totalteam: item.totalTeam,
          Leveltype: item.Leveltype,
          pos: item.pos,
        });
      });

      setMyTeamApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Myteam_API();
  }, []);

  // eslint-disable-next-line no-unused-vars
  var [myTeam, setMyTeam] = new useState({
    cols: [
      { Header: "S.No.", accessor: "RowNumber" },
      { Header: "User ID", accessor: "Username" },
      { Header: "User Name", accessor: "name", style: { color: "#ffa117" } },
      //{ Header: "Contact No.", accessor: "number" },
      { Header: "Level", accessor: "Leveltype" },
      { Header: "Status", accessor: "status" },
      //{ Header: "Is Blocked?", accessor: "block" },
      { Header: "Registration Date", accessor: "registrationDate" },
      { Header: "Activation Date", accessor: "Adate" },
      { Header: "Package Amt", accessor: "pamt" },
      { Header: "Sponsor Id", accessor: "sponser_id" },
      { Header: "Position", accessor: "pos" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = myTeamApi.slice(indexOfFirstPage, indexOfLastPost);

  return (
    <div>
      <DashBoardNav />
      <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
        <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
          Level Details
        </span>
        <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
          Dashboard &gt; Team
        </span>
      </div>
      <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
        <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
          <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
            <span
              // onclick=""
              className="float-left text-sm filterbtn color-black bdr-rad-xs"
            >
              Advance Filter
            </span>
            <div className="card col-lg-12">
              <div className="card-body">
                <form action="" method="post" className="ng-pristine ng-valid">
                  <div className="box-body activation_pannel">
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Level <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control select26"
                            id="level"
                            name="level"
                            value={selectedLevel}
                            onChange={(e) => setSelectedLevel(e.target.value)}
                          >
                            <option value={0}>All Level</option>
                            <option value={1}>Level-1</option>
                            <option value={2}>Level-2</option>
                            <option value={3}>Level-3</option>
                            <option value={4}>Level-4</option>
                            <option value={5}>Level-5</option>
                            <option value={6}>Level-6</option>
                            <option value={7}>Level-7</option>
                            <option value={8}>Level-8</option>
                            <option value={9}>Level-9</option>
                            <option value={10}>Level-10</option>
                            <option value={11}>Level-11</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Person Status
                            <span className="text-danger" />
                          </label>
                          <select
                            className="form-control select26"
                            id="status"
                            name="status"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                          >
                            <option value={2}>All</option>
                            <option value={1}>Active</option>
                            <option value={0}>Inactive</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            From Date
                            <span className="text-danger" />
                          </label>
                          <input
                            className="form-control select26"
                            id="fdate"
                            name="fdate"
                            type="date"
                            // defaultValue=""
                            value={FromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            To Date
                            <span className="text-danger" />
                          </label>
                          <input
                            className="form-control select26"
                            id="tdate"
                            name="tdate"
                            type="date"
                            // defaultValue=""
                            value={toDate}
                            onChange={(e) => settoDate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <button
                          type="button"
                          className="btn btn-success mpy"
                          onClick={() => Myteam_API()}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <div className="flex-md-50">
              <span className="disp-block color-grad1 text-big font-thik">
                17
              </span>
              <span className="disp-block color-gray">Team Network</span>
            </div>
            <div className="flex-md-50 text-right">
              <span className="disp-block color-grad1 text-big font-thik">
                0
              </span>
              <span className="disp-block color-gray">Team Activation</span>
            </div> */}
          </div>
        </div>
        <div className="bg myform pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
          <div className="bg text-center pad-tp-20 pad-lt-5 pad-rt-10">
            <span className="text-md font-bold color-grad1 float-left">
              Level Details Report
            </span>
          </div>
          <br />
          <br />
          <div className="card-body p-2">
            <div className="table-responsive">
              <div
                style={{ maxHeight: 400, overflow: "scroll" }}
                id="example_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <Table data={[...currentPost]} columns={myTeam.cols} />
                <div className="container">
                  <TableButton
                    indexOfFirstPage={indexOfFirstPage}
                    indexOfLastPost={indexOfLastPost}
                    setcurrentPage={setcurrentPage}
                    currentPage={currentPage}
                    totalData={myTeamApi.length}
                    listPerpage={listPerpage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoolTeam;
