import React, { useEffect, useState } from "react";
import TableButton from "../TableButton/TableButton";
import Table from "../Table/Table";
import DashBoardNav from "../DashBoardNav/DashBoardNav";
import { API } from "../../API/Api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function ProductHistory() {
  const [pendingWithdrawApi, setPendingWithdrawApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const navigate = useNavigate();
  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "Order Pending";
      case 1:
        return "Order Accepted";
      case 2:
        return "Order Cancelled";
      case 3:
        return "Order Delivered";
      default:
        return "Unknown Status";
    }
  };

  const ProductHistory_API = async () => {
    try {
      let res = await API.post(
        `/ActivationProductHistory`,
        {
          uid: userId,
          fromDt: "",
          toDt: "",
          type: 3,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      res = res.data.data[0];
      console.log("response", res);
      setPendingWithdrawApi([]);
      console.log("ActivatedproductHistory", res);
      let arr = [];
      res.forEach((item, index) => {
        arr.push({
          sno: item.row,
          Ono: (
            <>
              <span onClick={() => navigate(`/invoice?OrderNo=${item?.mpoid}`)}>
                {`${item.orderno}` + `/` + `${item.mpoid}`}
              </span>
            </>
          ),
          date: item.dd,
          Tpaid: item.TOtalPaid,
          quantity: item.totalqty,
          remark: item.remark,
          FId: item.Brid,
          status: getStatusText(item.Status),
          // tnx: (
          //   <a
          //     href={`https://tronscan.org/#/transaction/${item.txn}`}
          //     target="_blank">
          //     {" "}
          //     View Txn
          //   </a>
          // ),
        });
      });

      setPendingWithdrawApi(arr);
    } catch (e) {
      console.log(" Activatedproduct history API not working", e);
    }
  };

  useEffect(() => {
    ProductHistory_API();
  }, []);

  // eslint-disable-next-line no-unused-vars
  var [pendingWithdraw, setpendingWithdrawApi] = new useState({
    cols: [
      { Header: "S No", accessor: "sno" },
      { Header: "Order Id", accessor: "Ono" },
      { Header: "Paid Date", accessor: "date" },
      { Header: "Total Paid", accessor: "Tpaid" },
      { Header: "Quantity", accessor: "quantity" },
      { Header: "Remark", accessor: "remark" },
      { Header: "Franchise Id", accessor: "FId" },
      { Header: "Status", accessor: "status" },
    ],
  });

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = pendingWithdrawApi.slice(
    indexOfFirstPage,
    indexOfLastPost
  );

  return (
    <>
      <div>
        <DashBoardNav />
        <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
          <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
            Product History
          </span>
          <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
            Dashboard &gt; Buy Activation Product
          </span>
        </div>
        <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
          <div className="bg pad-tp-20 pad-bt-100 pad-lt-15 pad-rt-15 bg-white bdr-rad-md">
            <div className="bg text-center pad-tp-20 pad-lt-5 pad-rt-10">
              <span className="text-md font-bold color-grad1 float-left">
                Product History
              </span>
            </div>
            <br />
            <br />
            {/* table */}
            <Table data={[...currentPost]} columns={pendingWithdraw.cols} />
            <div className="container">
              <TableButton
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={pendingWithdrawApi.length}
                listPerpage={listPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
