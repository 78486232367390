import React, { useEffect, useState } from "react";
import DashShopNav from "../DashShopNav/DashShopNav";
import Table from "../Table/Table";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Api_Fren } from "../../API/Api_Fren";
import toast from "react-hot-toast";
import { ContentPasteOffOutlined } from "@mui/icons-material";

export default function CheckActivation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const [OrderId, setOrderId] = useState("");
  const [OrderData, setOrderData] = useState("");
  const [OrderDetails, setOrderDetails] = useState([]);

  const OrderDetail_API = async () => {
    let URL_ID = new URLSearchParams(window.location.search).get("OrderNo");
    try {
      setOrderId(URL_ID);
      let res = await Api_Fren.post(
        `showorderActProductmain_Fre`,
        {
          uid: userId,
          id: URL_ID,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setOrderData(res.data.data[0]);
      console.log("OrderData_1", res.data.data[0]);

      let resProduct = await Api_Fren.post(
        `showorderActProduct_Fre`,
        {
          uid: userId,
          id: URL_ID,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setOrderDetails(resProduct.data.data[0]);

      console.log("OrderData_2", resProduct.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    OrderDetail_API();
  }, []);

  return (
    <>
      <div>
        <DashShopNav />
        <div className="bg dashb pad-tp-30 pad-bt-200 text-center">
          <span className="pad-tp-20 bg marg-tp-50 text-xl color-white">
            Check Activation
          </span>
          <span className="pad-bt-20 mt-1 bg text-sm color-yellow">
            Dashboard &gt; check
          </span>
        </div>
        <div className="bg pad-tp-10 pad-bt-10 pad-lt-10 pad-rt-10 blockarea lg">
          <div className="bg marg-bt-10 pad-lt-5 pad-rt-5">
            <div className="flexbox bg-white bdr-rad-sm pad-tp-15 pad-bt-15 pad-lt-10 pad-rt-10">
              <div className="panel panel-primary col-lg-12">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    Activation Product Order Details
                  </h4>
                </div>
                <div
                  className="panel-body table-rep-plugin"
                  style={{ padding: 8, overflowY: "scroll" }}
                >
                  <div className="col-md-12">
                    <div className="activation-form table-responsive">
                      <div className="row" style={{ marginBottom: 20 }}>
                        <div className="col-md-1">
                          <label>Invoice NO</label>
                        </div>
                        <div className="col-md-3">
                          <input
                            className="form-control"
                            disabled="True"
                            id="invoiceno"
                            name="invoiceno"
                            type="text"
                            value={OrderData[0]?.invoicenumber}
                          />
                        </div>
                        <div className="col-md-1">
                          <label>Total Amount</label>
                        </div>
                        <div className="col-md-3">
                          <input
                            className="form-control"
                            disabled="True"
                            id="totalmrp"
                            name="totalmrp"
                            type="text"
                            value={OrderData[0]?.TotalMRP}
                          />
                        </div>
                        <div className="col-md-1">
                          <label>User Id</label>
                        </div>
                        <div className="col-md-3">
                          <input
                            className="form-control"
                            data-val="true"
                            data-val-length="The field userid must be a string with a minimum length of 9 and a maximum length of 10."
                            data-val-length-max={10}
                            data-val-length-min={9}
                            data-val-required="Required"
                            disabled="True"
                            id="userid"
                            name="userid"
                            type="text"
                            value={OrderData[0]?.Generated_by}
                          />
                        </div>
                      </div>
                      <div id="userDetail" className="panel-primary">
                        <div className="panel-heading">
                          <h4 className="panel-title">User Details</h4>
                        </div>
                        <div className="card1">
                          <div className="">
                            <div className="col-md-4">
                              <span>User</span>
                              <input
                                className="form-control"
                                disabled="True"
                                id="name"
                                name="name"
                                type="text"
                                value={OrderData[0]?.Uid}
                              />
                            </div>
                            <div className="col-md-4">
                              <span>Mobile</span>
                              <input
                                className="form-control"
                                disabled="True"
                                id="mobile"
                                name="mobile"
                                type="text"
                                value={OrderData[0]?.MobileNo}
                              />
                            </div>
                            <div className="col-md-4">
                              <span>Alternate Mobile</span>
                              <input
                                className="form-control"
                                disabled="True"
                                id="mobile1"
                                name="mobile1"
                                type="text"
                                value={OrderData[0]?.MobileNo1}
                              />
                            </div>
                            <div className="col-md-4">
                              <span>Email</span>
                              <input
                                className="form-control"
                                disabled="True"
                                id="email"
                                name="email"
                                type="text"
                                value={OrderData[0]?.Emailid}
                              />
                            </div>
                          </div>
                          <div className="">
                            <div className="col-md-4">
                              <span>Shipping Address</span>
                              <input
                                className="form-control"
                                disabled="True"
                                id="address"
                                name="address"
                                type="text"
                                value={OrderData[0]?.ShippingAddres}
                              />
                            </div>
                            <div className="col-md-4">
                              <span>State</span>
                              <input
                                className="form-control"
                                disabled="True"
                                id="state"
                                name="state"
                                type="text"
                                value={OrderData[0]?.StateName}
                              />
                            </div>
                          </div>
                          <div className="">
                            <div className="col-md-4">
                              City
                              <input
                                className="form-control"
                                disabled="True"
                                id="city"
                                name="city"
                                type="text"
                                value={OrderData[0]?.DistrictName}
                              />
                            </div>
                            <div className="col-md-4">
                              Pincode
                              <input
                                className="form-control"
                                disabled="True"
                                id="pincode"
                                name="pincode"
                                type="text"
                                value={OrderData[0]?.pincode[0]}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-2" />
                      </div>
                    </div>
                    <div className="row">
                      <br />
                      <div className="panel-heading">
                        <h4 className="panel-title">Product Details</h4>
                      </div>
                      <div
                        className="table-responsive"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-small-font table-bordered table-striped"
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Product Name</th>
                              <th>MRP</th>
                              <th>BV</th>
                              <th>Qty</th>
                              <th>Total MRP</th>
                              <th>Total BV</th>
                            </tr>
                          </thead>
                          <tbody>
                            {OrderDetails?.map((ele, index) => (
                              <tr>
                                <td>{ele.row}</td>
                                <td>{ele.pname}</td>
                                <td>{ele.MRP}</td>
                                <td>{ele.BV}</td>
                                <td>{ele.Qty}</td>
                                <td>{ele.TotalMRP}</td>
                                <td>{ele.TotalBV}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
