import React, { useEffect, useState } from "react";
import "./Agrmnt.css";
import sign from "../../Assets/Logo/sign-removebg-preview.png";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { API } from "../../API/Api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import jivanlogo from "../../Assets/Logo/logo.png";

export default function Agrmnt() {
  const handlePrint = () => {
    window.print();
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const [OrderId, setOrderId] = useState("");
  const [data, setData] = useState({});

  const Dashboard_API = async () => {
    try {
      let URL_ID = new URLSearchParams(window.location.search).get("id");
      setOrderId(URL_ID);

      let res = await API.post(
        `activationOrderDtails`,
        {
          uid: userId,
          id: URL_ID,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("API Response", res.data.data[0][0]);
      setData(res.data.data[0][0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Dashboard_API();
  }, []);
  return (
    <>
      <div className="wrapper">
        <div className="main1">
          <div className="mainnew">
            <div style={{ textAlign: "center" }}>
              <img src={jivanlogo} alt="Jivandhara Logo" className="" />
            </div>
            <h2 style={{ textAlign: "center", color: "red" }}>AGREEMENT</h2>
            <h3 className="text-uppercase">
              BETWEEN JIVANDHARA GROWING (OPC) PRIVATE LIMITED AND {data.f_name}{" "}
              {data.panno !== "" ? `(PAN - ${data.panno})` : ""}{" "}
            </h3>
            <p className="text-black">
              This Agreement ("Agreement") is entered into on {data.dd}, between
              JIVANDHARA GROWING (OPC) PRIVATE LIMITED, a company registered
              under the Companies Act, 2003, and having its registered office at
              3C/104, buddhi vihar, moradabad, Uttar Pradesh ("Jivandhara
              Growing"), and the {data.address} listed in Schedule A
              (collectively referred to as "Farmers").
            </p>
            <h5>RECITALS</h5>
            <p className="text-black">
              A. Jivandhara growing is engaged in the sale and growing of
              organic compost, plantation kitchen gardening, contract farming
              and organic products.
            </p>
            <p className="text-black">
              B. The Farmers desire to engage Jivandhara growing for the sale,
              purchase, grow or manufacturing of the company's products.
            </p>
            <h5>AGREEMENT</h5>
            <p className="text-black">
              Rs {data.Amount} has been invested by {data.f_name} on
              vermicompost production bed or other products.
            </p>
            <p className="text-black">
              Sale or Purchase of Organic Compost: Jivandhara growing agrees to
              take responsibility for the sale or purchase of organic compost
              from the Farmers.
            </p>
            <p className="text-black">
              Payment Guarantee: Jivandhara growing guarantees payment to the
              Farmers for the organic compost sold, with payments to be made on
              the 1st to 5th of every month.
            </p>
            <p className="text-black">
              Compost Collection: Jivandhara Farming shall collect the compost
              from the Farmers in every 3 months, starting from the date of this
              Agreement.
            </p>
            <p className="text-black">
              Duration: This Agreement shall be valid for a period of 25 months
              from the date of signing, unless terminated earlier in accordance
              with the terms herein.
            </p>
            <p className="text-black">
              Early Termination: If a Farmer wishes to end the Agreement within
              3 months of its commencement, they shall provide written notice to
              Jivandhara growing. In such cases, the Farmer shall be subject to
              specific conditions as outlined:If bed deployed on company lease
              property then after 25 months the bed is the property of the
              company. If the bed is deployed on the customer's property a
              minimum of 1 acre then after 25 months the bed will be the
              property of the owner.
            </p>
            <p className="text-black">
              After 25 months if the farmer wants to continue with company
              services then he must repurchase the same package.
            </p>
            <p className="text-black">
              Manufacturing plant is taken care of by the company
            </p>
            <p className="text-black">
              All earthworms, vermicompost and other items produced can be sold
              by the company only.
            </p>
            <p className="text-black">
              The next 25 months the company will pay for every kilogram of
              vermiculture at the rate of rupees four per kilograms (which is 8%
              what it cost to produce them) between 1st and 5th day of every
              month in advance.
            </p>
            <p className="text-black">
              Governing Law: This Agreement shall be governed by and construed
              in accordance with the laws of INDIA.
            </p>
            <p className="text-black">
              IN WITNESS WHEREOF, the Parties hereto have executed this
              Agreement as of the date first above written.
            </p>
            <div>
              <img className="doclogo" src={sign} alt="Jivandha" />
              <h5>JIVANDHARA GROWING (OPC) PRIVATE LIMITED</h5>
              {/* <p className="text-black">[ Signature ]</p> */}
            </div>
            <div className="footeragr text-center">
              <p>
                <span> 3C/104, Avas Vikas, Buddhi Bihar, Moradabad,244001</span>{" "}
                <span>Email- info@jivandhara.org </span>
              </p>
            </div>
            <div
              style={{ textAlign: "center", marginTop: "20px" }}
              className="no-print"
            >
              <button
                className="btn btn-success"
                onClick={handlePrint}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
